// @flow
import type {
  Dispatch,
  GetState,
  ThunkExtraArgument,
  ThunkAction,
} from '../../../store'
import { ApiError, type API } from '../../../api/api'
import load from '../../../actions/load'
import { loadUser } from './index'

type Group = {
  id: number,
  name: string,
}

type RemovingGroupAdmin = 'users::REMOVING_GROUP_ADMIN'
export type RemovingGroupAdminAction = {
  type: RemovingGroupAdmin,
  saving: boolean,
}

type RemovedGroupAdmin = 'users::REMOVED_GROUP_ADMIN'
export type RemovedGroupAdminAction = { type: RemovedGroupAdmin, data: string }

type RemoveGroupResult = { data: Group }

async function* pages(res: Response, api: API) {
  yield await res.json()
  let links = api.parseLinks(res)
  while (links.exists('next')) {
    // eslint-disable-next-line no-await-in-loop
    const nextPage = await links.follow('next')
    // eslint-disable-next-line no-await-in-loop
    const data = await nextPage.json()
    yield data
    links = api.parseLinks(nextPage)
  }
}

const getGroup = async (groupId: number, api: API): Promise<?Group> => {
  let group
  for await (const groupsPage of pages(
    await api.invokeOperation('admin.getGroups'),
    api,
  )) {
    group = groupsPage.find(({ id }) => id === groupId)
    if (group) {
      break
    }
  }
  return group
}

const removeGroupAdmin = async (
  api: API,
  profileId: number,
  groupId: number,
): Promise<RemoveGroupResult> => {
  const group = await getGroup(groupId, api)
  if (!group) {
    throw new Error(`invalid group: ${groupId}`)
  }

  try {
    await api.invokeOperation('admin.removeGroupAdmin', {
      params: { groupId, userId: profileId },
      headers: new Headers({ 'content-type': 'application/json' }),
    })
    return { data: group }
  } catch (err) {
    if (err instanceof ApiError) {
      throw err.wrap('remove group admin')
    }
    throw err
  }
}

export default (groupId: number): ThunkAction =>
  (dispatch: Dispatch, getState: GetState, { api }: ThunkExtraArgument) => {
    const { users: { loaded: { id: userId } = {} } = {} } = getState()
    if (!userId) {
      throw new Error('cannot remove group admin permission: no user selected')
    }
    dispatch(
      load(
        () => removeGroupAdmin(api, userId, groupId),
        (saving: boolean) => ({ type: 'users::REMOVING_GROUP_ADMIN', saving }),
        ({ data }: RemoveGroupResult) => ({
          type: 'users::REMOVED_GROUP_ADMIN',
          data,
        }),
      ),
    )
    dispatch(loadUser(userId))
  }
