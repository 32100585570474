// @flow
import { connect } from 'react-redux'
import { editSchema, loadSchemas, hideEditSchema } from '../actions'
import EditSchemaPanel from '../components/EditSchemaPanel'
import { State, Dispatch } from '../../../store'
import pushNotification from '../../../notifications/actions/pushNotification'

const mapStateToProps = ({
  schemas: { edit: { visible, saving, data, saved } = {} } = {},
}: State) => ({
  open: visible,
  updating: saving,
  updated: saved,
  schema: data,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateSchema: (data: { type: 'notes' | 'feedback', definition: mixed }) =>
    dispatch(editSchema(data)),
  close: () => {
    dispatch(hideEditSchema())
    dispatch(loadSchemas({ status: 'active' }))
  },
  onUpdate: (title: string) =>
    dispatch(
      pushNotification({
        title,
        type: 'success',
      }),
    ),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditSchemaPanel)
