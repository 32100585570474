// @flow
import React, { useState, type Node } from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import IconButton from '@material-ui/core/IconButton'
import Drawer from '@material-ui/core/Drawer'
import Divider from '@material-ui/core/Divider'
import HomeIcon from '@material-ui/icons/Home'
import MenuBookIcon from '@material-ui/icons/MenuBook'
import MenuIcon from '@material-ui/icons/Menu'
import LinkIcon from '@material-ui/icons/Link'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import ContactsIcon from '@material-ui/icons/Contacts'
import GroupIcon from '@material-ui/icons/Group'
import CodeIcon from '@material-ui/icons/Code'
import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard'
import Toolbar from './Toolbar'
import logo from './logo_color_white.png'
import LoggedIn from '../../containers/LoggedIn'
import IsAdmin from '../../containers/IsAdmin'
import LogInButton from '../../containers/LogInButton'
import LogOutButton from '../../containers/LogOutButton'
import UserAvatar from '../../containers/UserAvatar'
import { useNavLinks } from '../../config/useConfig'

type SiteNavProps = {
  classes: { [string]: string },
  children: ?Node,
}

const drawerWidth = 240

const styles = (theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  toolbar: {
    justifyContent: 'space-between',
  },
  left: {
    flex: 1,
  },
  right: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  menuButton: {
    marginRight: 36,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-end',
    ...theme.mixins.toolbar,
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(1),
    },
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
      padding: theme.spacing(1),
    },
  },
  link: {
    ...theme.typography.h6,
    textDecoration: 'none',
    '&:hover, &:focus': {
      textDecoration: 'underline',
    },
  },
  rightLink: {
    fontSize: 16,
    color: theme.palette.common.white,
    marginLeft: 0,
  },
  spacer: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
})

function SiteNav({ classes, children }: SiteNavProps) {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const links = useNavLinks()
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen)
  }

  return (
    <div className={classes.root}>
      <AppBar elevation={0} position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.left}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={toggleDrawer}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
          </div>
          <div className={classes.title}>
            <img className={classes.logo} src={logo} alt="Lattus" />
          </div>
          <div className={classes.right}>
            <LoggedIn invert>
              <LogInButton className={clsx(classes.link, classes.rightLink)}>
                Log in
              </LogInButton>
            </LoggedIn>
            <LoggedIn>
              <LogOutButton className={clsx(classes.link, classes.rightLink)}>
                <UserAvatar />
                <div className={classes.spacer} />
                <ExitToAppIcon />
              </LogOutButton>
            </LoggedIn>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: drawerOpen,
          [classes.drawerClose]: !drawerOpen,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: drawerOpen,
            [classes.drawerClose]: !drawerOpen,
          }),
        }}
      >
        <div className={classes.drawerHeader} />
        <List>
          <ListItem button component={Link} to="/">
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>
          <IsAdmin>
            <ListItem button component={Link} to="/users">
              <ListItemIcon>
                <ContactsIcon />
              </ListItemIcon>
              <ListItemText primary="Users" />
            </ListItem>
            <ListItem button component={Link} to="/groups">
              <ListItemIcon>
                <GroupIcon />
              </ListItemIcon>
              <ListItemText primary="Groups" />
            </ListItem>
            <ListItem button component={Link} to="/reports">
              <ListItemIcon>
                <MenuBookIcon />
              </ListItemIcon>
              <ListItemText primary="Reports" />
            </ListItem>
            <ListItem button component={Link} to="/schemas">
              <ListItemIcon>
                <DeveloperBoardIcon />
              </ListItemIcon>
              <ListItemText primary="Schemas" />
            </ListItem>
          </IsAdmin>
        </List>
        <Divider />
        <List>
          <ListItem button component="a" href={links.apispec}>
            <ListItemIcon>
              <CodeIcon />
            </ListItemIcon>
            <ListItemText primary="API spec" />
          </ListItem>
          <ListItem button component="a" href={links.app}>
            <ListItemIcon>
              <LinkIcon />
            </ListItemIcon>
            <ListItemText primary="Lattus app" />
          </ListItem>
        </List>
      </Drawer>
      <div className={classes.content}>
        <div className={classes.drawerHeader} />
        {children}
      </div>
    </div>
  )
}

export default withStyles(styles)(SiteNav)
