// @flow
import React, { useState, useEffect } from 'react'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'

type GroupFilterProps = {
  value?: ?number,
  groups?: Array<{ id: number, label: string }>,
  loadGroups: () => void,
  applyFilter: (?number) => void,
}

const GroupFilter = ({
  value: initialValue = null,
  groups = [],
  loadGroups,
  applyFilter,
}: GroupFilterProps) => {
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    loadGroups()
  }, [])

  useEffect(() => {
    if (value !== initialValue) {
      applyFilter(value)
    }
  }, [value])

  useEffect(() => {
    if (initialValue !== value) {
      setValue(initialValue || null)
    }
  }, [initialValue])

  if (groups.length < 1) {
    return null
  }

  return (
    <FormControl>
      <InputLabel>Group</InputLabel>
      <Select
        value={value || ''}
        onChange={(e) => setValue(e.target.value)}
        autoWidth
      >
        {groups.map(({ id, label }) => (
          <MenuItem key={id} value={id}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

GroupFilter.defaultProps = {
  value: null,
  groups: [],
}

export default GroupFilter
