// @flow
import type { Dispatch, GetState, ThunkAction } from '../../../store'
import loadUsers from './loadUsers'
import { loadMembers } from '../../groups/actions'

type FilterEmail = 'users::SET_EMAIL_FILTER'
export type FilterEmailAction = { type: FilterEmail, value: string }

const filterEmail = (value: string): ThunkAction => (
  dispatch: Dispatch,
  getState: GetState,
) => {
  const {
    users: { listing: { filter: { email } = {} } = {} } = {},
    groups: { members: { visible = false } = {} } = {},
  } = getState()
  if (email !== value) {
    dispatch({
      type: `${!visible ? 'users' : 'groups'}::SET_EMAIL_FILTER`,
      value,
    })
    dispatch(visible ? loadMembers() : loadUsers())
  }
}

export default filterEmail
