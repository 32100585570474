// @flow
import React, { Component, type Node } from 'react'
import { withScope, captureException } from '@sentry/browser'

type CatchBoundaryProps = {
  children: ?Node,
}

class CatchBoundary extends Component<CatchBoundaryProps> {
  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(err: Error, errInfo: mixed) {
    withScope((scope) => {
      scope.setExtras(errInfo)
      captureException(err)
    })
  }

  render() {
    const { children } = this.props
    return <>{children}</>
  }
}

export default CatchBoundary
