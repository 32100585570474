// @flow
import React, { useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Slide from '@material-ui/core/Slide'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import SetupImports from '../containers/SetupImports'
import UploadData from '../containers/UploadData'
import PreviewData from '../containers/PreviewData'
import ImportProgress from '../containers/ImportProgress'

type WizardProps = {
  open: boolean,
  step: number,
  valid: boolean,
  goBack: () => void,
  goForward: () => void,
  finish: () => void,
  reset: () => void,
  classes: { [string]: string },
}

const NumSteps = 4

const getContents = (step) => {
  switch (step) {
    case 0:
      return (
        <>
          <Typography paragraph>
            This wizard will guide you through importing
            <strong> new </strong>
            users from a CSV file. If you want to edit an existing user, then do
            not user this wizard.
          </Typography>
          <Typography variant="body2" paragraph>
            To begin, let&apos;s configure which groups the users will be added
            to, and whether or not Auth0 accounts should be created for the
            users.
          </Typography>
          <SetupImports />
        </>
      )
    case 1:
      return (
        <>
          <Typography paragraph>
            Upload the CSV file with the users to create.
          </Typography>
          <Typography variant="body2" paragraph>
            You will have a chance to review these changes before they are saved
            in the database. The following fields are supported:{' '}
            <code>firstName</code>
            {', '}
            <code>lastName</code>
            {', '}
            <code>email</code>
            {', '}
            <code>maxConversations</code>
            {', '}
            <code>bio</code>.
          </Typography>
          <UploadData />
        </>
      )
    case 2:
      return (
        <>
          <Typography paragraph>
            The following data will be imported; please review for accuracy.
          </Typography>
          <Typography variant="body2" paragraph>
            <strong>These changes have not been saved. </strong>
            Click &ldquo;next&rdquo; to begin importing them into the database.
          </Typography>
          <Typography variant="body2" paragraph>
            If you need to make a change, use the &ldquo;back&rdquo; button. You
            may make changes to your CSV locally and re-upload it for
            processing.
          </Typography>
          <PreviewData />
        </>
      )
    case 3:
      return (
        <>
          <Typography paragraph>Importing users into the database.</Typography>
          <Typography variant="body2" paragraph>
            <strong>Do not close or navigate away from this window. </strong>
            Doing so will abort the import, and may mean that some users have
            been imported while others have not.
          </Typography>
          <ImportProgress />
        </>
      )
    default:
      return <Typography>Done!</Typography>
  }
}

const Transition = React.forwardRef(function Transition(props, ref) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Slide direction="up" ref={ref} {...props} />
})

const styles = (theme) => ({
  modal: {
    height: '80vh',
  },
  contents: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.grey[200]}`,
  },
})

const Wizard = ({
  open,
  step,
  valid,
  goBack,
  goForward,
  finish,
  reset,
  classes,
}: WizardProps) => {
  const lastStep = step + 1 >= NumSteps

  useEffect(() => {
    if (step >= NumSteps) {
      finish()
    }
  }, [step])

  useEffect(() => {
    reset()
  }, [])

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={() => finish()}
      scroll="paper"
      fullWidth
      maxWidth="lg"
      PaperProps={{ className: classes.modal }}
    >
      <DialogTitle>Import users</DialogTitle>
      <DialogContent>
        <Stepper activeStep={step} alternativeLabel>
          <Step>
            <StepLabel>Setup</StepLabel>
          </Step>
          <Step>
            <StepLabel>Upload users</StepLabel>
          </Step>
          <Step>
            <StepLabel>Review</StepLabel>
          </Step>
          <Step>
            <StepLabel>Import</StepLabel>
          </Step>
        </Stepper>
        <div className={classes.contents}>{getContents(step)}</div>
      </DialogContent>
      <DialogActions>
        <Button disabled={step === 0} onClick={goBack}>
          Back
        </Button>
        <Button disabled={!valid || step >= NumSteps} onClick={goForward}>
          {lastStep ? 'Finish' : 'Next'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withStyles(styles)(Wizard)
