// @flow
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import logOut from '../auth/actions/logOut'
import type { State } from '../store'

const mapStateToProps = ({ auth: { loggedIn } = {} }: State) => ({
  disabled: !loggedIn,
})

const mapDispatchToProps = {
  onClick: logOut,
}

export default connect(mapStateToProps, mapDispatchToProps)(Button)
