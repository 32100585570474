// @flow
import { connect } from 'react-redux'
import Selector from '../components/Selector'
import loadReport from '../actions/loadReport'
import type { State } from '../../../store'

const mapStateToProps = ({
  reports: { activeReport, availableReports } = {},
}: State) => ({
  activeReport,
  availableReports,
})

const mapDispatchToProps = {
  setActiveReport: loadReport,
}

export default connect(mapStateToProps, mapDispatchToProps)(Selector)
