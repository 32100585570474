// @flow
import React, { type Node } from 'react'
import { Helmet } from 'react-helmet'
import Message from './Message'
import LoggedIn from '../containers/LoggedIn'
import IsAdmin from '../containers/IsAdmin'

type IsAuthorizedProps = {
  children: ?Node,
}

const IsAuthorized = ({ children }: IsAuthorizedProps) => (
  <>
    <LoggedIn invert>
      <Helmet>
        <title>Log in</title>
      </Helmet>
      <Message title="Log in" body="Please log in to continue." />
    </LoggedIn>
    <LoggedIn>
      <IsAdmin invert>
        <Helmet>
          <title>Not authorized</title>
        </Helmet>
        <Message title="Not authorized" body="Please contact an administrator if you need access." />
      </IsAdmin>
      <IsAdmin>
        {children}
      </IsAdmin>
    </LoggedIn>
  </>
)

export default IsAuthorized
