// @flow
import type { Group } from './loadGroups'
import type {
  Dispatch,
  GetState,
  ThunkExtraArgument,
  ThunkAction,
} from '../../../store'
import { ApiError, type API } from '../../../api/api'
import load from '../../../actions/load'

type EditingGroup = 'groups::EDITING_GROUP'
export type EditingGroupAction = { type: EditingGroup, saving: boolean }

type EditedGroup = 'groups::EDITED_GROUP'
export type EditedGroupAction = { type: EditedGroup, data: Group }

type EditGroupResult = { data: Group }

const editGroup = async (
  api: API,
  groupId: number,
  {
    name,
    description,
    prefix,
    logo,
    banner,
    whiteLabelLogo,
    whiteLabelVideoURL,
    defaultSkill,
    recodingValue,
    isMailingValue,
    isActive,
    isFake,
  }: {
    name: string,
    description: string,
    prefix: string | null,
    logo: File | null,
    banner: File | null,
    whiteLabelLogo: File | null,
    whiteLabelVideoURL: string | null,
    defaultSkill: number | null,
    recodingValue: boolean,
    isMailingValue: boolean,
    isActive: boolean,
    isFake: boolean,
  },
): Promise<EditGroupResult> => {
  try {
    const editedGroup = await api.invokeOperation('admin.updateGroup', {
      params: { groupId },
      headers: new Headers({ 'content-type': 'application/json' }),
      body: JSON.stringify({
        name,
        description,
        prefix,
        whiteLabelVideoURL,
        defaultSkill,
        recodingValue,
        isMailingValue,
        isActive,
        isFake,
      }),
    })
    const data = await editedGroup.json()
    // call update logo and banner
    let formData = new FormData()
    let updateGroupLogo = {}
    let updateGroupBanner = {}
    if (logo) {
      formData.append('file', logo, logo.name)
      updateGroupLogo = await api.invokeOperation('admin.updateGroupLogo', {
        headers: new Headers(),
        params: { groupId },
        body: formData,
      })
    }
    if (banner) {
      formData = new FormData()
      formData.append('file', banner, banner.name)
      updateGroupBanner = await api.invokeOperation('admin.updateGroupImage', {
        headers: new Headers(),
        params: { groupId },
        body: formData,
      })
    }
    if (whiteLabelLogo) {
      formData = new FormData()
      formData.append('file', whiteLabelLogo, whiteLabelLogo.name)
      updateGroupBanner = await api.invokeOperation(
        'admin.updateGroupWhiteLabelLogoV1',
        {
          headers: new Headers(),
          params: { groupId },
          body: formData,
        },
      )
    }
    return { data: { ...data, ...updateGroupBanner, ...updateGroupLogo } }
  } catch (err) {
    if (err instanceof ApiError) {
      throw err.wrap('editing group')
    }
    throw err
  }
}

export default ({
    name,
    description,
    prefix,
    logo,
    banner,
    whiteLabelLogo,
    whiteLabelVideoURL,
    defaultSkill,
    recodingValue,
    isMailingValue,
    isActive,
    isFake,
  }: {
    name: string,
    description: string,
    prefix: string | null,
    logo: File | null,
    banner: File | null,
    whiteLabelLogo: File | null,
    whiteLabelVideoURL: string | null,
    defaultSkill: number | null,
    recodingValue: boolean,
    isMailingValue: boolean,
    isActive: boolean,
    isFake: boolean,
  }): ThunkAction =>
  (dispatch: Dispatch, getState: GetState, { api }: ThunkExtraArgument) => {
    const { groups: { edit: { data: { id: groupId } = {} } = {} } = {} } =
      getState()
    if (!groupId) {
      throw new Error('cannot update group: no group selected')
    }
    dispatch(
      load(
        () =>
          editGroup(api, groupId, {
            name,
            description,
            prefix,
            logo,
            banner,
            whiteLabelLogo,
            whiteLabelVideoURL,
            defaultSkill,
            recodingValue,
            isMailingValue,
            isActive,
            isFake,
          }),
        (saving: boolean) => ({ type: 'groups::EDITING_GROUP', saving }),
        ({ data }: EditGroupResult) => ({ type: 'groups::EDITED_GROUP', data }),
      ),
    )
  }
