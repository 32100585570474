// @flow
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import { showImporter } from '../actions'

const mapDispatchToProps = {
  onClick: showImporter,
}

export default connect(null, mapDispatchToProps)(Button)
