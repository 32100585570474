// @flow
import type { Dispatch, GetState, ThunkAction } from '../../../store'
import loadUsers from './loadUsers'
import { loadMembers } from '../../groups/actions'

type FilterName = 'users::SET_NAME_FILTER'
export type FilterNameAction = { type: FilterName, value: string }

const filterName = (value: string): ThunkAction => (
  dispatch: Dispatch,
  getState: GetState,
) => {
  const {
    users: { listing: { filter: { name } = {} } = {} } = {},
    groups: { members: { visible = false } = {} } = {},
  } = getState()
  if (name !== value) {
    dispatch({
      type: `${!visible ? 'users' : 'groups'}::SET_NAME_FILTER`,
      value,
    })
    dispatch(visible ? loadMembers() : loadUsers())
  }
}

export default filterName
