import React from 'react'
import { Provider as StoreProvider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import SiteNav from './components/Nav/SiteNav'
import SiteFooter from './components/Nav/SiteFooter'
import Main from './components/Main'
import { ApiProvider } from './hooks/useApi'
import configureStore from './store'
import theme from './theme'
import ConfigProvider from './config/Provider'
import { LoggerProvider } from './logger/LogContext'
// import { WafTokenProvider } from '@/components/Context/WafTokenProvider'

export default ({ config } = {}) => {
  const store = configureStore(config)
  return (
    <>
      <ThemeProvider theme={theme}>
        <LoggerProvider>
          <ConfigProvider existingConfig={config}>
            <StoreProvider store={store}>
              <ApiProvider api={store.api}>
                <Router>
                  <CssBaseline />
                  <SiteNav>
                    <Main />
                    <SiteFooter />
                  </SiteNav>
                </Router>
              </ApiProvider>
            </StoreProvider>
          </ConfigProvider>
        </LoggerProvider>
      </ThemeProvider>
    </>
  )
}
