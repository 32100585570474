// @flow
import type { Dispatch, GetState, ThunkAction } from '../../../store'
import loadUsers from './loadUsers'

type FilterGroup = 'users::SET_GROUP_FILTER'
export type FilterGroupAction = { type: FilterGroup, value: ?number }

const filterGroup = (value: ?number): ThunkAction => (
  dispatch: Dispatch,
  getState: GetState,
) => {
  const { users: { filter: { groupId } = {} } = {} } = getState()
  if (groupId !== value) {
    dispatch({ type: 'users::SET_GROUP_FILTER', value })
    dispatch(loadUsers())
  }
}

export default filterGroup
