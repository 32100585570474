// @flow
import { connect } from 'react-redux'
import GroupButton from '../components/GroupButton'
import { showEditGroup } from '../actions'
import { State, Dispatch } from '../../../store'

const mapStateToProps = ({
  groups: { edit: { visible } = {} } = {},
}: State) => ({
  disabled: visible,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onClick: (groupId: number) => dispatch(showEditGroup(groupId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(GroupButton)
