// @flow
import React, { createElement } from 'react'
import clsx from 'clsx'
import { withStyles } from '@material-ui/core/styles'
import { amber, green } from '@material-ui/core/colors'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import WarningIcon from '@material-ui/icons/Warning'

type NotificationProps = {
  visible: boolean,
  title?: string,
  type?: 'error' | 'warning' | 'info' | 'success',
  message?: ?string,
  clear: () => void,
  classes: { [string]: string },
}

const styles = (theme) => ({
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  success: {
    backgroundColor: green[600],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
})

const IconVariants = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
}

const Notification = ({
  visible,
  title,
  type = 'info',
  message,
  clear,
  classes,
}: NotificationProps) => (
  <Snackbar
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    open={visible}
    autoHideDuration={60000}
    onClose={clear}
  >
    <SnackbarContent
      className={classes[type]}
      message={
        <div className={classes.message}>
          {createElement(IconVariants[type], {
            className: clsx(classes.icon, classes.iconVariant),
          })}
          <div>
            <Typography variant="h6">{title}</Typography>
            {message && <Typography>{message}</Typography>}
          </div>
        </div>
      }
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={clear}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
    />
  </Snackbar>
)

Notification.defaultProps = {
  title: null,
  type: 'info',
  message: null,
}

export default withStyles(styles)(Notification)
