// @flow
import { connect } from 'react-redux'
import Wizard from '../components/Wizard'
import { nextStep, prevStep, resetStep, hideImporter } from '../actions'
import type { State } from '../../../store'

const mapStateToProps = ({
  imports: { step, canStepNext, visible } = {},
}: State) => ({
  open: visible,
  step,
  valid: canStepNext,
})

const mapDispatchToProps = {
  goBack: prevStep,
  goForward: nextStep,
  finish: hideImporter,
  reset: resetStep,
}

export default connect(mapStateToProps, mapDispatchToProps)(Wizard)
