// @flow
import { connect } from 'react-redux'
import loadUser from '../actions/loadUser'
import UserProfile from '../components/UserProfile'
import type { State } from '../../../store'

const mapStateToProps = ({
  users: { loaded: { loading = false, data } = {} } = {},
}: State) => ({
  loading,
  user: data,
})

const mapDispatchToProps = {
  load: loadUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile)
