// @flow
import { connect } from 'react-redux'
import clearFilter from '../actions/clearFilter'
import loadUsers from '../actions/loadUsers'
import UsersFilter from '../components/UsersFilter'

const mapStateToProps = () => ({
  filterByGroups: false,
})

const mapDispatchToProps = (dispatch) => ({
  applyFilter: () => dispatch(loadUsers()),
  clearFilter: () => dispatch(clearFilter(true)),
})

export default connect(mapStateToProps, mapDispatchToProps)(UsersFilter)
