// @flow
import type {
  Dispatch,
  GetState,
  ThunkExtraArgument,
  ThunkAction,
} from '../../../store'
import { ApiError, type API } from '../../../api/api'
import load from '../../../actions/load'

type DeletingUser = 'users::DELETING_USER'
export type DeletingUserAction = { type: DeletingUser, deleting: boolean }

type DeletedUser = 'users::DELETED_USER'
export type DeletedUserAction = { type: DeletedUser, data: number }

type DeleteUserResult = { data: number }

const deleteAccount = async (api: API, profileId: number): Promise<void> => {
  try {
    await api.invokeOperation('admin.deleteAccount', {
      params: { profileId },
    })
  } catch (err) {
    if (err instanceof ApiError) {
      throw err.wrap('deleting account')
    }
    throw err
  }
}

const deleteProfile = async (
  api: API,
  profileId: number,
): Promise<DeleteUserResult> => {
  try {
    await api.invokeOperation('admin.deleteProfile', {
      params: { profileId },
    })
    return { data: profileId }
  } catch (err) {
    if (err instanceof ApiError) {
      throw err.wrap('deleting profile')
    }
    throw err
  }
}

export default ({
    deleteAccount: shouldDeleteAccount = true,
  }: { deleteAccount?: boolean } = {}): ThunkAction =>
  (dispatch: Dispatch, getState: GetState, { api }: ThunkExtraArgument) => {
    const { users: { loaded: { id: userId } = {} } = {} } = getState()
    if (!userId) {
      throw new Error('cannot delete profile: no user selected')
    }
    dispatch(
      load(
        async () => {
          if (shouldDeleteAccount) {
            await deleteAccount(api, userId)
          }

          return deleteProfile(api, userId)
        },
        (deleting: boolean) => ({ type: 'users::DELETING_USER', deleting }),
        ({ data }: DeleteUserResult) => ({
          type: 'users::DELETED_USER',
          data,
        }),
      ),
    )
  }
