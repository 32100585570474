// @flow
import type { Schema, SchemaData } from './loadSchemas'
import type {
  Dispatch,
  GetState,
  ThunkExtraArgument,
  ThunkAction,
} from '../../../store'
import { ApiError, type API } from '../../../api/api'
import load from '../../../actions/load'

type EditingSchema = 'schemas::EDITING_SCHEMA'
export type EditingSchemaAction = { type: EditingSchema, saving: boolean }

type EditedSchema = 'schemas::EDITED_SCHEMA'
export type EditedSchemaAction = { type: EditedSchema, data: Schema }

type EditSchemaResult = { data: Schema }

const editSchema = async (
  api: API,
  { type, definition }: SchemaData,
): Promise<EditSchemaResult> => {
  try {
    const editedSchema = await api.invokeOperation('admin.updateSchema', {
      headers: new Headers({ 'content-type': 'application/json' }),
      body: JSON.stringify({ type, definition }),
    })
    const data = await editedSchema.json()
    return { data }
  } catch (err) {
    if (err instanceof ApiError) {
      throw err.wrap('editing schema')
    }
    throw err
  }
}

export default (schema: SchemaData): ThunkAction =>
  (dispatch: Dispatch, getState: GetState, { api }: ThunkExtraArgument) =>
    dispatch(
      load(
        () => editSchema(api, schema),
        (saving: boolean) => ({ type: 'schemas::EDITING_SCHEMA', saving }),
        ({ data }: EditSchemaResult) => ({
          type: 'schemas::EDITED_SCHEMA',
          data,
        }),
      ),
    )
