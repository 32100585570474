// @flow
import { connect } from 'react-redux'
import deleteAccount from '../actions/deleteAccount'
import ConfirmButton from '../components/ConfirmButton'
import type { State } from '../../../store'

const mapStateToProps = ({
  users: {
    loaded: { data: { externalId } = {} } = {},
    account: { deleting = false } = {},
  } = {},
}: State) => ({
  disabled: typeof externalId !== 'string' || externalId.length < 1 || deleting,
})

const mapDispatchToProps = (dispatch) => ({
  onClick: () => dispatch(deleteAccount()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmButton)
