// @flow
import { connect } from 'react-redux'
import GlobalPermission from '../components/Permission/GlobalPermission'
import type { State } from '../../../store'
import addLattusAdmin from '../actions/addLattusAdmin'
import removeLattusAdmin from '../actions/removeLattusAdmin'

const mapStateToProps = ({
  users: { loaded: { data: { roles = [] } = {} } = {} } = {},
}: State) => ({
  isAdmin: roles.indexOf('admin') >= 0,
})

const mapDispatchToProps = {
  addLattusAdmin,
  removeLattusAdmin,
}

export default connect(mapStateToProps, mapDispatchToProps)(GlobalPermission)
