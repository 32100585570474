// @flow
import { connect } from 'react-redux'
import { editGroup, loadGroups, hideEditGroup } from '../actions'
import EditGroupPanel from '../components/EditGroupPanel'
import { State, Dispatch } from '../../../store'
import pushNotification from '../../../notifications/actions/pushNotification'

const mapStatetoProps = ({
  groups: { edit: { visible, saving, data, saved } = {} } = {},
}: State) => ({
  open: visible,
  updating: saving,
  updated: saved,
  group: data,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateGroup: ({
    name,
    description,
    prefix,
    logo,
    banner,
    whiteLabelLogo,
    whiteLabelVideoURL,
    defaultSkill,
    recodingValue,
    isMailingValue,
    isActive,
    isFake,
  }: {
    name: string,
    description: string,
    prefix: string | null,
    logo: File | null,
    banner: File | null,
    whiteLabelLogo: File | null,
    whiteLabelVideoURL: string | null,
    defaultSkill: number | null,
    recodingValue: boolean,
    isMailingValue: boolean,
    isActive: boolean,
    isFake: boolean,
  }) =>
    dispatch(
      editGroup({
        name,
        description,
        prefix,
        logo,
        banner,
        whiteLabelLogo,
        whiteLabelVideoURL,
        defaultSkill,
        recodingValue,
        isMailingValue,
        isActive,
        isFake,
      }),
    ),
  close: () => {
    dispatch(hideEditGroup())
    dispatch(loadGroups())
  },
  onUpdate: (title: string) =>
    dispatch(
      pushNotification({
        title,
        type: 'success',
      }),
    ),
})

export default connect(mapStatetoProps, mapDispatchToProps)(EditGroupPanel)
