// @flow
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import EditLoadedUser from '../../containers/EditLoadedUser'
import DeleteUserButton from '../../containers/DeleteUserButton'

const useStyles = makeStyles((theme) => ({
  actions: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    '& > *': {
      marginBottom: theme.spacing(1),
    },
    '& > *:last-child': {
      marginBottom: 0,
    },
  },
}))

const Manage = () => {
  const classes = useStyles()

  return (
    <Grid container>
      <Grid item sm={9}>
        <EditLoadedUser />
      </Grid>
      <Grid item sm={3} className={classes.actions}>
        <DeleteUserButton variant="contained" startIcon={<DeleteForeverIcon />}>
          Delete Profile
        </DeleteUserButton>
      </Grid>
    </Grid>
  )
}

export default Manage
