// @flow

type ShowCreateGroup = 'groups::SHOW_CREATE_GROUP'
export type ShowCreateGroupAction = {
  type: ShowCreateGroup,
  visible: boolean,
}

export default (visible: boolean): ShowCreateGroupAction => ({
  type: 'groups::SHOW_CREATE_GROUP',
  visible,
})
