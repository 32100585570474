// @flow
import type { Group } from './loadGroups'
import type { Dispatch, GetState } from '../../../store'

type ShowMembers = 'groups::SHOW_MEMBERS'
export type ShowMembersAction = {
  type: ShowMembers,
  group: Group,
}

export const showMembers = (groupId: number) => (
  dispatch: Dispatch,
  getState: GetState,
) => {
  const { groups: { listing: { data = [] } = {} } = {} } = getState()
  const group = data.find(({ id }) => id === groupId)

  if (!group) {
    throw new Error(`unable to load group with id "${groupId}"`)
  }

  dispatch({
    type: 'groups::SHOW_MEMBERS',
    group,
  })
}

type HideMembers = 'groups::HIDE_MEMBERS'
export type HideMembersAction = {
  type: HideMembers,
}

export const hideMembers = () => ({
  type: 'groups::HIDE_MEMBERS',
})
