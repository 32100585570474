// @flow
import React from 'react'
import { useParams } from 'react-router-dom'
import LoadedUserProfile from '../../modules/users/containers/LoadedUserProfile'

const Profile = () => {
  const { userId } = useParams()
  return <LoadedUserProfile id={userId} />
}

export default Profile
