// @flow
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'

type BannerProps = {
  id: number,
  firstName: string,
  lastName: string,
  avatar: string,
  email: string,
  externalId: ?string,
}

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    padding: theme.spacing(2),
    width: '450px',
    margin: 'auto',
  },
  avatar: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    marginBottom: theme.spacing(1),
  },
  divider: {
    padding: theme.spacing(1, 0),
  },
  fields: {
    ...theme.typography.body1,
    lineHeight: '1.8em',
    margin: 0,
    '& > dt': {
      float: 'left',
      clear: 'left',
      width: theme.spacing(12),
      overflow: 'hidden',
      textAlign: 'right',
      fontWeight: 700,
    },
    '& > dt::after': {
      content: `':'`,
    },
    '& > dd': {
      marginLeft: theme.spacing(14),
    },
    '& > dd::after': {
      content: `' '`,
      whiteSpace: 'pre',
    },
  },
}))

const Banner = ({
  id,
  firstName,
  lastName,
  avatar,
  email,
  externalId,
}: BannerProps) => {
  const classes = useStyles()

  return (
    <div className={classes.card}>
      <Avatar className={classes.avatar} src={avatar} />
      <Typography gutterBottom variant="h5" component="h4">
        <a href={`mailto:${email}`}>{email}</a>
      </Typography>
      <div className={classes.divider} />
      <div>
        <dl className={classes.fields}>
          <dt>ID</dt>
          <dd>{id}</dd>
          <dt>External ID</dt>
          <dd>{externalId || ''}</dd>
          <dt>Name</dt>
          <dd>{`${`${firstName || ''} ${lastName || ''}`.trim()}`}</dd>
        </dl>
      </div>
    </div>
  )
}

export default Banner
