// @flow
import React, { useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import GroupSelector from '../../containers/GroupSelector'
import CreateAccountsToggle from '../../containers/CreateAccountsToggle'

type SetupProps = {
  isValid: (boolean) => void,
  classes: { [string]: string },
}

const styles = (theme) => ({
  spacer: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
})

const Setup = ({ isValid, classes }: SetupProps) => {
  useEffect(() => {
    isValid(true)
  }, [])

  return (
    <>
      <GroupSelector>
        Add all users in this import to the selected groups.
      </GroupSelector>
      <div className={classes.spacer} />
      <CreateAccountsToggle>
        When enabled, automatically creates Auth0 accounts for these users and
        sends them the verification email.
      </CreateAccountsToggle>
    </>
  )
}

export default withStyles(styles)(Setup)
