// @flow
import { connect } from 'react-redux'
import { debounce } from 'debounce'
import filterName from '../actions/filterName'
import NameFilter from '../components/NameFilter'
import type { State } from '../../../store'

const mapStateToProps = ({
  users: { listing: { filter: { name = '' } = {} } = {} } = {},
  groups: { members: { visible, filter = {} } = {} } = {},
}: State) => ({
  value: visible && filter ? filter.name : name,
})

const mapDispatchToProps = (dispatch) => ({
  applyFilter: debounce((name) => dispatch(filterName(name)), 500),
})

export default connect(mapStateToProps, mapDispatchToProps)(NameFilter)
