// @flow
import React, { type Node } from 'react'

type SwitchProps = {
  invert?: boolean,
  loaded?: boolean,
  when: boolean,
  children: ?Node,
}

const Switch = ({ loaded = true, invert = false, when, children }: SwitchProps) => (
  <>
    {loaded && (invert ? !when : when) && children}
  </>
)

Switch.defaultProps = {
  invert: false,
  loaded: true,
}

export default Switch
