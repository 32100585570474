// @flow
import React, { useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ReviewTable from './ReviewTable'
import Warnings from './Warnings'
import Groups from './Groups'

type DataProps = {
  rows: Object[],
  columns: string[],
  warnings: Array<{ message: string, type: 'error' | 'warning' }>,
  groups: string[],
  hasErrors?: boolean,
  isValid: (boolean) => void,
  classes: { [string]: string },
}

const styles = (theme) => ({
  spacer: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
})

const Data = ({
  rows,
  columns,
  warnings,
  groups,
  hasErrors = false,
  isValid,
  classes,
}: DataProps) => {
  useEffect(() => {
    isValid(!hasErrors && rows.length > 0 && columns.length > 0)
  }, [rows, columns])

  return (
    <>
      {warnings && <Warnings warnings={warnings} />}
      {rows && (
        <>
          <ReviewTable rows={rows} columns={columns} />
          <div className={classes.spacer} />
        </>
      )}
      {groups && groups.length > 0 && (
        <>
          <Typography variant="h4">Groups</Typography>
          <Typography variant="body2" paragraph>
            Users will be added to the following groups.
          </Typography>
          <Groups groups={groups} />
        </>
      )}
    </>
  )
}

Data.defaultProps = {
  hasErrors: false,
}

export default withStyles(styles)(Data)
