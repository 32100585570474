/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import AutoSuggestHighlightMatch from 'autosuggest-highlight/match'
import AutoSuggestHighlightParse from 'autosuggest-highlight/parse'
import AutoSuggest from 'react-autosuggest'
import { withStyles } from '@material-ui/core/styles'

const styles = () => ({
  autoSuggest: {
    '& .react-autosuggest__container': {
      position: 'relative',
    },
    '& .react-autosuggest__input': {
      width: '100%',
      height: 60,
      padding: '0px 18px',
      border: ' 1px solid gainsboro',
      borderRadius: 4,
      marginBottom: 10,
      font: 'inherit',
      fontSize: 16,
    },
    '& .react-autosuggest__input--focused': {
      outline: 'none',
    },
    '& .react-autosuggest__suggestions-container--open': {
      display: 'block',
      position: 'absolute',
      overflowY: 'scroll',
      maxHeight: 400,
      top: 31,
      width: '100%',
      border: '1px solid #aaa',
      borderRadius: 4,
      padding: 10,
      backgroundColor: '#fff',
      fontSize: 16,
      zIndex: 1000,
    },
    '& .react-autosuggest__suggestions-list': {
      margin: 0,
      padding: 0,
      listStyleType: 'none',
    },
    '& .react-autosuggest__suggestion': {
      cursor: 'pointer',
      padding: '10px 20px',
    },
    '& .react-autosuggest__suggestion--highlighted': {
      backgroundColor: '#ddd',
    },
    '& .react-autosuggest__section-container': {
      borderTop: '1px dashed #ccc',
    },
    '& .react-autosuggest__section-container--first': {
      borderTop: 0,
    },
    '& .react-autosuggest__section-title': {
      padding: '10px 0 0 10px',
      fontSize: 16,
      color: 'gray',
    },
    '& .highlight': {
      fontWeight: 'bold',
      color: 'black',
    },
  },
})

const AutoSuggestTopic = ({
  suggestedTopics,
  handleSetFormState,
  initialValue = '',
  initialText = 'Type group default topic',
  classes,
}) => {
  const [value, setValue] = useState(initialValue)
  const [suggestions, setSuggestions] = useState([])

  const escapeRegexCharacters = (str) =>
    str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')

  const getSuggestions = (newValue) => {
    const escapedValue = escapeRegexCharacters(newValue.trim())
    if (escapedValue === '') {
      return []
    }
    return suggestedTopics
      .map((section) => {
        return {
          skillGroupName: section.skillGroupName,
          skills: section.skills.filter(
            (subtopic) =>
              subtopic.skillName
                .toUpperCase()
                .includes(escapedValue.toUpperCase()) ||
              section.skillGroupName
                .toUpperCase()
                .includes(escapedValue.toUpperCase()),
          ),
        }
      })
      .filter((section) => section.skills.length > 0)
  }

  const renderSuggestion = (suggestion, { query }) => {
    const suggestionText = `${suggestion.skillName}`
    const matches = AutoSuggestHighlightMatch(suggestionText, query)
    const parts = AutoSuggestHighlightParse(suggestionText, matches)

    return (
      <span className="suggestion-content">
        <span className="name">
          {parts.map((part, index) => {
            const className = part.highlight ? 'highlight' : null
            return (
              // eslint-disable-next-line react/no-array-index-key
              <span className={className} key={index}>
                {part.text}
              </span>
            )
          })}
        </span>
      </span>
    )
  }

  const renderSectionTitle = (section) => (
    <strong>{section.skillGroupName}</strong>
  )

  const getSectionSuggestions = (section) => section.skills

  return (
    <div className={`${classes.autoSuggest}`}>
      <AutoSuggest
        multiSection
        suggestions={suggestions}
        onSuggestionsClearRequested={() => setSuggestions([])}
        onSuggestionsFetchRequested={({ value: innerValue }) => {
          setValue(innerValue)
          setSuggestions(getSuggestions(innerValue))
        }}
        onSuggestionSelected={(_, { suggestion }) => {
          handleSetFormState(suggestion)
        }}
        getSuggestionValue={(suggestion) => suggestion.skillName}
        renderSuggestion={renderSuggestion}
        renderSectionTitle={renderSectionTitle}
        getSectionSuggestions={getSectionSuggestions}
        inputProps={{
          placeholder: initialText,
          value,
          onChange: (event, { newValue, __ }) => {
            setValue(newValue)
          },
        }}
      />
    </div>
  )
}

export default withStyles(styles)(AutoSuggestTopic)
