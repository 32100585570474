// @flow
import React, { useCallback, useState } from 'react'
import {
  Button,
  Divider,
  FormControlLabel,
  Switch,
  TextField,
} from '@material-ui/core'
import LocalHospitalIcon from '@material-ui/icons/LocalHospital'
import { withStyles } from '@material-ui/core/styles'
import AutoSuggestTopic from './commons/AutoSuggestTopic'
// import suggestedTopics from './topics'
import 'isomorphic-fetch'
import type { SkillGroup } from '../../skills/actions/loadSkillGroups'

type GroupFormProps = {
  onSubmit: ({
    name: string,
    description: string,
    prefix: string | null,
    logo: File | null,
    banner: File | null,
    whiteLabelLogo: File | null,
    whiteLabelVideoURL: string | null,
    defaultSkill: number,
    recodingValue: boolean,
    isMailingValue: Boolean,
    isActive: Boolean,
    isFake: boolean,
  }) => void,
  group?: {
    name: string,
    description: string,
    prefix: string,
    logo: string,
    banner: string,
    whiteLabelLogo: string,
    whiteLabelVideoURL: string,
    defaultSkill: number,
    recodingValue: boolean,
    isMailingValue: Boolean,
    isActive: Boolean,
    isFake: boolean,
  },
  editable: boolean,
  classes: { [string]: string },
  skillGroups: SkillGroup[],
}

const styles = (theme) => ({
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
})

const GroupForm = ({
  onSubmit,
  skillGroups = [],
  group: {
    name = '',
    description = '',
    prefix = '',
    logo: logoUrl,
    banner: bannerUrl,
    whiteLabelLogo: incomingWLLogo,
    whiteLabelVideoURL: incomingVideoURL,
    defaultSkill,
    recodingValue = false,
    isMailingValue = false,
    isActive,
    isFake,
  } = {},
  editable,
  classes,
}: GroupFormProps) => {
  const [nameValue, setNameValue] = useState(name)
  const [prefixValue, setPrefixValue] = useState(prefix)
  const [descriptionValue, setDescriptionValue] = useState(description)
  const [logo, setLogo] = useState(null)
  const [logoValue, setLogoValue] = useState(logoUrl || 'Upload Logo')
  const [banner, setBanner] = useState(null)
  const [bannerValue, setBannerValue] = useState(bannerUrl || 'Upload Banner')
  const [whiteLabelLogo, setWhiteLabelLogo] = useState(null)
  const [whiteLabelLogoValue, setWhiteLabelLogoValue] = useState(
    prefix ? incomingWLLogo : 'Upload White Label Logo',
  )
  const [whiteLabelVideoURL, setWhiteLabelVideoURL] = useState(incomingVideoURL)
  const [defaultTopic, setDefaultTopic] = useState(
    defaultSkill !== undefined ? defaultSkill : 206,
  )
  const [suggestedTopics] = useState(skillGroups)
  const [recordingOption, setRecordingOption] = useState(recodingValue)
  const [isMailing, setIsMailing] = useState(isMailingValue)
  const [isActiveVal, setIsActiveVal] = useState(isActive)
  const [isFakeVal, setIsFakeVal] = useState(isFake)

  const getSkillName = (skillNumber) => {
    const skillGroupIncludeSkill =
      suggestedTopics &&
      suggestedTopics.find(({ skills }) =>
        skills.find(({ skillId }) => skillId === skillNumber),
      )
    return (
      skillGroupIncludeSkill &&
      skillGroupIncludeSkill.skills.find(
        ({ skillId }) => skillId === skillNumber,
      ).skillName
    )
  }

  const save = (e) => {
    e.preventDefault()
    onSubmit({
      defaultSkill: defaultTopic,
      name: nameValue,
      description: descriptionValue,
      prefix: prefixValue,
      logo,
      banner,
      whiteLabelLogo,
      whiteLabelVideoURL,
      recodingValue: recordingOption,
      isMailingValue: isMailing,
      isActive: isActiveVal,
      isFake: isFakeVal,
    })
  }

  const handleSetFormState = useCallback((value) => {
    setDefaultTopic(value.skillId)
  }, [])

  return (
    <form onSubmit={save} disabled={!editable}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline',
          height: '56px',
        }}
      >
        <TextField
          label="Name"
          variant="outlined"
          value={nameValue}
          onChange={(e) => setNameValue(e.target.value)}
          disabled={!editable}
          style={{ width: '30%', marginBottom: 30 }}
        />
        <FormControlLabel
          control={
            <Switch
              checked={recordingOption}
              onChange={() => setRecordingOption((value) => !value)}
              name="checkeRecording"
            />
          }
          label="Recording group"
        />
        <FormControlLabel
          control={
            <Switch
              checked={isMailing}
              onChange={() => setIsMailing((value) => !value)}
              name="checkIsMailing"
            />
          }
          label="Enable Emails"
        />
        <FormControlLabel
          control={
            <Switch
              checked={isActiveVal}
              onChange={() => setIsActiveVal((value) => !value)}
              name="checkIsActive"
            />
          }
          label="Is Active?"
        />
        <FormControlLabel
          control={
            <Switch
              checked={isFakeVal}
              onChange={() => setIsFakeVal((value) => !value)}
              name="checkIsFake"
            />
          }
          label="Is Fake?"
        />
      </div>
      <br />
      <TextField
        label="Add white label prefix"
        variant="outlined"
        value={prefixValue}
        onChange={(e) => setPrefixValue(e.target.value)}
        disabled={!editable}
        style={{ width: '30%', marginBottom: 30 }}
      />
      <br />
      <TextField
        label="Add white label video URL"
        variant="outlined"
        value={whiteLabelVideoURL}
        onChange={(e) => setWhiteLabelVideoURL(e.target.value)}
        disabled={!editable}
        style={{ width: '30%', marginBottom: 30 }}
      />
      <br />
      <TextField
        label="Group Description"
        variant="outlined"
        value={descriptionValue}
        multiline
        fullWidth
        rows={6}
        onChange={(e) => setDescriptionValue(e.target.value)}
        disabled={!editable}
        required
        style={{ marginBottom: 30 }}
      />
      <br />
      <div style={{ paddingLeft: 12, fontSize: '16px' }}>Default Topic</div>
      <AutoSuggestTopic
        suggestedTopics={suggestedTopics}
        handleSetFormState={handleSetFormState}
        classes={classes}
        initialText={
          defaultSkill === undefined
            ? 'Type group default topic'
            : getSkillName(defaultTopic)
        }
      />
      <br />
      <TextField
        variant="outlined"
        value={logoValue}
        style={{ width: '45%', marginBottom: 30 }}
        disabled
      />
      <label htmlFor="raised-button-file-image">
        <input
          accept="image/*"
          className={classes.input}
          style={{ display: 'none' }}
          id="raised-button-file-image"
          type="file"
          onChange={(e) => {
            setLogo(e.currentTarget.files[0])
            setLogoValue(e.currentTarget.files[0].name)
          }}
        />
        <LocalHospitalIcon style={{ fontSize: '3rem' }} />
      </label>
      <br />
      <TextField
        variant="outlined"
        value={bannerValue}
        disabled
        style={{ width: '45%', marginBottom: 30 }}
      />
      <label htmlFor="raised-button-file-banner">
        <input
          accept="image/*"
          className={classes.input}
          style={{ display: 'none' }}
          id="raised-button-file-banner"
          type="file"
          onChange={(e) => {
            setBanner(e.currentTarget.files[0])
            setBannerValue(e.currentTarget.files[0].name)
          }}
        />
        <LocalHospitalIcon style={{ fontSize: '3rem' }} />
      </label>
      <br />
      <TextField
        variant="outlined"
        value={whiteLabelLogoValue}
        disabled
        style={{ width: '45%', marginBottom: 30 }}
      />
      <label htmlFor="raised-button-file-whitelabel-logo">
        <input
          accept="image/*"
          className={classes.input}
          style={{ display: 'none' }}
          id="raised-button-file-whitelabel-logo"
          type="file"
          onChange={(e) => {
            setWhiteLabelLogo(e.currentTarget.files[0])
            setWhiteLabelLogoValue(e.currentTarget.files[0].name)
          }}
        />
        <LocalHospitalIcon style={{ fontSize: '3rem' }} />
      </label>
      <Divider className={classes.divider} />
      <Button
        variant="contained"
        color="primary"
        type="submit"
        disabled={!editable}
      >
        Save
      </Button>
    </form>
  )
}

GroupForm.defaultProps = {
  group: {
    name: '',
    description: '',
    prefix: '',
    logo: '',
    banner: '',
    whiteLabelLogo: '',
    whiteLabelVideoURL: '',
    recodingValue: false,
    isActive: true,
    isFake: false,
  },
}

export default withStyles(styles)(GroupForm)
