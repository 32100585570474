// @flow
import React from 'react'
import clsx from 'clsx'
import { withStyles } from '@material-ui/core/styles'
import { amber } from '@material-ui/core/colors'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import WarningIcon from '@material-ui/icons/Warning'
import ErrorIcon from '@material-ui/icons/Error'

type WarningsProps = {
  warnings: Array<{ message: string, type: 'error' | 'warning' }>,
  classes: { [string]: string },
}

const styles = (theme) => ({
  wrapper: {
    borderRadius: 2,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  warning: {
    backgroundColor: amber[700],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
})

const Warnings = ({ warnings, classes }: WarningsProps) => (
  <List>
    {warnings.map(({ message, type }) => (
      <ListItem
        key={message}
        className={clsx({
          [classes.wrapper]: true,
          [classes.warning]: type === 'warning',
          [classes.error]: type === 'error',
        })}
      >
        <ListItemIcon>
          {type === 'error' && <ErrorIcon />}
          {type === 'warning' && <WarningIcon />}
        </ListItemIcon>
        <ListItemText primary={message} />
      </ListItem>
    ))}
  </List>
)

export default withStyles(styles)(Warnings)
