import pushNotification from '../../notifications/actions/pushNotification'

const middleware =
  () =>
  ({ dispatch }) =>
  (next) =>
  (action) => {
    if ('type' in action) {
      switch (action.type) {
        case 'users::RESET_PASSWORD':
          dispatch(
            pushNotification({
              title: 'Password reset sent',
              type: 'success',
              message: 'A password reset email has been sent to the user.',
            }),
          )
          break
        case 'users::EDITED_USER':
          dispatch(
            pushNotification({
              title: 'Profile saved',
              type: 'success',
            }),
          )
          break
        case 'users::LINKED_ACCOUNT':
          dispatch(
            pushNotification({
              title: 'Account linked',
              type: 'success',
              message:
                'This profile has been linked to the specified Auth0 account.',
            }),
          )
          break
        case 'users::CREATED_ACCOUNT':
          dispatch(
            pushNotification({
              title: 'Account created',
              type: 'success',
            }),
          )
          break
        default:
      }
    }

    return next(action)
  }

export default middleware
