// @flow
import { connect } from 'react-redux'
import Permission from '../components/Permission/List'
import type { State } from '../../../store'
import addGroupAdmin from '../actions/addGroupAdmin'
import removeGroupAdmin from '../actions/removeGroupAdmin'
import { loadAllGroups } from '../../groups/actions'

const mapStateToProps = ({
  users: { loaded: { data: { groups = [] } = {} } = {} } = {},
}: State) => ({
  groups: groups.sort((group1, group2) =>
    group1.name.localeCompare(group2.name),
  ),
})

const mapDispatchToProps = {
  addGroupAdmin,
  removeGroupAdmin,
  load: loadAllGroups,
}

export default connect(mapStateToProps, mapDispatchToProps)(Permission)
