// @flow
import './version'
// import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'typeface-work-sans'
import 'typeface-roboto-condensed'
import './setup'
import React from 'react'
// v16
import ReactDOM from 'react-dom'
// v18
// import { createRoot } from 'react-dom'
import App from './App'
import loadConfig from './config/init'
// import GlobalErrorBoundary from '@/errors/GlobalErrorBoundary'
// import * as serviceWorker from './serviceWorker'
// import './index.css'

// function StartApp() {
//   makeGlobalLogger()
//   return (
//     <GlobalErrorBoundary>
//       <App />
//     </GlobalErrorBoundary>
//   )
// }

async function main() {
  try {
    const config = await loadConfig
    // v16
    // ReactDOM.render(<App />, document.getElementById('root'))
    ReactDOM.render(<App config={config} />, document.getElementById('app'))
    // v18
    // const root = createRoot(document.getElementById('root'))
    // root.render(<StartApp />, document.getElementById('root'))
  } catch (error) {
    // eslint-disable-next-line no-console -- allow console before global logger instatiated
    console.error('Failed to initialize the application', error)
  }
}

main()
