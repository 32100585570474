// @flow
import type {
  Dispatch,
  GetState,
  ThunkExtraArgument,
  ThunkAction,
} from '../../../store'
import { ApiError, type API } from '../../../api/api'
import load from '../../../actions/load'

type SetActiveReport = 'reports::SET_ACTIVE'
export type SetActiveReportAction = { type: SetActiveReport, id: string }

type LoadingReport = 'reports::LOADING_REPORT'
export type LoadingReportAction = { type: LoadingReport, loading: boolean }

type LoadedReport = 'reports::LOADED_REPORT'
export type LoadedReportAction = { type: LoadedReport, data: string }

type GetReportResult = { data: string }

const getReport = async (api: API, id: string): Promise<GetReportResult> => {
  try {
    const report = await api.invokeOperation('admin.getReport', {
      params: { reportName: id },
      headers: new Headers({ accept: 'text/csv' }),
    })
    const data = await report.text()
    return { data }
  } catch (err) {
    if (err instanceof ApiError) {
      throw err.wrap(`getting "${id}" report`)
    }
    throw err
  }
}

export default (reportId: string): ThunkAction =>
  (dispatch: Dispatch, getState: GetState, { api }: ThunkExtraArgument) => {
    dispatch({ type: 'reports::SET_ACTIVE', id: reportId })
    dispatch(
      load(
        () => getReport(api, reportId),
        (loading: boolean) => ({ type: 'reports::LOADING_REPORT', loading }),
        ({ data }: GetReportResult) => ({
          type: 'reports::LOADED_REPORT',
          data,
        }),
      ),
    )
  }
