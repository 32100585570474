// @flow
import React, { useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Avatar from '@material-ui/core/Avatar'
import Link from '@material-ui/core/Link'
import IconButton from '@material-ui/core/IconButton'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import { Link as RouterLink } from 'react-router-dom'
import Pagination from '../../../components/Table/Pagination'

type User = {
  id: number,
  firstName: string,
  lastName: string,
  email: string,
  avatar: string,
  externalId?: ?string,
}

type UsersTableProps = {
  loadUsers: () => Promise<void>,
  data?: User[],
  loading?: boolean,
  hasFirst?: boolean,
  hasNext?: boolean,
  hasPrev?: boolean,
  hasLast?: boolean,
  gotoFirst: () => Promise<void>,
  gotoNext: () => Promise<void>,
  gotoPrev: () => Promise<void>,
  gotoLast: () => Promise<void>,
  classes: { [string]: string },
}

const styles = (theme) => ({
  user: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
})

const UsersTable = ({
  loadUsers,
  data = [],
  loading = false,
  hasFirst = false,
  hasNext = false,
  hasPrev = false,
  hasLast = false,
  gotoFirst,
  gotoNext,
  gotoPrev,
  gotoLast,
  classes,
}: UsersTableProps) => {
  useEffect(() => {
    loadUsers()
  }, [loadUsers])

  if (loading) {
    return <CircularProgress />
  }

  if (!data || !Array.isArray(data) || data.length < 1) {
    return <p>No users exist.</p>
  }

  return (
    <>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map(({ id, firstName, lastName, email, avatar }) => (
              <TableRow key={id}>
                <TableCell className={classes.user}>
                  <Avatar src={avatar} className={classes.avatar} />
                  <Link component={RouterLink} to={`/users/${id}/profile`}>
                    {`${[firstName, lastName].filter(Boolean).join(' ') || '(unset)'}`}
                  </Link>
                </TableCell>
                <TableCell>
                  <a href={`mailto:${email}`}>{email}</a>
                </TableCell>
                <TableCell>
                  <IconButton
                    component={RouterLink}
                    to={`/users/${id}/profile`}
                  >
                    <AssignmentIndIcon fontSize="small" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        hasFirst={hasFirst}
        hasNext={hasNext}
        hasPrev={hasPrev}
        hasLast={hasLast}
        gotoFirst={gotoFirst}
        gotoNext={gotoNext}
        gotoPrev={gotoPrev}
        gotoLast={gotoLast}
      />
    </>
  )
}

UsersTable.defaultProps = {
  data: null,
  loading: false,
  hasFirst: false,
  hasNext: false,
  hasPrev: false,
  hasLast: false,
}

export default withStyles(styles)(UsersTable)
