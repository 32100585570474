// @flow
import React, { useEffect } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import PeopleIcon from '@material-ui/icons/People'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import EditGroup from '../containers/EditGroup'
import EditGroupButton from '../containers/EditGroupButton'
import ShowMembersButton from '../containers/ShowMembersButton'
import DeleteGroupButton from '../containers/DeleteGroupButton'
import Members from '../containers/Members'
import Pagination from '../../../components/Table/Pagination'

type GroupsTableProps = {
  loadGroups: () => Promise<void>,
  data?: Array<{ id: number, name: string }> | null,
  loading?: boolean,
  hasNext?: boolean,
  hasPrev?: boolean,
  gotoNext: () => Promise<void>,
  gotoPrev: () => Promise<void>,
}

const GroupsTable = ({
  loadGroups,
  data = [],
  loading = false,
  hasNext = false,
  hasPrev = false,
  gotoNext,
  gotoPrev,
}: GroupsTableProps) => {
  useEffect(() => {
    loadGroups()
  }, [loadGroups])

  if (loading) {
    return <CircularProgress />
  }

  if (!data || !Array.isArray(data) || data.length < 1) {
    return <p>No groups exist.</p>
  }

  return (
    <>
      <EditGroup />
      <Members />
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map(({ id, name }) => (
              <TableRow key={id}>
                <TableCell>
                  <EditGroupButton groupId={id}>{name}</EditGroupButton>
                </TableCell>
                <TableCell>
                  <EditGroupButton groupId={id} component={IconButton}>
                    <EditIcon fontSize="small" />
                  </EditGroupButton>
                  <ShowMembersButton groupId={id} component={IconButton}>
                    <PeopleIcon fontSize="small" />
                  </ShowMembersButton>
                  <DeleteGroupButton
                    groupId={id}
                    confirmationTitle={`Are you sure you want to delete "${name}"?`}
                    confirmationText="Deleting a group permanently removes it from the database. This does not delete its members."
                    confirmationLabel="Delete"
                    startIcon={<DeleteForeverIcon />}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        hasNext={hasNext}
        hasPrev={hasPrev}
        gotoNext={gotoNext}
        gotoPrev={gotoPrev}
      />
    </>
  )
}

GroupsTable.defaultProps = {
  data: null,
  loading: false,
  hasNext: false,
  hasPrev: false,
}

export default GroupsTable
