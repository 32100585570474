// @flow
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import IconButton from '@material-ui/core/IconButton'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import LastPageIcon from '@material-ui/icons/LastPage'

type PaginationProps = {
  hasFirst?: boolean,
  hasNext: boolean,
  hasPrev: boolean,
  hasLast?: boolean,
  gotoFirst?: null | (() => Promise<void>),
  gotoNext: () => Promise<void>,
  gotoPrev: () => Promise<void>,
  gotoLast?: null | (() => Promise<void>),
  classes: { [string]: string },
}

const styles = (theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(1),
  },
})

const Pagination = ({
  hasFirst,
  hasNext,
  hasPrev,
  hasLast,
  gotoFirst,
  gotoNext,
  gotoPrev,
  gotoLast,
  classes,
}: PaginationProps) => (
  <Container className={classes.container}>
    <ButtonGroup size="small">
      {typeof gotoFirst === 'function' && (
        <IconButton onClick={() => gotoFirst()} disabled={!hasFirst}>
          <FirstPageIcon />
        </IconButton>
      )}
      <IconButton onClick={() => gotoPrev()} disabled={!hasPrev}>
        <NavigateBeforeIcon />
      </IconButton>
      <IconButton onClick={() => gotoNext()} disabled={!hasNext}>
        <NavigateNextIcon />
      </IconButton>
      {typeof gotoLast === 'function' && (
        <IconButton onClick={() => gotoLast()} disabled={!hasLast}>
          <LastPageIcon />
        </IconButton>
      )}
    </ButtonGroup>
  </Container>
)

Pagination.defaultProps = {
  hasFirst: false,
  hasLast: false,
  gotoFirst: null,
  gotoLast: null,
}

export default withStyles(styles)(Pagination)
