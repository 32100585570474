// @flow
import type { Dispatch, ThunkAction } from '../../../store'
import loadUsers from './loadUsers'
import { loadMembers } from '../../groups/actions'

type ClearFilter = 'users::CLEAR_FILTER'
export type ClearFilterAction = { type: ClearFilter }

const clearFilter = (visible: boolean = false): ThunkAction => (
  dispatch: Dispatch,
) => {
  dispatch({ type: `${!visible ? 'users' : 'groups'}::CLEAR_FILTER` })
  dispatch(visible ? loadMembers() : loadUsers())
}

export default clearFilter
