// @flow
import React, { useEffect } from 'react'
import {
  NavLink,
  Route,
  Redirect,
  Switch,
  useRouteMatch,
} from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Paper from '@material-ui/core/Paper'
import Banner from './Profile/Banner'
import ManageProfile from './Profile/Manage'
import ManageAccount from './Account/Manage'
import ManageGroups from './Groups/Manage'
import ManagePermission from './Permission/Manage'
import ManageSkills from './Skills/Manage'

type User = {
  id: number,
  firstName: string,
  lastName: string,
  avatar: string,
  email: string,
  externalId: ?string,
  bio: ?string,
}

type UserProfileProps = {
  loading: boolean,
  user: ?User,
  id: number,
  load: (number) => void,
}

const useStyles = makeStyles((theme) => ({
  tabPanel: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(4),
  },
  gutterTop: {
    marginTop: theme.spacing(2),
  },
}))

const UserProfile = ({ loading, user, id, load }: UserProfileProps) => {
  const classes = useStyles()
  const { url, path } = useRouteMatch()

  useEffect(() => {
    load(id)
  }, [id])

  if (loading) {
    return <CircularProgress />
  }

  if (!user) {
    return <Typography>User not found.</Typography>
  }

  return (
    <section>
      <Typography variant="h3" gutterBottom>
        User profile
      </Typography>
      <Banner
        id={id}
        firstName={user.firstName}
        lastName={user.lastName}
        avatar={user.avatar}
        email={user.email}
        externalId={user.externalId}
      />
      <Tabs
        className={classes.gutterTop}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab label="Profile" component={NavLink} to={`${url}/profile`} />
        <Tab label="Account" component={NavLink} to={`${url}/account`} />
        <Tab label="Groups" component={NavLink} to={`${url}/groups`} />
        <Tab label="Skills" component={NavLink} to={`${url}/skills`} />
        <Tab
          label="Permissions"
          component={NavLink}
          to={`${url}/permissions`}
        />
      </Tabs>
      <Paper variant="outlined" square className={classes.tabPanel}>
        <Switch>
          <Route exact path={path}>
            <Redirect to={`${path}/profile`} />
          </Route>
          <Route path={`${path}/profile`} component={ManageProfile} />
          <Route path={`${path}/account`} component={ManageAccount} />
          <Route path={`${path}/groups`} component={ManageGroups} />
          <Route path={`${path}/skills`} component={ManageSkills} />
          <Route path={`${path}/permissions`} component={ManagePermission} />
        </Switch>
      </Paper>
    </section>
  )
}

export default UserProfile
