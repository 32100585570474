// @flow
import type { Schema } from './loadSchemas'
import type { Dispatch, GetState } from '../../../store'

type ShowEditSchema = 'schemas::SHOW_EDIT_SCHEMA'
export type ShowEditSchemaAction = {
  type: ShowEditSchema,
  data: Schema,
}

export const showEditSchema = (schemaId: number) => (
  dispatch: Dispatch,
  getState: GetState,
) => {
  const { schemas: { listing: { data = [] } = {} } = {} } = getState()
  const schema = data.find(({ id }) => id === schemaId)

  if (!schema) {
    throw new Error(`unable to load schema with id "${schemaId}"`)
  }

  dispatch({
    type: 'schemas::SHOW_EDIT_SCHEMA',
    data: schema,
  })
}

type HideEditSchema = 'schemas::HIDE_EDIT_SCHEMA'
export type HideEditSchemaAction = { type: HideEditSchema }

export const hideEditSchema = () => ({
  type: 'schemas::HIDE_EDIT_SCHEMA',
})
