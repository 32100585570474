// @flow
import { connect } from 'react-redux'
import { hideMembers } from '../actions'
import MembersPanel from '../components/MembersPanel'
import { State } from '../../../store'

const mapStateToProps = ({
  groups: { members: { visible } = {} } = {},
}: State) => ({
  open: visible,
})

const mapDispatchToProps = {
  close: hideMembers,
}

export default connect(mapStateToProps, mapDispatchToProps)(MembersPanel)
