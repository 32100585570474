// @flow
import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Switch from '@material-ui/core/Switch'

type DeleteDialogProps = {
  deleteProfile: ({ deleteAccount: boolean }) => void,
  disabled: boolean,
  hasAccount: boolean,
}

const DeleteDialog = ({
  deleteProfile,
  disabled,
  hasAccount,
  ...buttonProps
}: DeleteDialogProps) => {
  const [visible, setVisible] = useState(false)
  const [deleteAccount, setDeleteAccount] = useState(hasAccount)
  const close = () => setVisible(false)

  return (
    <>
      <Dialog open={visible} onClose={() => close()}>
        <DialogTitle>Are you sure you want to delete this profile?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This will permanently delete the user&apos;s profile, and all
            associated information.
          </DialogContentText>
          {hasAccount && (
            <FormControl>
              <FormControlLabel
                control={
                  <Switch
                    checked={deleteAccount}
                    onChange={(e) => setDeleteAccount(e.target.checked)}
                  />
                }
                label="Delete the Auth0 acccount?"
              />
              <FormHelperText>
                Uncheck this toggle to keep the Auth0 account. The user may
                continue to log in with their existing credentials, but the next
                time they log in, they will have to create a new profile.
              </FormHelperText>
            </FormControl>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => close()}>Cancel</Button>
          <Button
            onClick={() => {
              deleteProfile({ deleteAccount })
              close()
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Button
        disabled={visible || disabled}
        onClick={() => setVisible(true)}
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...buttonProps}
      />
    </>
  )
}

export default DeleteDialog
