// @flow
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ViewActiveSchemas from '../../modules/schemas/containers/ViewActiveSchemas'

const styles = (theme) => ({
  spacer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
})

const Schemas = ({ classes }: { classes: { [string]: string } }) => (
  <>
    <Typography variant="h3">Schemas</Typography>
    <div className={classes.spacer} />
    <ViewActiveSchemas />
  </>
)

export default withStyles(styles)(Schemas)
