// @flow
import { ApiError, type API } from '../../../api/api'
import type {
  ThunkAction,
  Dispatch,
  GetState,
  ThunkExtraArgument,
} from '../../../store'
import load from '../../../actions/load'

export type Group = {
  id: number,
  name: string,
}

async function* pages(res: Response, api: API) {
  yield await res.json()
  let links = api.parseLinks(res)
  while (links.exists('next')) {
    // eslint-disable-next-line no-await-in-loop
    const nextPage = await links.follow('next')
    // eslint-disable-next-line no-await-in-loop
    const data = await nextPage.json()
    yield data
    links = api.parseLinks(nextPage)
  }
}

const loadAllGroups = async (api: API) => {
  try {
    let allGroups = []
    for await (const groups of pages(
      await api.invokeOperation('admin.getGroups'),
      api,
    )) {
      allGroups = [...allGroups, ...groups]
    }
    return allGroups
  } catch (err) {
    if (err instanceof ApiError) {
      throw err.wrap('loading all groups')
    }
    throw err
  }
}

type LoadingGroups = 'imports::LOADING_GROUPS'
export type LoadingGroupsAction = { type: LoadingGroups, loading: boolean }

type LoadedGroups = 'imports::LOADED_GROUPS'
export type LoadedGroupsAction = { type: LoadedGroups, data: Group[] }

export default (): ThunkAction =>
  (dispatch: Dispatch, getState: GetState, { api }: ThunkExtraArgument) =>
    dispatch(
      load(
        () => loadAllGroups(api),
        (loading: boolean) => ({ type: 'imports::LOADING_GROUPS', loading }),
        (data: Group[]) => ({ type: 'imports::LOADED_GROUPS', data }),
      ),
    )
