// @flow
import type { User, Link } from './actions/loadUsers'
import type { UserData } from './actions/loadUser'
import type { Action } from '../../store'

export type State = {
  listing: {
    loading: boolean,
    data?: User[] | null,
    links?: Link[] | null,
    filter?: {
      groupId?: number,
      email?: string,
      name?: string,
    },
  },
  loaded: {
    loading: boolean,
    saving: boolean,
    deleting: boolean,
    id?: number,
    data?: UserData | null,
  },
  account: {
    creating: boolean,
    linking: boolean,
    resetting: boolean,
    deleting: boolean,
  },
  group: {
    joining: boolean,
    leaving: boolean,
  },
}

const defaultState = {
  listing: { loading: false, filter: { email: '', name: '' } },
  loaded: { loading: false, saving: false, deleting: false },
  account: {
    creating: false,
    linking: false,
    resetting: false,
    deleting: false,
  },
  group: { joining: false, leaving: false },
}

export default (state: State = defaultState, action: Action) => {
  switch (action.type) {
    case 'users::LOADING_USER': {
      const { loading } = action
      const { loaded = {} } = state
      return { ...state, loaded: { ...loaded, loading } }
    }
    case 'users::LOADED_USER': {
      const { data: { id, ...data } = {} } = action
      const { loaded = {} } = state
      return { ...state, loaded: { ...loaded, id, data } }
    }
    case 'users::LOADING_USERS': {
      const { loading } = action
      const { listing = {} } = state
      return { ...state, listing: { ...listing, loading } }
    }
    case 'users::LOADED_USERS': {
      const { data, links } = action
      const { listing = {} } = state
      return { ...state, listing: { ...listing, data, links } }
    }
    case 'users::EDITING_USER': {
      const { saving } = action
      const { loaded = {} } = state
      return { ...state, loaded: { ...loaded, saving } }
    }
    case 'users::EDITED_USER': {
      const { data } = action
      const { loaded: { data: existingData, ...loaded } = {} } = state
      return {
        ...state,
        loaded: { ...loaded, data: { ...existingData, ...data } },
      }
    }
    case 'users::DELETING_USER': {
      const { deleting } = action
      const { loaded = {} } = state
      return { ...state, loaded: { ...loaded, deleting } }
    }
    case 'users::DELETED_USER': {
      const { data: profileId } = action
      const { loaded, listing = {} } = state
      const { id: deletedId, data: loadedProfile, ...restLoaded } = loaded || {}
      const { data: profileListing, ...restListing } = listing || {}
      // $FlowFixMe
      const listingData = (profileListing || []).filter(
        ({ id }) => id !== profileId,
      )
      return {
        ...state,
        loaded: restLoaded,
        listing: { ...restListing, data: listingData },
      }
    }
    case 'users::LINKING_ACCOUNT': {
      const { saving: linking } = action
      const { account = {} } = state
      return { ...state, account: { ...account, linking } }
    }
    case 'users::LINKED_ACCOUNT': {
      const { data: externalId } = action
      const { loaded: { data, ...loaded } = {} } = state
      return { ...state, loaded: { ...loaded, data: { ...data, externalId } } }
    }
    case 'users::CREATING_ACCOUNT': {
      const { saving: creating } = action
      const { account = {} } = state
      return { ...state, account: { ...account, creating } }
    }
    case 'users::CREATED_ACCOUNT': {
      const { data: externalId } = action
      const { loaded: { data, ...loaded } = {} } = state
      return { ...state, loaded: { ...loaded, data: { ...data, externalId } } }
    }
    case 'users::DELETING_ACCOUNT': {
      const { deleting } = action
      const { account = {} } = state
      return { ...state, account: { ...account, deleting } }
    }
    case 'users::DELETED_ACCOUNT': {
      const { loaded: { data, ...loaded } = {} } = state
      const { externalId, ...rest } = data || {}
      return { ...state, loaded: { ...loaded, data: rest } }
    }
    case 'users::JOINING_GROUP': {
      const { saving } = action
      const { group = {} } = state
      return { ...state, group: { ...group, joining: saving } }
    }
    case 'users::JOINED_GROUP': {
      const { data: group } = action
      const { loaded: { data = {}, ...loaded } = {} } = state
      const { groups } = data || {}
      return {
        ...state,
        loaded: {
          ...loaded,
          data: { ...data, groups: [...(groups || []), group] },
        },
      }
    }
    case 'users::LEAVING_GROUP': {
      const { saving } = action
      const { group = {} } = state
      return { ...state, group: { ...group, leaving: saving } }
    }
    case 'users::LEFT_GROUP': {
      const { data: groupId } = action
      const { loaded: { data = {}, ...loaded } = {} } = state
      const { groups: currentGroups } = data || {}
      // $FlowFixMe
      const groups = (currentGroups || []).filter(({ id }) => id !== groupId)
      return { ...state, loaded: { ...loaded, data: { ...data, groups } } }
    }
    case 'users::ADDING_GROUP_ADMIN': {
      const { saving } = action
      const { group = {} } = state
      return { ...state, group: { ...group, adding: saving } }
    }
    case 'users::ADDED_GROUP_ADMIN': {
      const { loaded: { data = {}, ...loaded } = {} } = state
      return {
        ...state,
        loaded: {
          ...loaded,
          data: { ...data },
        },
      }
    }
    case 'users::REMOVING_GROUP_ADMIN': {
      const { saving } = action
      const { group = {} } = state
      return { ...state, group: { ...group, removing: saving } }
    }
    case 'users::REMOVED_GROUP_ADMIN': {
      const { loaded: { data = {}, ...loaded } = {} } = state
      return {
        ...state,
        loaded: {
          ...loaded,
          data: { ...data },
        },
      }
    }
    case 'users::RESETTING_PASSWORD': {
      const { saving } = action
      const { account = {} } = state
      return { ...state, account: { ...account, resetting: saving } }
    }
    case 'users::SET_EMAIL_FILTER': {
      const { value: email } = action
      const { listing: { filter = {}, ...listing } = {} } = state
      return {
        ...state,
        listing: {
          ...listing,
          filter: {
            ...filter,
            email,
          },
        },
      }
    }
    case 'users::SET_NAME_FILTER': {
      const { value: name } = action
      const { listing: { filter = {}, ...listing } = {} } = state
      return {
        ...state,
        listing: {
          ...listing,
          filter: {
            ...filter,
            name,
          },
        },
      }
    }
    case 'users::SET_GROUP_FILTER': {
      const { value: groupId } = action
      if (typeof groupId !== 'number') {
        const {
          listing: {
            filter: { groupId: oldValue, ...rest } = {},
            ...listing
          } = {},
        } = state
        return {
          ...state,
          listing: {
            ...listing,
            filter: rest,
          },
        }
      }
      const { listing: { filter = {}, ...listing } = {} } = state
      return {
        ...state,
        listing: {
          ...listing,
          filter: {
            ...filter,
            groupId,
          },
        },
      }
    }
    case 'users::CLEAR_FILTER': {
      const { listing: { filter, ...listing } = {} } = state
      return {
        ...state,
        listing: {
          ...listing,
          filter: {},
        },
      }
    }
    default:
      return state
  }
}
