// @flow
import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import AvailableGroupList from '../../containers/AvailableGroupList'
import UserGroupList from '../../containers/UserGroupList'

const Manage = () => {
  return (
    <Grid container spacing={2}>
      <Grid item sm={6}>
        <Typography variant="h6" component="h5">Member of</Typography>
        <UserGroupList />
      </Grid>
      <Grid item sm={6}>
        <Typography variant="h6" component="h5">Join group</Typography>
        <AvailableGroupList />
      </Grid>
    </Grid>
  )
}

export default Manage
