// @flow
import { connect } from 'react-redux'
import Table from '../components/Skills/Table'
import type { State } from '../../../store'

const mapStateToProps = ({
  users: { loaded: { data: { topics = [] } = {} } = {} } = {},
}: State) => ({
  skills: topics,
})

export default connect(mapStateToProps)(Table)
