// @flow
import React from 'react'
import Grid from '@material-ui/core/Grid'
import UserGroupList from '../../containers/UserPermission'
import GlobalPermission from '../../containers/GlobalPermission'

const Manage = () => {
  return (
    <Grid container spacing={2}>
      <UserGroupList />
      <GlobalPermission />
    </Grid>
  )
}

export default Manage
