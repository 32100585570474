// @flow
import type { Group, Link } from './actions/loadGroups'
import type { Member } from './actions/loadMembers'
import type { Action } from '../../store'

export type State = {
  create: {
    visible: boolean,
    saving: boolean,
    data?: Group,
  },
  edit: {
    visible: boolean,
    saving: boolean,
    saved: boolean,
    data?: Group,
  },
  listing: {
    loading: boolean,
    deleting: boolean,
    data?: Group[] | null,
    links?: Link[] | null,
  },
  members: {
    loading: boolean,
    data?: Member[] | null,
    links?: Link[] | null,
    group?: Group | null,
    filter?: {
      email?: string,
      name?: string,
    },
  },
}

const defaultState = {
  create: { visible: false, saving: false },
  edit: { visible: false, saving: false, saved: false },
  listing: { loading: false, deleting: false },
  members: { loading: false },
}

export default (state: State = defaultState, action: Action) => {
  switch (action.type) {
    case 'groups::LOADING_GROUPS': {
      const { loading } = action
      const { listing = {} } = state
      return { ...state, listing: { ...listing, loading } }
    }
    case 'groups::LOADED_GROUPS': {
      const { data, links } = action
      const { listing = {} } = state
      return { ...state, listing: { ...listing, data, links } }
    }
    case 'groups::DELETING_GROUPS': {
      const { deleting } = action
      const { listing = {} } = state
      return { ...state, listing: { ...listing, deleting } }
    }
    case 'groups::DELETED_GROUP': {
      const { data: groupId } = action
      const { listing: { data, ...listing } = {} } = state
      // $FlowFixMe
      const remainingGroups = (data || []).filter(({ id }) => id !== groupId)
      return { ...state, listing: { ...listing, data: remainingGroups } }
    }
    case 'groups::SHOW_CREATE_GROUP': {
      const { visible } = action
      return { ...state, create: { visible } }
    }
    case 'groups::CREATING_GROUP': {
      const { saving } = action
      const { create = {} } = state
      return { ...state, create: { ...create, saving } }
    }
    case 'groups::CREATED_GROUP': {
      const { data } = action
      const { create = {} } = state
      return { ...state, create: { ...create, data } }
    }
    case 'groups::SHOW_EDIT_GROUP': {
      const { data } = action
      return { ...state, edit: { visible: true, data } }
    }
    case 'groups::HIDE_EDIT_GROUP': {
      return { ...state, edit: { visible: false } }
    }
    case 'groups::EDITING_GROUP': {
      const { saving } = action
      const { edit = {} } = state
      return { ...state, edit: { ...edit, saving } }
    }
    case 'groups::EDITED_GROUP': {
      const { data } = action
      const { edit = {} } = state
      return { ...state, edit: { ...edit, data, saved: true } }
    }
    case 'groups::LOADING_MEMBERS': {
      const { loading } = action
      const { members = {} } = state
      return { ...state, members: { ...members, loading } }
    }
    case 'groups::LOADED_MEMBERS': {
      const { data, links } = action
      const { members = {} } = state
      return { ...state, members: { ...members, data, links } }
    }
    case 'groups::SHOW_MEMBERS': {
      const { group, filters } = action
      return { ...state, members: { visible: true, group, filters } }
    }
    case 'groups::HIDE_MEMBERS': {
      return { ...state, members: { visible: false, filters: {} } }
    }
    case 'groups::SET_NAME_FILTER': {
      const { value: name } = action
      const { members: { filter = {}, ...members } = {} } = state
      return {
        ...state,
        members: {
          ...members,
          filter: {
            ...filter,
            name,
          },
        },
      }
    }
    case 'groups::SET_EMAIL_FILTER': {
      const { value: email } = action
      const { members: { filter = {}, ...members } = {} } = state
      return {
        ...state,
        members: {
          ...members,
          filter: {
            ...filter,
            email,
          },
        },
      }
    }
    case 'groups::CLEAR_FILTER': {
      const { members: { filter, ...members } = {} } = state
      return {
        ...state,
        members: {
          ...members,
          filter: {},
        },
      }
    }
    default:
      return state
  }
}
