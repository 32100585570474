// @flow
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import { showCreateGroup } from '../actions'
import { State, Dispatch } from '../../../store'

const mapStateToProps = ({
  groups: { create: { visible } = {} } = {},
}: State) => ({
  disabled: visible,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onClick: () => dispatch(showCreateGroup(true)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Button)
