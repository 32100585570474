// @flow
import 'isomorphic-fetch'
import linkHeader from 'http-link-header'
import { getWafToken } from './get-waf-token'

const formatQuery = (query: { [string]: mixed }): string =>
  Object.entries(query)
    .map(
      ([key, val]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(String(val))}`,
    )
    .join('&')

const setParams = (endpoint: string, params: { [string]: mixed }): string => {
  const pathParam = /\{(\w+)\}/
  let path = endpoint
  let match
  // eslint-disable-next-line no-cond-assign
  while ((match = pathParam.exec(path)) !== null) {
    const [fullMatch, identifier] = match || []
    if (!(identifier in params)) {
      throw new Error(`missing required parameter: ${identifier}`)
    }

    const replaceVal = params[identifier]
    path = path.replace(fullMatch, encodeURIComponent(String(replaceVal)))
  }
  return path
}

export type InvokeOptions = {
  query?: { [string]: Object },
  params?: { [string]: mixed },
} & RequestOptions

type GetAccessToken = () => Promise<string>

type Link = {
  rel: string,
  uri: string,
}

type OpenApiOperationSpec = Object

type OpenApiComponentSpec = Object

export type API = {
  apiFetch: (string, ?InvokeOptions) => Promise<Response>,
  invokeOperation: (string, ?InvokeOptions) => Promise<Response>,
  parseLinks: (Response) => {
    values: Link[],
    exists: (string) => boolean,
    follow: (string) => Promise<Response>,
  },
  getSpecForOperation: (string) => Promise<OpenApiOperationSpec>,
  resolveRef: (string) => Promise<OpenApiComponentSpec>,
}

export class ApiError extends Error {
  contextMessage: string

  req: Request

  res: Response

  constructor(message: string, req: Request, res: Response) {
    super()
    this.contextMessage = message
    this.req = req
    this.res = res
  }

  get message() {
    const { contextMessage = '', res: { statusText = 'unknown' } = {} } = this
    return `${contextMessage}: ${statusText}`
  }

  set message(val: string) {
    this.contextMessage = val
  }

  // eslint-disable-next-line no-use-before-define
  wrap(message: string): ApiError {
    const { contextMessage } = this
    this.contextMessage = `${message}: ${contextMessage}`
    return this
  }
}

const apiFactory = (baseUri: string, getAccessToken: GetAccessToken): API => {
  const apiFetch = async (
    endpoint: string,
    { query, params = {}, ...fetchOpts }: InvokeOptions = {},
  ): Promise<Response> => {
    const queryStr = query ? `?${formatQuery(query)}` : ''
    const host = endpoint.startsWith('http') ? '' : baseUri
    const parameterizedEndpoint = setParams(endpoint, params)
    const headers = new Headers(fetchOpts?.headers || {})
    const wafToken = await getWafToken()
    headers.set('x-aws-waf-token', wafToken)

    const req = new Request(`${host}${parameterizedEndpoint}${queryStr}`, {
      ...fetchOpts,
      headers,
    })

    const res = await fetch(req)

    if (res.status === 401 && res.redirected) {
      // some browsers (e.g., Safari) do not include the Authorization header in
      // the redirect, even when the redirect does not change domains.
      // this brute-force workaround retries the failed request.
      return apiFetch(res.url, fetchOpts)
    }

    if (!res.ok) {
      throw new ApiError(`api method failed: ${endpoint}`, req, res)
    }
    return res
  }

  const withToken = async (
    endpoint: string,
    options: ?InvokeOptions,
  ): Promise<Response> => {
    const { headers, ...fetchOpts } = options || {}
    const authedHeaders = new Headers(headers)
    const token = await getAccessToken()
    authedHeaders.set('Authorization', `Bearer ${token}`)

    return apiFetch(endpoint, {
      ...fetchOpts,
      headers: authedHeaders,
    })
  }

  const getStatus = async () => {
    const res = await apiFetch('/v1/status')
    return res.json()
  }

  const gettingStatus = getStatus()

  const getOperations = async () => {
    const { links = [] } = await gettingStatus
    return links
  }

  const gettingOperations = getOperations()

  const getOperation = async (name: string) => {
    const operations = await gettingOperations
    const { href, type: method = 'get' } =
      operations.find(({ rel }) => rel === name || rel === `${name}V1`) || {}
    if (!href) {
      throw new Error(`no endpoint for operation: "${name}"`)
    }
    return { href, method }
  }

  const getSpec = async () => {
    const headers = new Headers({ accept: 'application/json' })

    const res = await apiFetch('/spec', {
      headers,
    })
    return res.json()
  }

  const gettingSpec = getSpec()

  const invoke = async (operationName: string, options: ?InvokeOptions) => {
    const { href, method = 'get' } = await getOperation(operationName)
    if (!href) {
      throw new Error(`invalid operation: "${operationName}"`)
    }
    return withToken(href, {
      ...options,
      method: method.toUpperCase(),
    })
  }

  const links = (res: Response) => {
    const { headers = new Headers() } = res
    const { refs: values = [] } = headers.has('link')
      ? linkHeader.parse(headers.get('link'))
      : {}
    const exists = (name: string) =>
      (values || []).some(({ rel }) => rel === name)
    const follow = (name: string) => {
      const { uri: linkUri } =
        (values || []).find(({ rel }) => rel === name) || {}
      if (!linkUri) {
        throw new Error(
          `unable to follow link: link does not exist: "${linkUri}`,
        )
      }
      return withToken(linkUri)
    }

    return { values, exists, follow }
  }

  const getSpecForOperation = async (operationName: string) => {
    const gettingOperation = getOperation(operationName)
    const { paths = {} } = await gettingSpec
    const { href, method = 'get' } = await gettingOperation

    if (!(href in paths)) {
      throw new Error(
        `operation "${operationName}" not found in spec: unknown path "${href}"`,
      )
    }

    const path = paths[href] || {}
    if (!(method in path)) {
      throw new Error(
        `operation "${operationName}" not found in spec: unknown method "${method}"`,
      )
    }

    return path[method] || {}
  }

  const resolveRef = async (schema: Object) => {
    if (!('$ref' in schema)) {
      return schema
    }

    const { $ref: ref } = schema
    if (!ref.startsWith('#')) {
      throw new Error(
        `cannot resolve ref "${ref}": only local references are supported`,
      )
    }

    const [, ...attrs] = ref.split('/')
    const spec = await gettingSpec
    const value = attrs.reduce((curr, attr) => curr && curr[attr], spec)

    return resolveRef(value || {})
  }

  return {
    apiFetch: withToken,
    invokeOperation: invoke,
    parseLinks: links,
    getSpecForOperation,
    resolveRef,
  }
}

export default apiFactory
