// @flow
import React, { useEffect } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import GroupForm from './GroupForm'
import Panel from '../../../components/Panel'
import type { SkillGroup } from '../../skills/actions/loadSkillGroups'

type CreateGroupProps = {
  open: boolean,
  created: boolean,
  creating: boolean,
  createGroup: ({
    name: string,
    description: string,
    prefix: string | null,
    logo: File | null,
    banner: File | null,
    whiteLabelLogo: File | null,
    whiteLabelVideoURL: string | null,
    defaultSkill: number,
    isActive: Boolean,
    isFake: boolean,
  }) => void,
  close: () => void,
  onCreate: (string) => void,
  skillGroups?: SkillGroup[],
  loading?: boolean,
  loadSkillGroups?: ?() => void,
}

const CreateGroupPanel = ({
  open,
  createGroup,
  created,
  creating,
  close,
  onCreate,
  skillGroups,
  loadSkillGroups,
  loading = false,
}: CreateGroupProps) => {
  useEffect(() => {
    if (typeof loadSkillGroups === 'function') {
      loadSkillGroups()
    }
  }, [])

  useEffect(() => {
    if (created) {
      onCreate('Successfully saved the group')
      close()
    }
  }, [created])

  if (loading) {
    return <CircularProgress />
  }

  return (
    <Panel title="New group" open={open} onClose={close}>
      <GroupForm
        editable={!creating}
        onSubmit={createGroup}
        skillGroups={skillGroups}
      />
    </Panel>
  )
}

CreateGroupPanel.defaultProps = {
  loading: false,
  loadSkillGroups: undefined,
  skillGroups: [],
}
export default CreateGroupPanel
