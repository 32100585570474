// @flow
import { createTheme } from '@material-ui/core/styles'
import { green, grey, red } from '@material-ui/core/colors'

const rawTheme = createTheme({
  palette: {
    primary: {
      light: '#CCCCB8',
      main: '#33332E',
    },
    secondary: {
      light: '#CBF7CE',
      main: '#63bd6d',
    },
    warning: {
      main: '#ffc071',
      dark: '#ffb25e',
    },
    error: {
      xLight: red[50],
      main: red[500],
      dark: red[700],
    },
    success: {
      xLight: green[50],
      main: green[500],
      dark: green[700],
    },
  },
  typography: {
    fontFamily: "'Work Sans', sans-serif",
    fontSize: 14,
    fontWeightLight: 300, // Work Sans
    fontWeightRegular: 400, // Work Sans
    fontWeightMedium: 700, // Roboto Condensed
    fontFamilySecondary: "'Roboto Condensed', sans-serif",
  },
})

const fontHeader = {
  fontWeight: rawTheme.typography.fontWeightMedium,
  fontFamily: rawTheme.typography.fontFamilySecondary,
  textTransform: 'uppercase',
}

const theme = {
  ...rawTheme,
  palette: {
    ...rawTheme.palette,
    background: {
      ...rawTheme.palette.background,
      default: rawTheme.palette.common.white,
      placeholder: grey[200],
    },
  },
  typography: {
    ...rawTheme.typography,
    fontHeader,
    h1: {
      ...rawTheme.typography.h1,
      ...fontHeader,
      letterSpacing: 0,
    },
    h2: {
      ...rawTheme.typography.h2,
      ...fontHeader,
      fontSize: '3rem',
    },
    h3: {
      ...rawTheme.typography.h3,
      ...fontHeader,
      fontSize: '2rem',
    },
    h4: {
      ...rawTheme.typography.h4,
      ...fontHeader,
      fontSize: '1.2rem',
    },
    h5: {
      ...rawTheme.typography.h5,
      fontSize: '1.2rem',
      fontWeight: rawTheme.typography.fontWeightLight,
    },
    h6: {
      ...rawTheme.typography.h6,
      ...fontHeader,
      fontSize: '1.2rem',
    },
    subtitle1: {
      ...rawTheme.typography.subtitle1,
      fontSize: '1.2rem',
    },
    body1: {
      ...rawTheme.typography.body1,
    },
    body2: {
      ...rawTheme.typography.body2,
    },
  },
}

export default theme
