// @flow
import type { Warning } from './actions/loadData'
import type { Action } from '../../store'

export type State = {
  step: number,
  canStepNext: boolean,
  visible: boolean,
  data: {
    loaded: boolean,
    rows?: Object[],
    columns?: string[],
    warnings?: Warning[],
  },
  progress: {
    started: boolean,
    imported: Array<{ userId: number, rowId: number }>,
    failed: Array<{ id: number, data: Object, err: Error }>,
    completion: number,
  },
  groups: {
    loading: boolean,
    data?: Array<{ id: number, name: string }>,
    selected?: number[],
  },
  createAccounts: boolean,
}

const defaultState = {
  step: 0,
  canStepNext: false,
  visible: false,
  data: { loaded: false },
  progress: { started: false, imported: [], failed: [], completion: 0 },
  groups: { loading: false },
  createAccounts: false,
}

export default (state: State = defaultState, action: Action) => {
  switch (action.type) {
    case 'imports::LOAD_DATA': {
      const { rows, columns, warnings } = action
      return { ...state, data: { loaded: true, rows, columns, warnings } }
    }
    case 'imports::CLEAR_DATA': {
      return { ...state, data: { loaded: false } }
    }
    case 'imports::SET_STEP': {
      const { delta } = action
      const { step } = state
      return { ...state, step: step + delta }
    }
    case 'imports::RESET_STEP': {
      return { ...state, step: 0 }
    }
    case 'imports::SET_STEP_VALID': {
      const { valid } = action
      return { ...state, canStepNext: valid }
    }
    case 'imports::START_IMPORT': {
      const {
        progress: { ...progress },
      } = state
      return { ...state, progress: { ...progress, started: true } }
    }
    case 'imports::CREATED_USER': {
      const { rowId, userId } = action
      const { progress: { imported, ...progress } = {} } = state
      return {
        ...state,
        progress: { ...progress, imported: [...imported, { rowId, userId }] },
      }
    }
    case 'imports::USER_CREATION_FAILED': {
      const { id, data, err } = action
      const { progress: { failed, ...progress } = {} } = state
      return {
        ...state,
        progress: { ...progress, failed: [...failed, { id, data, err }] },
      }
    }
    case 'imports::DONE_IMPORT': {
      const { progress } = state
      return { ...state, progress: { ...progress, started: false } }
    }
    case 'imports::RESET_IMPORT': {
      return { ...state, progress: defaultState.progress }
    }
    case 'imports::LOADING_GROUPS': {
      const { loading } = action
      const { groups } = state
      return { ...state, groups: { ...groups, loading } }
    }
    case 'imports::LOADED_GROUPS': {
      const { data } = action
      const { groups } = state
      return { ...state, groups: { ...groups, data } }
    }
    case 'imports::SELECT_GROUPS': {
      const { ids } = action
      const { groups } = state
      return { ...state, groups: { ...groups, selected: ids } }
    }
    case 'imports::SET_CREATE_ACCOUNTS': {
      const { value } = action
      return { ...state, createAccounts: value }
    }
    case 'imports::SET_COMPLETION': {
      const { value } = action
      const { progress } = state
      return { ...state, progress: { ...progress, completion: value } }
    }
    case 'imports::SHOW_IMPORTER': {
      return { ...state, visible: true }
    }
    case 'imports::HIDE_IMPORTER': {
      return defaultState
    }
    default:
      return state
  }
}
