// @flow
import React, { type Node } from 'react'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Switch from '@material-ui/core/Switch'

type ToggleProps = {
  value: boolean,
  label: string,
  onChange: (boolean) => void,
  children: ?Node,
}

const Toggle = ({ value, label, onChange, children }: ToggleProps) => (
  <FormControl>
    <FormControlLabel
      control={
        <Switch checked={value} onChange={(e) => onChange(e.target.checked)} />
      }
      label={label}
    />
    <FormHelperText>{children}</FormHelperText>
  </FormControl>
)

export default Toggle
