// @flow
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import createAccount from '../actions/createAccount'
import type { State } from '../../../store'

const mapStateToProps = ({
  users: {
    loaded: { data: { externalId } = {} } = {},
    account: { creating = false, linking = false } = {},
  } = {},
}: State) => ({
  disabled:
    (typeof externalId === 'string' && externalId.length > 0) ||
    creating ||
    linking,
})

const mapDispatchToProps = (dispatch) => ({
  onClick: () => dispatch(createAccount()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Button)
