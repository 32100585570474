// @flow
import type {
  Dispatch,
  GetState,
  ThunkExtraArgument,
  ThunkAction,
} from '../../../store'
import { ApiError, type API } from '../../../api/api'
import load from '../../../actions/load'

type ResettingPassword = 'users::RESETTING_PASSWORD'
export type ResettingPasswordAction = {
  type: ResettingPassword,
  saving: boolean,
}

type ResetPassword = 'users::RESET_PASSWORD'
export type ResetPasswordAction = { type: ResetPassword }

const resetPassword = async (api: API, profileId: number): Promise<void> => {
  try {
    await api.invokeOperation('admin.resetPassword', {
      params: { profileId },
    })
  } catch (err) {
    if (err instanceof ApiError) {
      throw err.wrap('resetting password')
    }
    throw err
  }
}

export default (): ThunkAction =>
  (dispatch: Dispatch, getState: GetState, { api }: ThunkExtraArgument) => {
    const { users: { loaded: { id: userId } = {} } = {} } = getState()
    if (!userId) {
      throw new Error('cannot reset password: no user selected')
    }
    dispatch(
      load(
        () => resetPassword(api, userId),
        (saving: boolean) => ({ type: 'users::RESETTING_PASSWORD', saving }),
        () => ({ type: 'users::RESET_PASSWORD' }),
      ),
    )
  }
