// @flow
import type { Dispatch, GetState, ThunkExtraArgument } from '../../../store'
import { ApiError, type API } from '../../../api/api'

type StartImport = 'imports::START_IMPORT'
export type StartImportAction = { type: StartImport }

type CreatedUser = 'imports::CREATED_USER'
export type CreatedUserAction = {
  type: CreatedUser,
  userId: number,
  rowId: number,
}

type UserCreationFailed = 'imports::USER_CREATION_FAILED'
export type UserCreationFailedAction = {
  type: UserCreationFailed,
  id: number,
  data: Object,
  err: Error,
}

type DoneImport = 'imports::DONE_IMPORT'
export type DoneImportAction = { type: DoneImport }

type SetCompletion = 'imports::SET_COMPLETION'
export type SetCompletionAction = { type: SetCompletion, value: number }

const createUser = async (
  api: API,
  { bio, ...user }: Object,
): Promise<number> => {
  try {
    const res = await api.invokeOperation('admin.createProfile', {
      headers: new Headers({ 'content-type': 'application/json' }),
      body: JSON.stringify({ ...user, bio: bio ? btoa(bio) : undefined }),
    })
    const { id } = await res.json()
    return id
  } catch (err) {
    if (err instanceof ApiError) {
      throw err.wrap(`creating user: ${user.id}`)
    }
    throw err
  }
}

const addUsersToGroup = async (
  api: API,
  groupId: number,
  userIds: number[],
) => {
  try {
    await api.invokeOperation('admin.addGroupMembers', {
      headers: new Headers({ 'content-type': 'application/json' }),
      params: { groupId },
      body: JSON.stringify({ profileIds: userIds }),
    })
  } catch (err) {
    if (err instanceof ApiError) {
      throw err.wrap(`adding users to group ${groupId}`)
    }
    throw err
  }
}

const createAccount = async (api: API, userId: number) => {
  try {
    await api.invokeOperation('admin.createAccount', {
      params: { profileId: userId },
    })
  } catch (err) {
    if (err instanceof ApiError) {
      throw err.wrap(`creating account for user ${userId}`)
    }
    throw err
  }
}

const sleep = (millis: number) =>
  // eslint-disable-next-line no-promise-executor-return
  new Promise((resolve) => setTimeout(resolve, millis))

export default (
    pending: Object[],
    {
      batchSize = 5,
      cooldown = 1000,
    }: {
      batchSize?: number,
      cooldown?: number,
    } = {},
  ) =>
  async (
    dispatch: Dispatch,
    getState: GetState,
    { api }: ThunkExtraArgument,
  ) => {
    const { imports: { progress: { started = false } = {} } = {} } = getState()
    if (started) {
      throw new Error('cannot start a new import: one is already in progress')
    }

    dispatch({ type: 'imports::START_IMPORT' })

    for (let i = 0; i < pending.length; i += batchSize) {
      const batch = pending.slice(i, i + batchSize)
      // eslint-disable-next-line no-await-in-loop
      await Promise.all(
        batch.map(async ({ id: rowId, ...user }) => {
          try {
            const userId = await createUser(api, user)
            dispatch({ type: 'imports::CREATED_USER', userId, rowId })
          } catch (err) {
            dispatch({
              type: 'imports::USER_CREATION_FAILED',
              id: rowId,
              data: user,
              err,
            })
          } finally {
            dispatch({
              type: 'imports::SET_COMPLETION',
              value: (i / pending.length / 3) * 100,
            })
          }
        }),
      )
      // eslint-disable-next-line no-await-in-loop
      await sleep(cooldown)
    }

    const {
      imports: {
        progress: { imported = [] } = {},
        groups: { selected: groups = [] } = {},
      } = {},
    } = getState()
    const userIds = imported.map(({ userId }) => userId)

    for (let i = 0; i < groups.length; i += 1) {
      const groupId = groups[i]
      try {
        // eslint-disable-next-line no-await-in-loop
        await addUsersToGroup(api, groupId, userIds)
      } catch (err) {
        dispatch({
          type: 'imports::USER_CREATION_FAILED',
          id: groupId,
          data: { groupId },
          err,
        })
      } finally {
        dispatch({
          type: 'imports::SET_COMPLETION',
          value: (i / groups.length / 3) * 100 + 33,
        })
      }
      // eslint-disable-next-line no-await-in-loop
      await sleep(cooldown)
    }

    const { imports: { createAccounts } = {} } = getState()
    if (createAccounts) {
      for (let i = 0; i < userIds.length; i += batchSize) {
        const batch = userIds.slice(i, i + batchSize)
        // eslint-disable-next-line no-await-in-loop
        await Promise.all(
          batch.map(async (userId) => {
            try {
              await createAccount(api, userId)
            } catch (err) {
              dispatch({
                type: 'imports::USER_CREATION_FAILED',
                id: userId,
                data: { userId },
                err,
              })
            } finally {
              dispatch({
                type: 'imports::SET_COMPLETION',
                value: (i / pending.length / 3) * 100 + 66,
              })
            }
          }),
        )
        // eslint-disable-next-line no-await-in-loop
        await sleep(cooldown)
      }
    }

    dispatch({ type: 'imports::SET_COMPLETION', value: 100 })
    dispatch({ type: 'imports::DONE_IMPORT' })
  }

type ResetImport = 'imports::RESET_IMPORT'
export type ResetImportAction = { type: ResetImport }

export const resetImport = () => ({
  type: 'imports::RESET_IMPORT',
})
