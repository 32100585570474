// @flow
import type {
  Dispatch,
  GetState,
  ThunkExtraArgument,
  ThunkAction,
} from '../../../store'
import { ApiError, type API } from '../../../api/api'
import load from '../../../actions/load'

type CreatingAccount = 'users::CREATING_ACCOUNT'
export type CreatingAccountAction = { type: CreatingAccount, saving: boolean }

type CreatedAccount = 'users::CREATED_ACCOUNT'
export type CreatedAccountAction = { type: CreatedAccount, data: string }

type CreateAccountResult = { data: string }

const createAccount = async (
  api: API,
  profileId: number,
): Promise<CreateAccountResult> => {
  try {
    const account = await api.invokeOperation('admin.createAccount', {
      params: { profileId },
    })
    const { externalId } = await account.json()
    return { data: externalId }
  } catch (err) {
    if (err instanceof ApiError) {
      throw err.wrap('creating account')
    }
    throw err
  }
}

export default (): ThunkAction =>
  (dispatch: Dispatch, getState: GetState, { api }: ThunkExtraArgument) => {
    const { users: { loaded: { id: userId } = {} } = {} } = getState()
    if (!userId) {
      throw new Error('cannot create user account: no user selected')
    }
    dispatch(
      load(
        () => createAccount(api, userId),
        (saving: boolean) => ({ type: 'users::CREATING_ACCOUNT', saving }),
        ({ data }: CreateAccountResult) => ({
          type: 'users::CREATED_ACCOUNT',
          data,
        }),
      ),
    )
  }
