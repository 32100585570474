// @flow
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import resetPassword from '../actions/resetPassword'
import type { State } from '../../../store'

const mapStateToProps = ({
  users: {
    loaded: { data: { externalId } = {} } = {},
    account: { creating = false, linking = false, resetting = false } = {},
  } = {},
}: State) => ({
  disabled:
    typeof externalId !== 'string' ||
    externalId.length < 1 ||
    creating ||
    linking ||
    resetting,
})

const mapDispatchToProps = (dispatch) => ({
  onClick: () => dispatch(resetPassword()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Button)
