// @flow
import type { Action } from '../../store'

type Column = {
  title: string,
  field: string,
  defaultGroupOrder?: number,
  filtering?: boolean,
  type?: 'boolean' | 'numeric',
}

export type ReportsState = {
  loading: boolean,
  activeReport?: string,
  data?: string,
  availableReports: Array<{ id: string, title: string, columns: Column[] }>,
}

const defaultState: ReportsState = {
  loading: false,
  availableReports: [
    {
      id: 'mentor-ratios',
      title: 'Mentor/Mentee ratios',
      columns: [
        { title: 'Skill group', field: 'skillgroup_name' },
        { title: 'Group', field: 'group_name', defaultGroupOrder: 0 },
        {
          title: 'Num. mentors',
          field: 'num_mentors',
          filtering: false,
          type: 'numeric',
        },
        {
          title: 'Num. mentees',
          field: 'num_mentees',
          filtering: false,
          type: 'numeric',
        },
        { title: 'Needs mentors?', field: 'needs_mentors', type: 'boolean' },
      ],
    },
    {
      id: 'user-checklist',
      title: 'User checklist',
      columns: [
        { title: 'Name', field: 'name' },
        { title: 'Email', field: 'email' },
        { title: 'Groups', field: 'group' },
        { title: 'Set skills?', field: 'set_skills', type: 'boolean' },
        {
          title: 'Set availability?',
          field: 'set_availability',
          type: 'boolean',
        },
        {
          title: 'Scheduled conversation?',
          field: 'conversation_scheduled',
          type: 'boolean',
        },
        {
          title: 'Had conversation?',
          field: 'conversation_completed',
          type: 'boolean',
        },
        { title: 'Saved notes?', field: 'set_notes', type: 'boolean' },
        { title: 'Logged in?', field: 'logged_in', type: 'boolean' },
      ],
    },
  ],
}

export default (state: ReportsState = defaultState, action: Action) => {
  switch (action.type) {
    case 'reports::SET_ACTIVE': {
      const { id } = action
      return { ...state, activeReport: id }
    }
    case 'reports::LOADING_REPORT': {
      const { loading } = action
      return { ...state, loading }
    }
    case 'reports::LOADED_REPORT': {
      const { data } = action
      return { ...state, data }
    }
    default:
      return state
  }
}
