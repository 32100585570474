// @flow
import { connect } from 'react-redux'
import DownloadFile from '../components/DownloadFile'
import type { State } from '../../../store'

const mapStateToProps = ({
  reports: { loading, activeReport: activeReportId, data } = {},
}: State) => ({
  loading,
  file: data,
  type: 'text/csv',
  fileName: `${activeReportId}.csv`,
})

export default connect(mapStateToProps)(DownloadFile)
