// @flow
import { applyMiddleware } from 'redux'
import type {
  StoreEnhancer,
  StoreCreator,
  Store,
  Middleware,
  MiddlewareAPI,
} from 'redux'
import reduxThunk from 'redux-thunk'
import reduxPromise from 'redux-promise'
import createAuth0Client from '@auth0/auth0-spa-js'
import { withScope, captureException } from '@sentry/browser'
import apiFactory, { ApiError, type API } from './api/api'
import setUser from './auth/actions/setUser'
import setLoaded from './auth/actions/setLoaded'
import clearUser from './auth/actions/clearUser'
import pushNotification from './notifications/actions/pushNotification'
import usersMiddleware from './modules/users/middleware'
import type { State, Action, Dispatch } from './store'

type Config = {
  auth: {
    domain: string,
    clientId: string,
    audience: string,
  },
  api: {
    baseUri: string,
  },
}

export type EnhancedStore<S: State, A: Action, D: Dispatch> = Store<S, A, D> & {
  getAccessToken: () => Promise<string>,
  api: API,
}

const enhancerFactory = function authEnhancerFactory<
  S: State,
  A: Action,
  D: Dispatch,
>({ auth: authConfig, api: apiConfig }: Config): StoreEnhancer<S, A, D> {
  const auth0Pr = createAuth0Client({
    domain: authConfig.domain,
    client_id: authConfig.clientId,
    audience: authConfig.audience,
  })

  const getAccessToken = async (): Promise<string> => {
    const auth0 = await auth0Pr
    return auth0.getTokenSilently()
  }

  const refreshAuth = async (dispatch: D): Promise<void> => {
    const auth0 = await auth0Pr
    const isLoggedIn = await auth0.isAuthenticated()
    if (isLoggedIn) {
      const { nickname: username, picture: avatar } = await auth0.getUser()
      dispatch(setUser({ username, avatar }))
    } else {
      dispatch(clearUser())
    }
  }

  const api = apiFactory(apiConfig.baseUri, getAccessToken)

  const loginMiddleware: Middleware<S, A, D> =
    ({
      dispatch,
    }: // $FlowFixMe
    MiddlewareAPI<S, A, D>): ((D) => D) =>
    (next: D): D =>
    (action: A): A => {
      const { type } = action
      if (type === 'auth::LOG_IN') {
        auth0Pr.then(async (auth0) => {
          await auth0.loginWithPopup()
          dispatch(refreshAuth)
        })
      } else if (type === 'auth::LOG_OUT') {
        auth0Pr.then(async (auth0) => {
          await auth0.logout({
            returnTo: window.location.origin,
          })
          dispatch(refreshAuth)
        })
      }
      return next(action)
    }

  const errorMiddleware: Middleware<S, A, D> =
    ({
      dispatch,
      getState,
    }: // $FlowFixMe
    MiddlewareAPI<S, A, D>): ((D) => D) =>
    (next: D): D =>
    async (action: A): Promise<void> => {
      try {
        await next(action)
      } catch (err) {
        const state = getState() || {}
        const { auth: { username = 'unauthenticated', roles = [] } = {} } =
          state
        // notifiy sentry
        withScope((scope) => {
          let actionExtra
          if (typeof action === 'function') {
            actionExtra = `${action.name || 'unknown'} <<thunk>>`
          } else if (action && typeof action.then === 'function') {
            actionExtra = `<<promise>>`
          } else {
            actionExtra = action
          }

          scope.setUser({ username, roles })
          scope.setTag('context', 'redux_store')
          scope.setExtras({
            state,
            action: actionExtra,
          })

          if (err instanceof ApiError) {
            const { req, res } = err
            const authHeader = req.headers.get('authorization')
            const token =
              typeof authHeader === 'string'
                ? `${authHeader.substring(0, 13)}...`
                : ''
            scope.setExtras({
              req: {
                url: req.url,
                headers: Object.fromEntries(req.headers.entries()),
                token,
              },
              res: {
                code: res.status,
                headers: Object.fromEntries(res.headers.entries()),
              },
            })
          }

          captureException(err)
        })

        // show notification
        dispatch(
          pushNotification({
            title: 'Something went wrong',
            message: 'Looks like something went wrong, please try again.',
            type: 'error',
          }),
        )

        // eslint-disable-next-line no-console
        console.error(`error while dispatching action: ${err.message}`)
      }
    }

  return (createStore: StoreCreator<S, A, D>): StoreCreator<S, A, D> =>
    (...args: any[]): EnhancedStore<S, A, D> => {
      const store = applyMiddleware(
        errorMiddleware,
        loginMiddleware,
        usersMiddleware(),
        reduxThunk.withExtraArgument({ api }),
        reduxPromise,
      )(createStore)(...args)

      auth0Pr.then(() => store.dispatch(setLoaded(true)))
      refreshAuth(store.dispatch)

      return {
        ...store,
        getAccessToken,
        api,
      }
    }
}

export default enhancerFactory
