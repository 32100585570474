// @flow
import type {
  Dispatch,
  GetState,
  ThunkExtraArgument,
  ThunkAction,
} from '../../../store'
import { ApiError, type API } from '../../../api/api'
import load from '../../../actions/load'

export type Skill = {
  skillId: number,
  skillName: string,
}

export type SkillGroup = {
  id: number,
  skillGroupName: string,
  skills: Skill[],
}

type LoadingSkillGroups = 'skills::LOADING_SKILLGROUPS'
export type LoadingSkillGroupsAction = {
  type: LoadingSkillGroups,
  loading: boolean,
}

type LoadedSkillGroups = 'skills::LOADED_SKILLGROUPS'
export type LoadedSkillGroupsAction = {
  type: LoadedSkillGroups,
  data: SkillGroup[],
}

type LoadSkillGroupsResult = { data: SkillGroup[] }

const loadSkillGroups = async (
  api: API,
  fetch: () => Promise<Response>,
): Promise<LoadSkillGroupsResult> => {
  try {
    const skillGroups = await fetch()
    const data = await skillGroups.json()
    return { data }
  } catch (err) {
    if (err instanceof ApiError) {
      throw err.wrap('loading skillGroups')
    }
    throw err
  }
}

const loadingSkills = (loading: boolean) => ({
  type: 'skills::LOADING_SKILLGROUPS',
  loading,
})

const loadedSkills = ({ data }: LoadSkillGroupsResult) => ({
  type: 'skills::LOADED_SKILLGROUPS',
  data,
})

export default (): ThunkAction =>
  (dispatch: Dispatch, getState: GetState, { api }: ThunkExtraArgument) =>
    dispatch(
      load(
        () => {
          const { skillGroups = [] } = getState()
          if (skillGroups.data) {
            return Promise.resolve({ data: skillGroups.data })
          }
          return loadSkillGroups(api, () =>
            api.invokeOperation('listSkillGroupsIncludeSkillsV1'),
          )
        },
        loadingSkills,
        loadedSkills,
      ),
    )
