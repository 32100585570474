// @flow

type SetCreateAccounts = 'imports::SET_CREATE_ACCOUNTS'
export type SetCreateAccountsAction = {
  type: SetCreateAccounts,
  value: boolean,
}

export default (value: boolean) => ({
  type: 'imports::SET_CREATE_ACCOUNTS',
  value,
})
