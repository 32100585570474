// @flow
import { connect } from 'react-redux'
import Selector from '../components/Form/Selector'
import { selectGroups, loadGroups } from '../actions'
import { State } from '../../../store'

const mapStateToProps = ({
  imports: { groups: { loading, data = [], selected = [] } = {} } = {},
}: State) => ({
  label: 'Groups',
  values: data,
  selected,
  loading,
})

const mapDispatchToProps = {
  load: loadGroups,
  setSelected: selectGroups,
}

export default connect(mapStateToProps, mapDispatchToProps)(Selector)
