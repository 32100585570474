// @flow
import { connect } from 'react-redux'
import ViewReport from '../components/ViewReport'
import type { State } from '../../../store'

const mapStateToProps = ({
  reports: {
    loading,
    activeReport: activeReportId,
    availableReports,
    data,
  } = {},
}: State) => {
  const activeReport =
    availableReports.find(({ id }) => id === activeReportId) || {}
  return {
    loading,
    title: activeReport.title || '',
    data,
    columns: activeReport.columns || [],
  }
}

export default connect(mapStateToProps)(ViewReport)
