import 'isomorphic-fetch'
import { Config } from './Config'

/**
 * loadConfig loads the application from the specified URL.
 * The configuration is expected to be a JSON file with a structure that matches the Config type.
 */
const loadConfig = (
  fromUrl: string,
): {
  config: Promise<Config>
} => {
  const config = fetch(fromUrl).then(async (response) => {
    if (!response.ok) {
      throw new Error(`failed to load configuration: ${response.statusText}`)
    }
    const data = (await response.json()) as Config
    return data
  })
  return { config }
}

export default loadConfig
