// @flow
import { connect } from 'react-redux'
import joinGroup from '../actions/joinGroup'
import List from '../components/Groups/List'
import { loadAllGroups } from '../../groups/actions'
import type { State } from '../../../store'

const mapStateToProps = ({
  groups: { listing: { data = [], loading } = {} },
  users: { loaded: { data: { groups = [] } = {} } = {} } = {},
}: State) => {
  const alreadyJoinedGroups = new Set(groups.map(({ id }) => id))
  return {
    groups: data
      .filter(({ id }) => !alreadyJoinedGroups.has(id))
      .sort((group1, group2) => group1.name.localeCompare(group2.name)),
    loading,
  }
}

const mapDispatchToProps = {
  joinGroup,
  load: loadAllGroups,
}

export default connect(mapStateToProps, mapDispatchToProps)(List)
