// @flow

export type Notification = {
  title: string,
  type?: 'error' | 'warning' | 'info' | 'success',
  message?: ?string,
}

type PushNotification = 'notifications::PUSH_NOTIFICATION'
export type PushNotificationAction = {
  type: PushNotification,
  id: number,
  notification: Notification,
}

let nextId = 0
const getNextId = () => {
  nextId += 1
  return nextId
}

export default ({
  title,
  type = 'info',
  message,
}: Notification): PushNotificationAction => ({
  type: 'notifications::PUSH_NOTIFICATION',
  id: getNextId(),
  notification: { title, type, message },
})
