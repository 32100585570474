// @flow
import type {
  Dispatch,
  GetState,
  ThunkExtraArgument,
  ThunkAction,
} from '../../../store'
import { ApiError, type API } from '../../../api/api'
import load from '../../../actions/load'

export type UserData = {
  firstName: string,
  lastName: string,
  email: string,
  avatar: string,
  externalId?: ?string,
  groups?: ?Array<{ id: number, name: string }>,
}

export type User = UserData & {
  id: number,
}

type LoadingUser = 'users::LOADING_USER'
export type LoadingUserAction = { type: LoadingUser, loading: boolean }

type LoadedUser = 'users::LOADED_USER'
export type LoadedUserAction = {
  type: LoadedUser,
  data: User,
}

type LoadUserResult = { data: User }

const loadUser = async (
  api: API,
  profileId: number,
): Promise<LoadUserResult> => {
  try {
    const user = await api.invokeOperation('admin.getProfileV1', {
      params: { profileId: `${profileId}` },
    })
    const data = await user.json()
    return { data }
  } catch (err) {
    if (err instanceof ApiError) {
      throw err.wrap('loading user')
    }
    throw err
  }
}

const loadingUser = (loading: boolean) => ({
  type: 'users::LOADING_USER',
  loading,
})

const loadedUser = ({ data }: LoadUserResult) => ({
  type: 'users::LOADED_USER',
  data,
})

export default (userId: number): ThunkAction =>
  (dispatch: Dispatch, getState: GetState, { api }: ThunkExtraArgument) =>
    dispatch(load(() => loadUser(api, userId), loadingUser, loadedUser))
