// @flow
import React from 'react'
import ViewAllMembers from '../containers/ViewAllMembers'
import Panel from '../../../components/Panel'

type MembersPanelProps = {
  open: boolean,
  close: () => void,
}

const MembersPanel = ({ open, close }: MembersPanelProps) => (
  <Panel title="Members" open={open} onClose={close}>
    <ViewAllMembers />
  </Panel>
)

export default MembersPanel
