// @flow
import React, { type ComponentType, type Node } from 'react'
import Button from '@material-ui/core/Button'

type SchemaButtonProps = {
  schemaId: number,
  onClick: (number) => void,
  component?: ComponentType<{
    onClick: () => void,
  }>,
  children: ?Node,
}

const SchemaButton = ({ schemaId, onClick, component: Component = Button, children, ...rest }: SchemaButtonProps) => (
  /* eslint-disable-next-line react/jsx-props-no-spreading */
  <Component onClick={() => onClick(schemaId)} {...rest}>
    {children}
  </Component>
)

SchemaButton.defaultProps = {
  component: Button,
}

export default SchemaButton
