// @flow
import React, { type ComponentType, type Node } from 'react'
import Button from '@material-ui/core/Button'

type GroupButtonProps = {
  groupId: number,
  onClick: (number) => void,
  component?: ComponentType<{
    onClick: () => void,
  }>,
  children: ?Node,
}

const GroupButton = ({ groupId, onClick, component: Component = Button, children, ...rest }: GroupButtonProps) => (
  /* eslint-disable-next-line react/jsx-props-no-spreading */
  <Component onClick={() => onClick(groupId)} {...rest}>
    {children}
  </Component>
)

GroupButton.defaultProps = {
  component: Button,
}

export default GroupButton
