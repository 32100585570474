// @flow
import { connect } from 'react-redux'
import SchemaButton from '../components/SchemaButton'
import { showEditSchema } from '../actions'
import { State, Dispatch } from '../../../store'

const mapStateToProps = ({
  schemas: { edit: { visible } = {} } = {},
}: State) => ({
  disabled: visible,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onClick: (schemaId: number) => dispatch(showEditSchema(schemaId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SchemaButton)
