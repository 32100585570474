// @flow
import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import FormLabel from '@material-ui/core/FormLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import JSONInput from 'react-json-editor-ajrm'
import locale from 'react-json-editor-ajrm/locale/en'

type SchemaFormProps = {
  onSubmit: ({ type: string, definition: mixed }) => void,
  schema?: { type: string, definition: mixed },
  editable: boolean,
  classes: { [string]: string },
}

const styles = (theme) => ({
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  control: {},
})

const SchemaForm = ({
  onSubmit,
  schema: { type = '', definition = {} } = {},
  editable,
  classes,
}: SchemaFormProps) => {
  const [typeValue, setTypeValue] = useState(type)
  const [definitionValue, setDefinitionValue] = useState(definition)
  const [dirty, setDirty] = useState(false)
  const [valid, setValid] = useState(true)

  const save = (e) => {
    e.preventDefault()
    onSubmit({ type: typeValue, definition: definitionValue })
  }

  return (
    <form onSubmit={save} disabled={!editable}>
      <FormControl fullWidth>
        <InputLabel>Type</InputLabel>
        <Select
          value={typeValue}
          onChange={(e) => setDirty(true) && setTypeValue(e.target.value)}
          disabled={type !== ''}
        >
          <MenuItem value="notes">Notes</MenuItem>
          <MenuItem value="feedback">Feedback</MenuItem>
        </Select>
      </FormControl>
      <div className={classes.divider} />
      <FormControl fullWidth>
        <FormLabel>Definition</FormLabel>
        <JSONInput
          placeholder={definition}
          onChange={({ jsObject, error }) => {
            if (!jsObject || error) {
              setValid(false)
              return
            }
            setValid(true)
            setDirty(true)
            setDefinitionValue(jsObject)
          }}
          locale={locale}
          width="100%"
        />
        <FormHelperText>
          {'This block must be valid '}
          <a href="https://json-schema.org/">JSON Schema</a>.
        </FormHelperText>
      </FormControl>
      <Divider className={classes.divider} />
      <Button
        variant="contained"
        color="primary"
        type="submit"
        disabled={!valid || !editable || !dirty}
      >
        Save
      </Button>
    </form>
  )
}

SchemaForm.defaultProps = {
  schema: { type: '', definition: {} },
}

export default withStyles(styles)(SchemaForm)
