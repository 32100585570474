// @flow
import { connect } from 'react-redux'
import UsersTable from '../components/UsersTable'
import { loadUsers, navigateUsers } from '../actions'
import type { State, Dispatch } from '../../../store'

const mapStateToProps = ({
  users: { listing: { loading, data, links = [] } = {} } = {},
}: State) => {
  const hasFirst = links.some(({ rel }) => rel === 'first')
  const hasNext = links.some(({ rel }) => rel === 'next')
  const hasPrev = links.some(({ rel }) => rel === 'prev')
  const hasLast = links.some(({ rel }) => rel === 'last')
  return {
    data,
    loading,
    hasFirst: hasFirst && hasPrev,
    hasNext,
    hasPrev,
    hasLast: hasLast && hasNext,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadUsers: () => dispatch(loadUsers()),
  gotoFirst: () => dispatch(navigateUsers('first')),
  gotoNext: () => dispatch(navigateUsers('next')),
  gotoPrev: () => dispatch(navigateUsers('prev')),
  gotoLast: () => dispatch(navigateUsers('last')),
})

export default connect(mapStateToProps, mapDispatchToProps)(UsersTable)
