// @flow
import { connect } from 'react-redux'
import clearFilter from '../actions/clearFilter'
import loadUsers from '../actions/loadUsers'
import UsersFilter from '../components/UsersFilter'

const mapDispatchToProps = (dispatch) => ({
  applyFilter: () => dispatch(loadUsers()),
  clearFilter: () => dispatch(clearFilter()),
})

export default connect(null, mapDispatchToProps)(UsersFilter)
