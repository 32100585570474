// @flow
import React, { useEffect } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import EditSchemaButton from '../containers/EditSchemaButton'
import EditSchema from '../containers/EditSchema'
import Pagination from '../../../components/Table/Pagination'

type Schema = {
  id: number,
  type: string,
  active: boolean,
}

type SchemasTableProps = {
  loadSchemas: () => Promise<void>,
  data?: Schema[],
  loading?: boolean,
  hasNext?: boolean,
  hasPrev?: boolean,
  gotoNext: () => Promise<void>,
  gotoPrev: () => Promise<void>,
}

const SchemasTable = ({
  loadSchemas,
  data = [],
  loading = false,
  hasNext = false,
  hasPrev = false,
  gotoNext,
  gotoPrev,
}: SchemasTableProps) => {
  useEffect(() => {
    loadSchemas()
  }, [loadSchemas])

  if (loading) {
    return <CircularProgress />
  }

  if (!data || !Array.isArray(data) || data.length < 1) {
    return <p>No schemas exist.</p>
  }

  return (
    <>
      <EditSchema />
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>Active</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map(({ id, type, active }) => (
              <TableRow key={id}>
                <TableCell>{type}</TableCell>
                <TableCell>{active ? 'Yes' : 'No'}</TableCell>
                <TableCell>
                  <EditSchemaButton schemaId={id} component={IconButton}>
                    <EditIcon fontSize="small" />
                  </EditSchemaButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination hasNext={hasNext} hasPrev={hasPrev} gotoNext={gotoNext} gotoPrev={gotoPrev} />
    </>
  )
}

SchemasTable.defaultProps = {
  data: null,
  loading: false,
  hasNext: false,
  hasPrev: false,
}

export default SchemasTable
