// @flow
import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { Helmet } from 'react-helmet'
import IsAuthorized from './IsAuthorized'
import Message from './Message'
import CatchBoundary from './CatchBoundary'
import Reports from './Pages/Reports'
import Groups from './Pages/Groups'
import Schemas from './Pages/Schemas'
import Users from './Pages/Users'
import Profile from './Pages/Profile'
import AppNotification from '../containers/AppNotification'

type MainProps = {
  classes: { [string]: string },
}

const styles = (theme) => ({
  main: {
    minHeight: '84vh',
    padding: theme.spacing(3),
  },
})

const Main = ({ classes }: MainProps) => (
  <Container classes={{ root: classes.main }}>
    <CatchBoundary>
      <AppNotification />
      <Helmet defaultTitle="Lattus Admin" titleTemplate="%s | Lattus Admin" />
      <Switch>
        <Route exact path="/">
          <IsAuthorized>
            <Helmet>
              <title>Welcome</title>
            </Helmet>
            <Message
              title="Welcome!"
              body="This application administers the Lattus platform."
            />
          </IsAuthorized>
        </Route>
        <Route path="/reports">
          <IsAuthorized>
            <Helmet>
              <title>Reports</title>
            </Helmet>
            <Reports />
          </IsAuthorized>
        </Route>
        <Route path="/groups">
          <IsAuthorized>
            <Helmet>
              <title>Groups</title>
            </Helmet>
            <Groups />
          </IsAuthorized>
        </Route>
        <Route path="/schemas">
          <IsAuthorized>
            <Helmet>
              <title>Schemas</title>
            </Helmet>
            <Schemas />
          </IsAuthorized>
        </Route>
        <Route path="/users/:userId">
          <IsAuthorized>
            <Helmet>
              <title>User profile</title>
            </Helmet>
            <Profile />
          </IsAuthorized>
        </Route>
        <Route path="/users">
          <IsAuthorized>
            <Helmet>
              <title>Users</title>
            </Helmet>
            <Users />
          </IsAuthorized>
        </Route>
        <Route path="*">
          <Helmet>
            <title>Not found</title>
          </Helmet>
          <Message
            title="Not found"
            body="This is not the page you're looking for."
          />
        </Route>
      </Switch>
    </CatchBoundary>
  </Container>
)

export default withStyles(styles)(Main)
