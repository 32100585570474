// @flow
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import SelectReport from '../../modules/reports/containers/SelectReport'
import ViewActiveReport from '../../modules/reports/containers/ViewActiveReport'
import DownloadActiveReport from '../../modules/reports/containers/DownloadActiveReport'

const styles = (theme) => ({
  spacer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
})

const Reports = ({ classes }: { classes: { [string]: string } }) => (
  <>
    <Typography variant="h3">Reports</Typography>
    <div className={classes.spacer} />
    <SelectReport />
    <Divider light className={classes.spacer} />
    <DownloadActiveReport />
    <div className={classes.spacer} />
    <ViewActiveReport />
  </>
)

export default withStyles(styles)(Reports)
