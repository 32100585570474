// @flow
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import logIn from '../auth/actions/logIn'
import type { State } from '../store'

const mapStateToProps = ({ auth: { loggedIn } = {} }: State) => ({
  disabled: loggedIn,
})

const mapDispatchToProps = {
  onClick: logIn,
}

export default connect(mapStateToProps, mapDispatchToProps)(Button)
