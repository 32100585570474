// @flow
import { datadogRum } from '@datadog/browser-rum'
import type {
  Dispatch,
  GetState,
  ThunkAction,
  ThunkExtraArgument,
} from '../../store'
import { ApiError, type API } from '../../api/api'

type SetUser = 'auth::SET_USER'
export type SetUserAction = {
  type: SetUser,
  username: string,
  avatar?: string,
  roles: string[],
}

const getRoles = async (api: API) => {
  try {
    const profile = await api.invokeOperation('getCurrentProfile')
    const { id, email, roles = [] } = (await profile.json()) || {}
    datadogRum.setUser({
      id,
      email,
      roles,
    })
    return roles
  } catch (err) {
    if (err instanceof ApiError) {
      throw err.wrap('getting roles from user profile')
    }
    throw err
  }
}

export default ({
    username,
    avatar,
  }: {
    username: string,
    avatar: string,
  }): ThunkAction =>
  async (
    dispatch: Dispatch,
    getState: GetState,
    { api }: ThunkExtraArgument,
  ): Promise<void> => {
    const roles = await getRoles(api)
    dispatch({
      type: 'auth::SET_USER',
      username,
      avatar,
      roles,
    })
  }
