// @flow
import React, { Fragment } from 'react'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'

type FailuresProps = {
  failed: Array<{ id: number, data: Object, err: Error }>,
}

const Failures = ({ failed }: FailuresProps) => {
  if (failed.length < 1) {
    return null
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Data</TableCell>
          <TableCell>Error</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {failed.map(({ id, data = {}, err }) => (
          <TableRow key={id}>
            <TableCell>
              <dl>
                {data &&
                  Object.keys(data || {}).map((col) => (
                    <Fragment key={`${id}-${col}`}>
                      <dt>{col}</dt>
                      <dd>{JSON.stringify(data[col], null, 2)}</dd>
                    </Fragment>
                  ))}
                {!data && 'No data'}
              </dl>
            </TableCell>
            <TableCell>{err.message}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default Failures
