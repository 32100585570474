// @flow
import React from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import GroupIcon from '@material-ui/icons/Group'

type GroupsProps = {
  groups: string[],
}

const Groups = ({ groups }: GroupsProps) => {
  if (!Array.isArray(groups) || groups.length < 1) {
    return null
  }

  return (
    <List>
      {groups.map((name) => (
        <ListItem key={name}>
          <ListItemIcon>
            <GroupIcon />
          </ListItemIcon>
          <ListItemText primary={name} />
        </ListItem>
      ))}
    </List>
  )
}

export default Groups
