// @flow
import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import SaveIcon from '@material-ui/icons/Save'
import CancelIcon from '@material-ui/icons/Cancel'
import withStyles from '@material-ui/core/styles/withStyles'
import { green } from '@material-ui/core/colors'
import Grid from '@material-ui/core/Grid'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import FormControlLabel from '@material-ui/core/FormControlLabel'

type Profile = {
  bio: ?string,
  email: ?string,
  firstName: ?string,
  funFact: ?string,
  highSchool: ?string,
  homeTown: ?string,
  isActive: ?boolean,
  isFake: ?boolean,
  lastName: ?string,
  linkedIn: ?string,
  location: ?string,
  maxConversations: ?number,
  phoneNumber: ?string,
  showTour: ?boolean,
  title: ?string,
}

type ProfileFormProps = {
  onSubmit: (Profile) => void,
  profile?: Profile,
  editable: boolean,
}

const useStyles = makeStyles((theme) => ({
  // root: {
  //   display: 'flex',
  //   flexDirection: 'row',
  // },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(1),
  },
  fields: {
    // width: '100%',
    // '& > *': {
    //   margin: theme.spacing(1, 0),
    // },
    // '& > *:first-child': {
    //   marginTop: 0,
    // },
    // '& > *:last-child': {
    //   marginBottom: 0,
    // },
  },
  gridItem: {
    marginBottom: theme.spacing(1),
  },
}))

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
  // eslint-disable-next-line react/jsx-props-no-spreading
})((props) => <Checkbox color="default" {...props} />)

const validateEmail = (email: ?string): boolean =>
  typeof email === 'string' && email.length > 0 && email.includes('@')

const validateNonZeroInteger = (num: ?number): boolean =>
  typeof num === 'number' && !Number.isNaN(num) && num >= 0

const ProfileForm = ({
  onSubmit,
  profile = {},
  editable = true,
}: ProfileFormProps) => {
  const classes = useStyles()
  const [dirty, setDirty] = useState(false)
  const [locked, setLocked] = useState(true)
  const [firstName, setFirstName] = useState(profile.firstName || '')
  const [lastName, setLastName] = useState(profile.lastName || '')
  const [email, setEmail] = useState(profile.email || '')
  const formChanged = !locked && dirty
  const [validEmail, setValidEmail] = useState(
    !formChanged || validateEmail(email),
  )
  const [maxConversations, setMaxConversations] = useState(
    profile.maxConversations || 0,
  )
  const [validMaxConversations, setValidMaxConversations] = useState(
    !formChanged || validateNonZeroInteger(maxConversations),
  )
  const [bio, setBio] = useState(profile.bio || '')
  const [valid, setValid] = useState(validEmail && validMaxConversations)
  const [isActive, setIsActive] = useState(profile.isActive)
  const [isFake, setIsFake] = useState(profile.isFake)
  const [showTour, setShowTour] = useState(profile.showTour)
  const [funFact, setFunFact] = useState(profile.funFact || '')
  const [highSchool, setHighSchool] = useState(profile.highSchool || '')
  const [homeTown, setHomeTown] = useState(profile.homeTown || '')
  const [linkedIn, setLinkedIn] = useState(profile.linkedIn || '')
  const [location, setLocation] = useState(profile.location || '')
  const [phoneNumber, setPhoneNumber] = useState(profile.phoneNumber || '')
  const [title, setTitle] = useState(profile.title || '')

  useEffect(() => {
    const checks = [
      () => firstName !== (profile.firstName || ''),
      () => lastName !== (profile.lastName || ''),
      () => email !== (profile.email || ''),
      () => maxConversations !== (profile.maxConversations || 0),
      () => bio !== (profile.bio || ''),
      () => isFake !== profile.isFake,
      () => isActive !== profile.isActive,
      () => showTour !== profile.showTour,
      () => funFact !== (profile.funFact || ''),
      () => highSchool !== (profile.highSchool || ''),
      () => homeTown !== (profile.homeTown || ''),
      () => linkedIn !== (profile.linkedIn || ''),
      () => location !== (profile.location || ''),
      () => phoneNumber !== (profile.phoneNumber || ''),
      () => title !== (profile.title || ''),
    ]

    setDirty(checks.some((fn) => fn()))
  }, [
    firstName,
    lastName,
    email,
    maxConversations,
    bio,
    isActive,
    isFake,
    showTour,
    funFact,
    highSchool,
    homeTown,
    linkedIn,
    location,
    phoneNumber,
    title,
  ])

  useEffect(() => {
    setValidEmail(!formChanged || validateEmail(email))
  }, [formChanged, email])

  useEffect(() => {
    setValidMaxConversations(
      !formChanged || validateNonZeroInteger(maxConversations),
    )
  }, [formChanged, maxConversations])

  useEffect(() => {
    setValid(validEmail && validMaxConversations)
  }, [validEmail, validMaxConversations])

  const save = (e) => {
    e.preventDefault()
    onSubmit({
      firstName,
      lastName,
      email,
      maxConversations,
      bio,
      isActive,
      isFake,
      showTour,
      funFact,
      highSchool,
      homeTown,
      linkedIn,
      location,
      phoneNumber,
      title,
    })
    setLocked(true)
    setDirty(false)
  }

  const discard = () => {
    setLocked(true)
    setDirty(false)
    setFirstName(profile.firstName || '')
    setLastName(profile.lastName || '')
    setEmail(profile.email || '')
    setMaxConversations(profile.maxConversations || 0)
    setBio(profile.bio || '')
    setIsActive(profile.isActive)
    setIsFake(profile.isFake)
    setShowTour(profile.showTour)
    setFunFact(profile.funFact || '')
    setHighSchool(profile.highSchool || '')
    setHomeTown(profile.homeTown || '')
    setLinkedIn(profile.linkedIn || '')
    setLocation(profile.location || '')
    setPhoneNumber(profile.phoneNumber || '')
    setTitle(profile.title || '')
  }

  const formDisabled = !editable || !valid
  const fieldDisabled = locked || !editable
  const submitDisabled = formDisabled || !dirty

  return (
    <form onSubmit={save} disabled={formDisabled} className={classes.root}>
      <div className={classes.fields}>
        <Grid container spacing={1}>
          <Grid item xs={9}>
            <ListItem>
              <ListItemText primary="Profile:" />
            </ListItem>
          </Grid>
          <Grid item xs={3}>
            {locked && (
              <IconButton
                aria-label="edit"
                disabled={!locked}
                color="secondary"
                onClick={() => setLocked(false)}
              >
                <EditIcon />
              </IconButton>
            )}
            {!locked && (
              <>
                <IconButton
                  aria-label="cancel"
                  disabled={locked}
                  onClick={discard}
                >
                  <CancelIcon />
                </IconButton>
                <IconButton
                  aria-label="save"
                  disabled={locked || submitDisabled}
                  color="primary"
                  onClick={save}
                >
                  <SaveIcon />
                </IconButton>
              </>
            )}
          </Grid>

          <Grid item xs={6} className={classes.gridItem}>
            <TextField
              label="First name"
              value={firstName}
              disabled={fieldDisabled}
              variant="outlined"
              fullWidth
              onChange={(e) => setFirstName(e.target.value)}
            />
          </Grid>

          <Grid item xs={6} className={classes.gridItem}>
            <TextField
              label="Last name"
              value={lastName}
              disabled={fieldDisabled}
              variant="outlined"
              fullWidth
              onChange={(e) => setLastName(e.target.value)}
            />
          </Grid>

          <Grid item xs={12} className={classes.gridItem}>
            <TextField
              label="Email"
              type="email"
              value={email}
              disabled={fieldDisabled}
              variant="outlined"
              fullWidth
              required
              error={!validEmail}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>

          <Grid item xs={6} className={classes.gridItem}>
            <TextField
              type="tel"
              label="Phone number"
              value={phoneNumber}
              disabled={fieldDisabled}
              variant="outlined"
              fullWidth
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </Grid>

          <Grid item xs={6} className={classes.gridItem}>
            <TextField
              label="Title (CANNOT BE UPDATED BY SYSADMIN)"
              value={title}
              disabled={fieldDisabled}
              variant="outlined"
              fullWidth
              onChange={(e) => setTitle(e.target.value)}
            />
          </Grid>

          <Grid item xs={12} className={classes.gridItem}>
            <TextField
              type="url"
              label="linkedIn"
              value={linkedIn}
              disabled={fieldDisabled}
              variant="outlined"
              fullWidth
              onChange={(e) => setLinkedIn(e.target.value)}
            />
          </Grid>

          <Grid item xs={12} className={classes.gridItem}>
            <TextField
              label="Bio"
              multiline
              value={atob(bio)}
              disabled={fieldDisabled}
              variant="outlined"
              fullWidth
              onChange={(e) => setBio(btoa(e.target.value))}
            />
          </Grid>

          <Grid item xs={12} className={classes.gridItem}>
            <TextField
              label="Fun Fact"
              multiline
              value={atob(funFact)}
              disabled={fieldDisabled}
              variant="outlined"
              fullWidth
              onChange={(e) => setFunFact(btoa(e.target.value))}
            />
          </Grid>

          <Grid item xs={6} className={classes.gridItem}>
            <TextField
              label="High School"
              value={highSchool}
              disabled={fieldDisabled}
              variant="outlined"
              fullWidth
              onChange={(e) => setHighSchool(e.target.value)}
            />
          </Grid>

          <Grid item xs={6} className={classes.gridItem}>
            <TextField
              label="Home Town"
              value={homeTown}
              disabled={fieldDisabled}
              variant="outlined"
              fullWidth
              onChange={(e) => setHomeTown(e.target.value)}
            />
          </Grid>

          <Grid item xs={12} className={classes.gridItem}>
            <TextField
              label="Location (CANNOT BE UPDATED BY SYSADMIN)"
              value={location}
              disabled={fieldDisabled}
              variant="outlined"
              fullWidth
              onChange={(e) => setLocation(e.target.value)}
            />
          </Grid>

          <Grid item xs={12} className={classes.gridItem}>
            <ListItem>
              <ListItemText primary="User Settings:" />
            </ListItem>
          </Grid>

          <Grid item xs={4} className={classes.gridItem}>
            <TextField
              label="Max. number of conversations"
              type="number"
              min="0"
              value={maxConversations}
              disabled={fieldDisabled}
              variant="outlined"
              fullWidth
              error={!validMaxConversations}
              onChange={(e) =>
                setMaxConversations(Number.parseInt(e.target.value, 10))
              }
            />
          </Grid>
          <Grid item sm={12} className={classes.gridItem}>
            <FormControlLabel
              disabled={fieldDisabled}
              control={
                <GreenCheckbox
                  checked={showTour}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setShowTour(true)
                    } else {
                      setShowTour(false)
                    }
                  }}
                  name="showTour"
                  label="showTour"
                />
              }
              label="Show Tour"
            />
          </Grid>

          <Grid item xs={12} className={classes.gridItem}>
            <ListItem>
              <ListItemText primary="Global User Settings:" />
            </ListItem>
          </Grid>

          <Grid item xs={6} className={classes.gridItem}>
            <FormControlLabel
              disabled={fieldDisabled}
              control={
                <GreenCheckbox
                  checked={isActive}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setIsActive(true)
                    } else {
                      setIsActive(false)
                    }
                  }}
                  name="isActive"
                  label="isActive"
                />
              }
              label="Active User (disable emails/login)"
            />
          </Grid>

          <Grid item xs={6} className={classes.gridItem}>
            <FormControlLabel
              disabled={fieldDisabled}
              control={
                <GreenCheckbox
                  checked={isFake}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setIsFake(true)
                    } else {
                      setIsFake(false)
                    }
                  }}
                  name="isFake"
                />
              }
              label="Fake User"
            />
          </Grid>
        </Grid>
      </div>
    </form>
  )
}

ProfileForm.defaultProps = {
  profile: {},
}

export default ProfileForm
