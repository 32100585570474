// @flow
import React, { useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import { Link as RouterLink } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import Pagination from '../../../components/Table/Pagination'
import FilterGroupMembers from '../../users/containers/FilterGroupMembers'

type MembersTableProps = {
  loadMembers: () => Promise<void>,
  name?: string,
  data?: Array<{
    id: number,
    firstName: string,
    lastName: string,
    email: string,
    avatar: string,
  }> | null,
  loading?: boolean,
  hasNext?: boolean,
  hasPrev?: boolean,
  gotoNext: () => Promise<void>,
  gotoPrev: () => Promise<void>,
  classes: { [string]: string },
}

const styles = {
  user: {
    display: 'flex',
    alignItems: 'center',
  },
}

const MembersTable = ({
  loadMembers,
  name,
  data = [],
  loading = false,
  hasNext = false,
  hasPrev = false,
  gotoNext,
  gotoPrev,
  classes,
}: MembersTableProps) => {
  useEffect(() => {
    loadMembers()
  }, [loadMembers])

  if (loading) {
    return <CircularProgress />
  }

  return (
    <>
      <Typography variant="h3" align="right">
        {name}
      </Typography>
      <br />
      <FilterGroupMembers />
      {!data || !Array.isArray(data) || data.length < 1 ? (
        <p>No members.</p>
      ) : (
        <>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map(({ id, firstName, lastName, email, avatar }) => (
                  <TableRow key={id}>
                    <TableCell className={classes.user}>
                      <Avatar src={avatar} />
                      &nbsp;
                      {`${firstName} ${lastName}`}
                    </TableCell>
                    <TableCell>
                      <a href={`mailto:${email}`}>{email}</a>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        component={RouterLink}
                        to={`/users/${id}/groups`}
                      >
                        <AssignmentIndIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            hasNext={hasNext}
            hasPrev={hasPrev}
            gotoNext={gotoNext}
            gotoPrev={gotoPrev}
          />
        </>
      )}
    </>
  )
}

MembersTable.defaultProps = {
  name: null,
  data: null,
  loading: false,
  hasNext: false,
  hasPrev: false,
}

export default withStyles(styles)(MembersTable)
