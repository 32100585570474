// @flow
import { connect } from 'react-redux'
import Progress from '../components/Import/Progress'
import { startImport, setStepValid } from '../actions'
import type { State } from '../../../store'

const getCaption = (completion) => {
  if (completion >= 100) {
    return 'Done!'
  }
  if (completion >= 66) {
    return 'Creating Auth0 accounts...'
  }
  if (completion >= 33) {
    return 'Adding users to groups...'
  }
  if (completion > 0) {
    return 'Creating user profiles...'
  }
  return 'Starting...'
}

const mapStateToProps = ({
  imports: {
    progress: { imported, failed, completion } = {},
    data: { rows } = {},
  } = {},
}: State) => ({
  queue: rows.filter(({ id }) => !imported.some(({ rowId }) => id === rowId)),
  progress: completion,
  failed,
  caption: getCaption(completion),
})

const mapDispatchToProps = {
  startImport,
  isValid: setStepValid,
}

export default connect(mapStateToProps, mapDispatchToProps)(Progress)
