// @flow
import type { Dispatch, Dispatchable } from '../store'

const load = function loadData<T>(
  getData: () => Promise<T>,
  setLoading: boolean => Dispatchable,
  setData: T => Dispatchable,
) {
  return async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    try {
      const data = await getData()
      dispatch(setData(data))
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export default load
