// @flow

type ShowImporter = 'imports::SHOW_IMPORTER'
export type ShowImporterAction = { type: ShowImporter }

export const showImporter = () => ({
  type: 'imports::SHOW_IMPORTER',
})

type HideImporter = 'imports::HIDE_IMPORTER'
export type HideImporterAction = { type: HideImporter }

export const hideImporter = () => ({
  type: 'imports::HIDE_IMPORTER',
})
