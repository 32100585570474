// @flow
import type {
  Dispatch,
  GetState,
  ThunkExtraArgument,
  ThunkAction,
} from '../../../store'
import { ApiError, type API } from '../../../api/api'
import load from '../../../actions/load'

export type Member = {
  id: number,
  firstName: string,
  lastName: string,
  email: string,
  avatar: string,
}

export type Link = {
  rel: string,
  uri: string,
}

type LoadingMembers = 'groups::LOADING_MEMBERS'
export type LoadingMembersAction = { type: LoadingMembers, loading: boolean }

type LoadedMembers = 'groups::LOADED_MEMBERS'
export type LoadedMembersAction = {
  type: LoadedMembers,
  data: Member[],
  links: Link[],
}

type LoadMembersResult = { data: Member[], links: Link[] }

const loadMembers = async (
  api: API,
  fetch: () => Promise<Response>,
): Promise<LoadMembersResult> => {
  try {
    const members = await fetch()
    const data = await members.json()
    const { values: links } = api.parseLinks(members)
    return { data, links }
  } catch (err) {
    if (err instanceof ApiError) {
      throw err.wrap('loading members')
    }
    throw err
  }
}

export default (rel: ?string): ThunkAction =>
  (dispatch: Dispatch, getState: GetState, { api }: ThunkExtraArgument) =>
    dispatch(
      load(
        () => {
          if (!rel) {
            const {
              groups: {
                members: { group: { id: groupId } = {}, filter = {} } = {},
              } = {},
            } = getState()
            return loadMembers(api, () =>
              api.invokeOperation('admin.getProfilesV1', {
                query: { groupId, ...filter },
              }),
            )
          }

          const { groups: { members: { links = [] } = {} } = {} } = getState()
          const link = links.find(({ rel: linkRel }) => linkRel === rel)
          if (!link) {
            throw new Error(
              `unable to follow link: no matching link found: "${rel}`,
            )
          }
          return loadMembers(api, () => api.apiFetch(link.uri))
        },
        (loading: boolean) => ({ type: 'groups::LOADING_MEMBERS', loading }),
        ({ data, links }: LoadMembersResult) => ({
          type: 'groups::LOADED_MEMBERS',
          data,
          links,
        }),
      ),
    )
