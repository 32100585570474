// @flow
import type {
  Dispatch,
  GetState,
  ThunkExtraArgument,
  ThunkAction,
} from '../../../store'
import { ApiError, type API } from '../../../api/api'
import { loadUser } from './index'

const removeLattusAdmin = async (
  api: API,
  profileId: number,
): Promise<void> => {
  try {
    await api.invokeOperation('admin.removeAdmin', {
      params: { userId: profileId },
      headers: new Headers({ 'content-type': 'application/json' }),
    })
  } catch (err) {
    if (err instanceof ApiError) {
      throw err.wrap('remove lattus admin')
    }
    throw err
  }
}

export default (): ThunkAction =>
  (dispatch: Dispatch, getState: GetState, { api }: ThunkExtraArgument) => {
    const { users: { loaded: { id: userId } = {} } = {} } = getState()
    if (!userId) {
      throw new Error('cannot remove lattus admin permission: no user selected')
    }
    removeLattusAdmin(api, userId).then(() => dispatch(loadUser(userId)))
  }
