// @flow
import type {
  Dispatch,
  GetState,
  ThunkExtraArgument,
  ThunkAction,
} from '../../../store'
import { ApiError, type API } from '../../../api/api'
import load from '../../../actions/load'

type DeletingGroup = 'groups::DELETING_GROUP'
export type DeletingGroupAction = { type: DeletingGroup, deleting: boolean }

type DeletedGroup = 'groups::DELETED_GROUP'
export type DeletedGroupAction = { type: DeletedGroup, data: number }

type DeleteGroupResult = { data: number }

const deleteGroup = async (
  api: API,
  groupId: number,
): Promise<DeleteGroupResult> => {
  try {
    await api.invokeOperation('admin.deleteGroup', {
      params: { groupId },
    })
    return { data: groupId }
  } catch (err) {
    if (err instanceof ApiError) {
      throw err.wrap('deleting group')
    }
    throw err
  }
}

export default (groupId: number): ThunkAction =>
  (dispatch: Dispatch, getState: GetState, { api }: ThunkExtraArgument) => {
    dispatch(
      load(
        () => deleteGroup(api, groupId),
        (deleting: boolean) => ({ type: 'groups::DELETING_GROUP', deleting }),
        ({ data }: DeleteGroupResult) => ({
          type: 'groups::DELETED_GROUP',
          data,
        }),
      ),
    )
  }
