// @flow
import { connect } from 'react-redux'
import linkAccount from '../actions/linkAccount'
import AccountForm from '../components/Account/AccountForm'
import type { State } from '../../../store'

const mapStateToProps = ({
  users: {
    loaded: { data: { externalId } = {} } = {},
    account: { creating = false, linking = false } = {},
  } = {},
}: State) => ({
  externalId,
  editable: !creating && !linking,
})

const mapDispatchToProps = {
  linkAccount,
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountForm)
