import { useContext } from 'react'
import { Auth0Config, BaseUri, Config, NavLinks } from './Config'
import { Context } from './Provider'

/** useConfig provides the application configuration. */
const useConfig = (): Config => useContext(Context)

/** useAuth0 provides the Auth0 client configuration. */
export const useAuth0 = (): Auth0Config => {
  const { auth0 } = useConfig()
  return auth0
}

/** useBaseUri provides the base URI value for the specified subsystem/service. */
export const useBaseUri = (name: keyof BaseUri): string => {
  const { baseUri } = useConfig()
  return baseUri[name] ?? ''
}

/** useNavLinks provides the nav links value. */
export const useNavLinks = (): NavLinks => {
  const { links } = useConfig()
  return links ?? {}
}
export default useConfig
