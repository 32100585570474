// @flow
import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'

type DownloadFileProps = {
  loading: boolean,
  file: string,
  type: string,
  fileName: string,
}

type DownloadFileState = {
  url: ?Object,
}

class DownloadFile extends Component<DownloadFileProps, DownloadFileState> {
  constructor(props: DownloadFileProps) {
    super(props)
    this.state = { url: null }
  }

  componentDidMount() {
    this.generateUrl()
  }

  componentDidUpdate({ file: prevFile }: DownloadFileProps) {
    const { file } = this.props
    if (file !== prevFile) {
      this.revokeUrl()
      this.generateUrl()
    }
  }

  componentWillUnmount() {
    this.revokeUrl()
  }

  generateUrl() {
    const { file, type } = this.props
    if (!file || !type) {
      return
    }

    const data = new Blob([file], { type })
    const dataUrl = URL.createObjectURL(data)
    this.setState({
      url: dataUrl,
    })
  }

  revokeUrl() {
    const { url } = this.state
    if (url) {
      URL.revokeObjectURL(url)
      this.setState({ url: null })
    }
  }

  render() {
    const { url } = this.state
    const { loading, fileName, file } = this.props
    if (!file) {
      return null
    }

    const loaded = !loading && !!url

    return (
      <Button
        variant="contained"
        color="primary"
        disabled={!loaded}
        component="a"
        href={url}
        download={fileName}
        startIcon={<CloudDownloadIcon />}
      >
        {loaded && 'Download'}
        {!loaded && <CircularProgress />}
      </Button>
    )
  }
}

export default DownloadFile
