// @flow
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Copyright from './Copyright'

type SiteFooterProps = {
  classes: { [string]: string },
}

const styles = (theme) => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.primary.light,
  },
  container: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    display: 'flex',
  },
  iconsWrapper: {
    height: 120,
  },
  icons: {
    display: 'flex',
  },
  link: {
    ...theme.typography.h6,
    fontWeight: 400,
    color: 'inherit',
    textDecoration: 'none',
    '&:hover, &:focus': {
      textDecoration: 'underline',
    },
  },
})

const SiteFooter = ({ classes }: SiteFooterProps) => (
  <Typography component="footer" className={classes.root}>
    <Container className={classes.container}>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={4} md={3}>
          <Copyright />
        </Grid>
      </Grid>
    </Container>
  </Typography>
)

export default withStyles(styles)(SiteFooter)
