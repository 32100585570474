// @flow
import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'

type NameFilterProps = {
  value?: string,
  applyFilter: (string) => void,
}

const NameFilter = ({
  value: initialValue = '',
  applyFilter,
}: NameFilterProps) => {
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    if (value !== initialValue) {
      applyFilter(value)
    }
  }, [value])

  useEffect(() => {
    if (initialValue !== value) {
      setValue(initialValue)
    }
  }, [initialValue])

  return (
    <TextField
      label="Name"
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  )
}

NameFilter.defaultProps = {
  value: '',
}

export default NameFilter
