// @flow
import { connect } from 'react-redux'
import leaveGroup from '../actions/leaveGroup'
import List from '../components/Groups/List'
import type { State } from '../../../store'

const mapStateToProps = ({
  users: { loaded: { data: { groups = [] } = {} } = {} } = {},
}: State) => ({
  groups: groups.sort((group1, group2) =>
    group1.name.localeCompare(group2.name),
  ),
})

const mapDispatchToProps = {
  leaveGroup,
}

export default connect(mapStateToProps, mapDispatchToProps)(List)
