// @flow
import React, { useEffect } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import MuiList from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import GroupIcon from '@material-ui/icons/Group'
import GroupAddIcon from '@material-ui/icons/GroupAdd'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'

type Group = {
  id: number,
  name: string,
}

type ListProps = {
  groups: Group[],
  joinGroup?: ?(number => void),
  leaveGroup?: ?(number => void),
  load?: ?(() => void),
  loading?: boolean,
}

const List = ({ groups, joinGroup, leaveGroup, load, loading = false }: ListProps) => {
  useEffect(() => {
    if (typeof load === 'function') {
      load()
    }
  }, [])

  if (loading) {
    return <CircularProgress />
  }

  if (groups.length < 1) {
    return <Typography>No groups.</Typography>
  }

  const showJoin = typeof joinGroup === 'function'
  const showLeave = typeof leaveGroup === 'function'
  return (
    <MuiList>
      {groups.map(({ id, name }) => (
        <ListItem key={id}>
          <ListItemIcon>
            <GroupIcon />
          </ListItemIcon>
          <ListItemText primary={name} />
          {showJoin && (
            <ListItemSecondaryAction>
              <IconButton color="secondary" edge="end" aria-label="join" onClick={() => typeof joinGroup === 'function' && joinGroup(id)}>
                <GroupAddIcon />
              </IconButton>
            </ListItemSecondaryAction>
          )}
          {showLeave && (
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="leave" onClick={() => typeof leaveGroup === 'function' && leaveGroup(id)}>
                <RemoveCircleIcon />
              </IconButton>
            </ListItemSecondaryAction>
          )}
        </ListItem>
      ))}
    </MuiList>
  )
}

List.defaultProps = {
  joinGroup: undefined,
  leaveGroup: undefined,
  load: undefined,
  loading: false,
}

export default List
