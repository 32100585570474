// @flow
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  type Node,
  type Context,
} from 'react'
import type { API, InvokeOptions } from '../api/api'

const apiContext: Context<?API> = createContext(null)

type ParseData<T> = (Response) => Promise<T>

const parseJson = (res) => res.json()

const useApiHook = (
  operationName: string,
  baseOptions: ?InvokeOptions,
  parseData: ParseData<*> = parseJson,
) => {
  const context = useContext(apiContext)
  if (!context) {
    throw new Error('hook is not within a valid context')
  }

  const { invokeOperation, parseLinks } = context

  const [fetch, setFetch] = useState(null)
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const [links, setLinks] = useState(null)
  const [fetching, setFetching] = useState(null)

  useEffect(() => {
    if (typeof fetch !== 'function') return
    if (loading) return

    setFetching(fetch())
    setFetch(null)
  }, [fetch, loading])

  useEffect(() => {
    if (!fetching) return
    fetching
      .then((res) => {
        setLinks(parseLinks(res))
        setData(parseData(res))
        setSuccess(true)
      })
      .catch(() => {
        setSuccess(false)
        setError(null)
      })
      .then(() => {
        setFetch(null)
        setLoading(false)
      })
  }, [fetching])

  return {
    data,
    loading,
    error,
    success,
    invoke: (options: ?InvokeOptions) => {
      if (loading) {
        throw new Error('cannot invoke operation: data already loading')
      }
      setFetch((): Promise<Response> =>
        invokeOperation(operationName, { ...baseOptions, ...options }),
      )
    },
    hasLink: (link: string) => links && links.exists(link),
    followLink: (link: string) => {
      if (loading) {
        throw new Error('cannot follow link: data already loading')
      }
      if (!links) {
        throw new Error('cannot follow link: no links available')
      }
      setFetch((): Promise<Response> => links.follow(link))
    },
  }
}

type ApiProviderProps = {
  api: API,
  children: ?Node,
}

const ApiProvider = ({ api, children }: ApiProviderProps) => (
  <apiContext.Provider value={api}>{children}</apiContext.Provider>
)

export { useApiHook as useApi, ApiProvider }
