// @flow
import { connect } from 'react-redux'
import SchemasTable from '../components/SchemasTable'
import { loadSchemas, navigateSchema } from '../actions'
import type { State, Dispatch } from '../../../store'

const mapStateToProps = ({
  schemas: { listing: { loading, data, links = [] } = {} } = {},
}: State) => ({
  data,
  loading,
  hasNext: links.some(({ rel }) => rel === 'next'),
  hasPrev: links.some(({ rel }) => rel === 'prev'),
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadSchemas: () => dispatch(loadSchemas({ status: 'active' })),
  gotoNext: () => dispatch(navigateSchema('next')),
  gotoPrev: () => dispatch(navigateSchema('prev')),
})

export default connect(mapStateToProps, mapDispatchToProps)(SchemasTable)
