// @flow
import { connect } from 'react-redux'
import Switch from '../components/Switch'
import type { State } from '../store'

const mapStateToProps = ({
  auth: { loggedIn, loaded, roles = [], rolesLoaded } = {},
}: State) => ({
  when: loggedIn && roles.includes('admin'),
  loaded: loaded && rolesLoaded,
})

export default connect(mapStateToProps)(Switch)
