// @flow
import { createElement } from 'react'
import { connect } from 'react-redux'
import Avatar from '@material-ui/core/Avatar'
import type { State } from '../store'

const mapStateToProps = ({ auth: { avatar, username } = {} }: State) => ({
  alt: username,
  src: avatar,
})

export default connect(
  mapStateToProps,
  null,
)(({ alt, src }) => createElement(Avatar, { alt, src }))
