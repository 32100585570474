// @flow
import { connect } from 'react-redux'
import deleteUser from '../actions/deleteUser'
import DeleteButton from '../components/Profile/DeleteButton'
import type { State } from '../../../store'

const mapStateToProps = ({
  users: { loaded: { deleting = false, data: { externalId } = {} } = {} },
}: State) => ({
  disabled: deleting,
  hasAccount: typeof externalId === 'string' && externalId.length > 0,
})

const mapDispatchToProps = {
  deleteProfile: deleteUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteButton)
