// @flow
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import SearchIcon from '@material-ui/icons/Search'
import UserEmailFilter from '../containers/UserEmailFilter'
import UserNameFilter from '../containers/UserNameFilter'
import UserGroupFilter from '../containers/UserGroupFilter'

type UsersFilterProps = {
  applyFilter: () => void,
  clearFilter: () => void,
  filterByGroups: boolean,
}

const useStyles = makeStyles((theme) => ({
  panel: {
    padding: theme.spacing(2),
  },
  header: {
    display: 'flex',
  },
  controls: {
    display: 'flex',
    flexDirection: 'row',
  },
  control: {
    flexGrow: 1,
    maxWidth: '50%',
    minWidth: '33%',
    padding: theme.spacing(0, 1),
    '&:first-child': {
      paddingLeft: 0,
    },
    '&:last-child': {
      paddingRight: 0,
    },
    '& > *': {
      width: '100%',
    },
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginTop: theme.spacing(2),
  },
}))

const UsersFilter = ({
  applyFilter,
  clearFilter,
  filterByGroups = true,
}: UsersFilterProps) => {
  const classes = useStyles()
  const submit = () => applyFilter()
  return (
    <Paper
      component="form"
      onSubmit={submit}
      className={classes.panel}
      variant="outlined"
    >
      <div className={classes.header}>
        <SearchIcon />
        <Typography variant="h4">Filter</Typography>
      </div>
      <div className={classes.controls}>
        <div className={classes.control}>
          <UserNameFilter />
        </div>
        <div className={classes.control}>
          <UserEmailFilter />
        </div>
        {filterByGroups && (
          <div className={classes.control}>
            <UserGroupFilter />
          </div>
        )}
      </div>
      <div className={classes.buttons}>
        <Button onClick={() => clearFilter()} variant="text">
          Reset
        </Button>
      </div>
    </Paper>
  )
}

export default UsersFilter
