// @flow
import type {
  Dispatch,
  GetState,
  ThunkExtraArgument,
  ThunkAction,
} from '../../../store'
import { ApiError, type API } from '../../../api/api'
import load from '../../../actions/load'

export type User = {
  id: number,
  firstName: string,
  lastName: string,
  email: string,
  avatar: string,
  externalId?: ?string,
}

export type Link = {
  rel: string,
  uri: string,
}

type LoadingUsers = 'users::LOADING_USERS'
export type LoadingUsersAction = { type: LoadingUsers, loading: boolean }

type LoadedUsers = 'users::LOADED_USERS'
export type LoadedUsersAction = {
  type: LoadedUsers,
  data: User[],
  links: Link[],
}

type LoadUsersResult = { data: User[], links: Link[] }

type Filter = {
  groupId?: number,
  email?: string,
  name?: string,
}

const loadUsers = async (
  api: API,
  query: ?Filter = {},
): Promise<LoadUsersResult> => {
  try {
    const users = await api.invokeOperation('admin.getProfilesV1', {
      query: query || {},
    })
    const data = await users.json()
    const { values: links } = api.parseLinks(users)
    return { data, links }
  } catch (err) {
    if (err instanceof ApiError) {
      throw err.wrap('loading users')
    }
    throw err
  }
}

const loadingUsers = (loading: boolean) => ({
  type: 'users::LOADING_USERS',
  loading,
})

const loadedUsers = ({ data, links }: LoadUsersResult) => ({
  type: 'users::LOADED_USERS',
  data,
  links,
})

export default (): ThunkAction =>
  (dispatch: Dispatch, getState: GetState, { api }: ThunkExtraArgument) => {
    const { users: { listing: { filter = {} } = {} } = {} } = getState()
    dispatch(load(() => loadUsers(api, filter), loadingUsers, loadedUsers))
  }

const loadLink = async (api: API, { uri }: Link) => {
  try {
    const users = await api.apiFetch(uri)
    const data = await users.json()
    const { values: nextLinks } = api.parseLinks(users)
    return { data, links: nextLinks }
  } catch (err) {
    if (err instanceof ApiError) {
      throw err.wrap('following linked resource')
    }
    throw err
  }
}

export const follow =
  (rel: string): ThunkAction =>
  (dispatch: Dispatch, getState: GetState, { api }: ThunkExtraArgument) => {
    const { users: { listing: { links = [] } = {} } = {} } = getState()
    const link = links.find(({ rel: linkRel }) => linkRel === rel)
    if (!link) {
      throw new Error(`unable to follow link: no matching link found: "${rel}`)
    }
    dispatch(load(() => loadLink(api, link), loadingUsers, loadedUsers))
  }
