// @flow
import type { UserData } from './loadUser'
import type {
  Dispatch,
  GetState,
  ThunkExtraArgument,
  ThunkAction,
} from '../../../store'
import { ApiError, type API } from '../../../api/api'
import load from '../../../actions/load'

type EditingUser = 'users::EDITING_USER'
export type EditingUserAction = { type: EditingUser, saving: boolean }

type EditedUser = 'users::EDITED_USER'
export type EditedUserAction = { type: EditedUser, data: UserData }

type EditUserResult = { data: UserData }

const getExpectedBody = async (api: API, operationName: string) => {
  const {
    requestBody: { content: { 'application/json': { schema } = {} } = {} } = {},
  } = (await api.getSpecForOperation(operationName)) || {}
  return api.resolveRef(schema)
}

const filterData = ({ properties = {} }: Object, data: Object) =>
  Object.fromEntries(
    Object.entries(data)
      .map(([attr, val]) => {
        if (attr in properties) {
          const { readOnly = false } = properties[attr]
          if (readOnly) {
            return null
          }
          return [attr, val]
        }
        return null
      })
      .filter(Boolean),
  )

const editUser = async (
  api: API,
  profileId: number,
  data: UserData,
): Promise<EditUserResult> => {
  const operationName = 'admin.updateProfile'
  const schema = await getExpectedBody(api, operationName)
  const submission = filterData(schema, data)

  try {
    await api.invokeOperation(operationName, {
      params: { profileId },
      headers: new Headers({ 'content-type': 'application/json' }),
      body: JSON.stringify(submission),
    })
    return { data }
  } catch (err) {
    if (err instanceof ApiError) {
      throw err.wrap('editing user')
    }
    throw err
  }
}

export default (userData: UserData): ThunkAction =>
  (dispatch: Dispatch, getState: GetState, { api }: ThunkExtraArgument) => {
    const { users: { loaded: { id: userId, data: existingData } = {} } = {} } =
      getState()
    if (!userId) {
      throw new Error('cannot edit user: no user selected')
    }
    dispatch(
      load(
        () => editUser(api, userId, { ...existingData, ...userData }),
        (saving: boolean) => ({ type: 'users::EDITING_USER', saving }),
        ({ data }: EditUserResult) => ({ type: 'users::EDITED_USER', data }),
      ),
    )
  }
