import { useReducer, Dispatch } from 'react'
import { Config } from './Config'
import { LoadStatus } from './LoadStatus'

type LoadingState = { status: LoadStatus.Loading }

type SuccessState = { status: LoadStatus.Success; config: Config }

type ErrorState = { status: LoadStatus.Error; err: Error }

type State = LoadingState | SuccessState | ErrorState

type ActionLoadConfig = { type: 'LOADING' }

export const loading = (): ActionLoadConfig => ({ type: 'LOADING' })

type ActionLoadedConfig = { type: 'LOADED'; payload: Config }

export const loadedConfig = (payload: Config): ActionLoadedConfig => ({
  type: 'LOADED',
  payload,
})

type ActionError = { type: 'ERROR'; payload: Error }

export const error = (payload: Error): ActionError => ({
  type: 'ERROR',
  payload,
})

type Action = ActionLoadConfig | ActionLoadedConfig | ActionError

const reducer = (state: State, action: Action): State => {
  const { type } = action
  switch (type) {
    case 'LOADED': {
      const { payload: config } = action
      return { status: LoadStatus.Success, config }
    }
    case 'ERROR': {
      const { payload: err } = action
      return { status: LoadStatus.Error, err }
    }
    default: {
      return { status: LoadStatus.Loading }
    }
  }
}

const initialState: State = Object.freeze({
  status: LoadStatus.Loading,
})

/** useConfigState tracks loading an asyncronous configuration file. */
const useConfigState = (): [State, Dispatch<Action>] =>
  useReducer(reducer, initialState)

export default useConfigState
