// @flow
import type { Group } from './loadGroups'
import type {
  Dispatch,
  GetState,
  ThunkExtraArgument,
  ThunkAction,
} from '../../../store'
import { ApiError, type API } from '../../../api/api'
import load from '../../../actions/load'

type CreatingGroup = 'groups::CREATING_GROUP'
export type CreatingGroupAction = { type: CreatingGroup, saving: boolean }

type CreatedGroup = 'groups::CREATED_GROUP'
export type CreatedGroupAction = { type: CreatedGroup, data: Group }

type CreateGroupResult = { data: Group }

const createGroup = async (
  api: API,
  {
    name,
    description,
    prefix,
    logo,
    banner,
    whiteLabelLogo,
    whiteLabelVideoURL,
    defaultSkill,
    recodingValue,
    isMailingValue,
    isActive,
    isFake,
  }: {
    name: string,
    description: string,
    prefix: string | null,
    logo: File | null,
    banner: File | null,
    whiteLabelLogo: File | null,
    whiteLabelVideoURL: string | null,
    defaultSkill: any | null,
    recodingValue: boolean,
    isMailingValue: boolean,
    isActive: boolean,
    isFake: boolean,
  },
): Promise<CreateGroupResult> => {
  try {
    const createdGroup = await api.invokeOperation('admin.createGroup', {
      headers: new Headers({ 'content-type': 'application/json ' }),
      body: JSON.stringify({
        name,
        description,
        prefix,
        whiteLabelVideoURL,
        defaultSkill,
        recodingValue,
        isMailingValue,
        isActive,
        isFake,
      }),
    })
    const data = await createdGroup.json()

    // call update logo and banner
    let formData = new FormData()
    let updateGroupLogo = {}
    let updateGroupBanner = {}
    let updateGroupWhiteLabelLogo = {}

    if (logo) {
      formData.append('file', logo, logo.name)
      updateGroupLogo = await api.invokeOperation('admin.updateGroupLogo', {
        headers: new Headers(),
        params: { groupId: data.id },
        body: formData,
      })
    }
    if (banner) {
      formData = new FormData()
      formData.append('file', banner, banner.name)
      updateGroupBanner = await api.invokeOperation('admin.updateGroupImage', {
        headers: new Headers(),
        params: { groupId: data.id },
        body: formData,
      })
    }
    if (whiteLabelLogo) {
      formData = new FormData()
      formData.append('file', whiteLabelLogo, whiteLabelLogo.name)
      updateGroupWhiteLabelLogo = await api.invokeOperation(
        'admin.updateGroupWhiteLabelLogo',
        {
          headers: new Headers(),
          params: { groupId: data.id },
          body: formData,
        },
      )
    }
    return {
      data: {
        ...data,
        ...updateGroupBanner,
        ...updateGroupLogo,
        ...updateGroupWhiteLabelLogo,
      },
    }
  } catch (err) {
    if (err instanceof ApiError) {
      throw err.wrap('creating group')
    }
    throw err
  }
}

export default ({
    name,
    description,
    prefix,
    logo,
    banner,
    whiteLabelLogo,
    whiteLabelVideoURL,
    defaultSkill,
    recodingValue,
    isMailingValue,
    isActive,
    isFake,
  }: {
    name: string,
    description: string,
    prefix: string | null,
    logo: File | null,
    banner: File | null,
    whiteLabelLogo: File | null,
    whiteLabelVideoURL: string | null,
    defaultSkill: any | null,
    recodingValue: boolean,
    isMailingValue: boolean,
    isActive: boolean,
    isFake: boolean,
  }): ThunkAction =>
  (dispatch: Dispatch, getState: GetState, { api }: ThunkExtraArgument) =>
    dispatch(
      load(
        () =>
          createGroup(api, {
            name,
            description,
            prefix,
            logo,
            banner,
            whiteLabelLogo,
            whiteLabelVideoURL,
            defaultSkill,
            recodingValue,
            isMailingValue,
            isActive,
            isFake,
          }),
        (saving: boolean) => ({ type: 'groups::CREATING_GROUP', saving }),
        ({ data }: CreateGroupResult) => ({
          type: 'groups::CREATED_GROUP',
          data,
        }),
      ),
    )
