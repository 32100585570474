// @flow
import React, { useEffect } from 'react'
import SchemaForm from './SchemaForm'
import Panel from '../../../components/Panel'

type EditSchemaPanelProps = {
  open: boolean,
  updated: boolean,
  updating: boolean,
  updateSchema: ({ name: string }) => void,
  schema: { type: string, definition: mixed },
  close: () => void,
  onUpdate: (string) => void,
}

const EditSchemaPanel = ({ open, updated, updating, updateSchema, schema, close, onUpdate }: EditSchemaPanelProps) => {
  useEffect(() => {
    if (updated) {
      onUpdate('Successfully updated the schema')
      close()
    }
  }, [updated])

  return (
    <Panel title="Edit schema" open={open} onClose={close}>
      <SchemaForm onSubmit={updateSchema} schema={schema} editable={!updating} />
    </Panel>
  )
}

export default EditSchemaPanel
