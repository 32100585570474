// @flow
import { connect } from 'react-redux'
import Switch from '../components/Switch'
import type { State } from '../store'

const mapStateToProps = ({ auth: { loggedIn, loaded } = {} }: State) => ({
  when: loggedIn,
  loaded,
})

export default connect(mapStateToProps)(Switch)
