// @flow
import React, { useEffect } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import MuiList from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Typography from '@material-ui/core/Typography'
import GroupIcon from '@material-ui/icons/Group'
import withStyles from '@material-ui/core/styles/withStyles'
import { green } from '@material-ui/core/colors'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'

type Group = {
  id: number,
  name: string,
  isAdmin: boolean,
}

type ListProps = {
  groups: Group[],
  addGroupAdmin?: ?(number) => void,
  removeGroupAdmin?: ?(number) => void,
  load?: ?() => void,
  loading?: boolean,
}

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
  // eslint-disable-next-line react/jsx-props-no-spreading
})((props) => <Checkbox color="default" {...props} />)

const Permission = ({
  groups,
  addGroupAdmin,
  removeGroupAdmin,
  load,
  loading = false,
}: ListProps) => {
  useEffect(() => {
    if (typeof load === 'function') {
      load()
    }
  }, [])

  if (loading) {
    return <CircularProgress />
  }

  return (
    <Grid item sm={6}>
      <Grid container spacing={2}>
        <Grid item sm={8}>
          <Typography variant="h6" component="h5">
            GROUP PERMISSIONS
          </Typography>
        </Grid>
        <Grid item sm={4}>
          <Typography
            variant="inherit"
            component="h5"
            style={{ paddingTop: 10 }}
          >
            ADMIN
          </Typography>
        </Grid>
        <Grid item sm={12}>
          <MuiList>
            {groups.map(({ id, name, isAdmin }) => (
              <Grid container spacing={2} key={id}>
                <Grid item sm={8}>
                  <ListItem>
                    <ListItemIcon>
                      <GroupIcon />
                    </ListItemIcon>
                    <ListItemText primary={name} />
                  </ListItem>
                </Grid>

                <Grid item sm={4} style={{ paddingLeft: 20 }}>
                  <FormControlLabel
                    control={
                      <GreenCheckbox
                        checked={isAdmin}
                        onChange={(e) => {
                          if (e.target.checked) {
                            addGroupAdmin && addGroupAdmin(id)
                          } else {
                            removeGroupAdmin && removeGroupAdmin(id)
                          }
                        }}
                        name="checked"
                      />
                    }
                    label=""
                  />
                </Grid>
              </Grid>
            ))}
          </MuiList>
        </Grid>
      </Grid>
    </Grid>
  )
}

Permission.defaultProps = {
  addGroupAdmin: undefined,
  removeGroupAdmin: undefined,
  load: undefined,
  loading: false,
}

export default Permission
