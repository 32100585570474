// @flow
import { connect } from 'react-redux'
import Setup from '../components/Form/Setup'
import { setStepValid } from '../actions'

const mapDispatchToProps = {
  isValid: setStepValid,
}

export default connect(null, mapDispatchToProps)(Setup)
