// @flow
import { connect } from 'react-redux'
import GroupsTable from '../components/GroupsTable'
import { loadGroups } from '../actions'
import type { State, Dispatch } from '../../../store'

const mapStateToProps = ({
  groups: { listing: { loading, data, links = [] } } = {},
}: State) => ({
  data,
  loading,
  hasNext: links.some(({ rel }) => rel === 'next'),
  hasPrev: links.some(({ rel }) => rel === 'prev'),
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadGroups: () => dispatch(loadGroups()),
  gotoNext: () => dispatch(loadGroups('next')),
  gotoPrev: () => dispatch(loadGroups('prev')),
})

export default connect(mapStateToProps, mapDispatchToProps)(GroupsTable)
