// @flow
import { connect } from 'react-redux'
import Notification from '../components/Notification'
import popNotification from '../notifications/actions/popNotification'
import type { State } from '../store'

const mapStateToProps = ({
  notifications: [notification = {}] = [],
}: State) => ({
  visible: !!notification.id,
  title: notification.title,
  message: notification.message,
  type: notification.type,
})

const mapDispatchToProps = {
  clear: popNotification,
}

export default connect(mapStateToProps, mapDispatchToProps)(Notification)
