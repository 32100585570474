// @flow
import { connect } from 'react-redux'
import MembersTable from '../components/MembersTable'
import { loadMembers } from '../actions'
import type { State, Dispatch } from '../../../store'

const mapStateToProps = ({
  groups: { members: { group, loading, data, links = [] } = {} } = {},
}: State) => ({
  data,
  name: group?.name,
  loading,
  hasNext: links.some(({ rel }) => rel === 'next'),
  hasPrev: links.some(({ rel }) => rel === 'prev'),
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadMembers: () => dispatch(loadMembers()),
  gotoNext: () => dispatch(loadMembers('next')),
  gotoPrev: () => dispatch(loadMembers('prev')),
})

export default connect(mapStateToProps, mapDispatchToProps)(MembersTable)
