// @flow
import { connect } from 'react-redux'
import Data from '../components/Review/Data'
import { setStepValid } from '../actions'
import type { State } from '../../../store'

const errorCodes = ['missing_required']

const mapStateToProps = ({
  imports: {
    data: { rows, columns, warnings } = {},
    groups: { selected = [], data = [] } = {},
  } = {},
}: State) => ({
  rows,
  columns,
  groups: selected.map(
    (id) => (data.find(({ id: groupId }) => groupId === id) || {}).name,
  ),
  warnings: warnings.map(({ code, message }) => ({
    message,
    type: errorCodes.includes(code) ? 'error' : 'warning',
  })),
  hasErrors: warnings.some(({ code }) => errorCodes.includes(code)),
})

const mapDispatchToProps = {
  isValid: setStepValid,
}

export default connect(mapStateToProps, mapDispatchToProps)(Data)
