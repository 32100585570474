// @flow
import { connect } from 'react-redux'
import { debounce } from 'debounce'
import filterGroup from '../actions/filterGroup'
import GroupFilter from '../components/GroupFilter'
import { loadAllGroups } from '../../groups/actions/loadGroups'
import type { State } from '../../../store'

const mapStateToProps = ({
  groups: { listing: { data = [] } = {} } = {},
  users: { listing: { filter: { groupId } = {} } = {} } = {},
}: State) => ({
  value: groupId,
  groups: (data || []).map(({ id, name }) => ({ id, label: name })),
})

const mapDispatchToProps = (dispatch) => ({
  loadGroups: () => dispatch(loadAllGroups()),
  applyFilter: debounce((groupId) => dispatch(filterGroup(groupId)), 200),
})

export default connect(mapStateToProps, mapDispatchToProps)(GroupFilter)
