// @flow
import React, { useEffect, type Node } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import Input from '@material-ui/core/Input'
import MenuItem from '@material-ui/core/MenuItem'
import Checkbox from '@material-ui/core/Checkbox'
import ListItemText from '@material-ui/core/ListItemText'

type SelectorProps = {
  label: string,
  values: Array<{ id: number, name: string }>,
  selected: number[],
  load: () => void,
  setSelected: (number[]) => void,
  loading: boolean,
  children: ?Node,
}

const Selector = ({
  label,
  values,
  selected,
  load,
  setSelected,
  loading,
  children,
}: SelectorProps) => {
  useEffect(() => {
    load()
  }, [])

  if (loading) {
    return <CircularProgress />
  }

  if (!Array.isArray(values) || values.length < 1) {
    return <Typography>No values</Typography>
  }

  return (
    <>
      <FormControl fullWidth>
        <InputLabel>{label}</InputLabel>
        <Select
          fullWidth
          multiple
          value={selected}
          onChange={(e) => setSelected(e.target.value)}
          input={<Input />}
          renderValue={(selectedValues) =>
            selectedValues
              .map(
                (selectedId) =>
                  (values.find(({ id }) => id === selectedId) || {}).name,
              )
              .join(', ')
          }
        >
          {values.map(({ id, name }) => (
            <MenuItem key={id} value={id}>
              <Checkbox checked={selected.includes(id)} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{children}</FormHelperText>
      </FormControl>
    </>
  )
}

export default Selector
