// @flow
import React from 'react'
import MuiTable from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import Typography from '@material-ui/core/Typography'

type Skill = {
  skillName: string,
  groupName: string,
  typeName: string,
  roleName: string,
  ratingName: string,
}

type TableProps = {
  skills: Skill[],
}

const Table = ({ skills = [] }: TableProps) => {
  if (skills.length < 1) {
    return <Typography>No skills.</Typography>
  }

  return (
    <MuiTable>
      <TableHead>
        <TableRow>
          <TableCell>
            Skill (Group)
          </TableCell>
          <TableCell>
            Role
          </TableCell>
          <TableCell>
            Type
          </TableCell>
          <TableCell>
            Rating
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {skills.map(({ skillName, groupName, typeName, roleName, ratingName }) => (
          <TableRow key={skillName}>
            <TableCell>
              {skillName}
              <br />
              {`(${groupName})`}
            </TableCell>
            <TableCell>{roleName}</TableCell>
            <TableCell>{typeName}</TableCell>
            <TableCell>{ratingName}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </MuiTable>
  )
}

export default Table
