// @flow
import type { Action } from '../store'
import type { Notification } from './actions/pushNotification'

export type NotificationState = Array<{ ...Notification, id: number }>

const defaultState = []

export default (state: NotificationState = defaultState, action: Action) => {
  switch (action.type) {
    case 'notifications::PUSH_NOTIFICATION': {
      const { id, notification } = action
      return [...state, { ...notification, id }]
    }
    case 'notifications::POP_NOTIFICATION': {
      const [, ...rest] = state
      return rest
    }
    default:
      return state
  }
}
