// @flow
import React from 'react'

const Copyright = () => (
  <>
    {`© Lattus, Inc. ${new Date().getFullYear()}`}
  </>
)

export default Copyright
