// @flow

export type Warning = {
  field: string,
  code: 'unknown_field',
  message: string,
}

type LoadData = 'imports::LOAD_DATA'
export type LoadDataAction = {
  type: LoadData,
  rows: Object[],
  columns: string[],
  warnings: Warning[],
}

const AllowedFields = [
  'firstName',
  'lastName',
  'email',
  'maxConversations',
  'bio',
]

const RequiredFields = ['email']

const FieldParsers = new Map([
  [
    'maxConversations',
    (val: string): number => {
      const parsed = Number.parseInt(val, 10)
      if (Number.isNaN(parsed)) {
        throw new Error(`expected number, but got "${val}"`)
      }
      return parsed
    },
  ],
])

const Identity = (val) => val

type ParseResult = {
  warnings: Warning[],
  data: Object[],
}

const parseData = (rows: Object[], columns: string[]): ParseResult => {
  const warnings = [
    ...columns.map((field) => {
      if (AllowedFields.includes(field)) {
        return null
      }
      return {
        field,
        code: 'unknown_field',
        message: `The field "${field}" was not recognized, and will be ignored.`,
      }
    }),
    ...RequiredFields.map((field) => {
      if (columns.includes(field)) {
        return null
      }
      return {
        field,
        code: 'missing_required',
        message: `The field "${field}" is required, but was not found in the data set.`,
      }
    }),
  ].filter(Boolean)

  const data = rows.map((row, idx) => {
    const datum = AllowedFields.reduce(
      (partial, field) => {
        const parser = FieldParsers.get(field) || Identity
        try {
          return { ...partial, [field]: parser(row[field]) }
        } catch (err) {
          warnings.push({
            field,
            code: 'unexpected_value',
            message: `The field ${field} in row ${
              idx + 1
            } failed validation, and will be ignored: ${err.message}.`,
          })
        }
        return partial
      },
      { id: idx },
    )

    if (
      !RequiredFields.every(
        (field) =>
          // $FlowFixMe - failing on some fields, even though we safety check them
          (field in datum && datum[field] !== '') || !columns.includes(field),
      )
    ) {
      warnings.push({
        code: 'missing_required',
        message: `Row ${idx + 1} is missing a required field.`,
      })
    }

    return datum
  })

  // $FlowFixMe - flow isn't convinced that values here are all truthy
  return { warnings, data }
}

export default (rows: Object[], columns: string[]): LoadDataAction => {
  const { warnings, data } = parseData(rows, columns)
  return {
    type: 'imports::LOAD_DATA',
    rows: data,
    columns: columns.filter((column) => AllowedFields.includes(column)),
    warnings,
  }
}
