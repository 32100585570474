// @flow
import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

type SelectorProps = {
  activeReport: string,
  availableReports: Array<{ id: string, title: string }>,
  setActiveReport: (string) => void,
  classes: { [string]: string },
}

const styles = () => ({
  selector: {
    minWidth: '200px',
  },
})

class Selector extends Component<SelectorProps> {
  selectReport = (event: SyntheticInputEvent<HTMLSelectElement>) => {
    const { setActiveReport } = this.props
    setActiveReport(event.target.value)
  }

  render() {
    const { activeReport, availableReports, classes } = this.props
    return (
      <FormControl>
        <InputLabel>Select report</InputLabel>
        <Select value={activeReport || ''} onChange={this.selectReport} className={classes.selector}>
          {availableReports.map(({ id, title }) => <MenuItem key={id} value={id}>{title}</MenuItem>)}
        </Select>
      </FormControl>
    )
  }
}

export default withStyles(styles)(Selector)
