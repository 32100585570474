// @flow
import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import CSVTable, { type Column } from './CSVTable'

type ViewReportProps = {
  title: string,
  data: ?string,
  loading: boolean,
  columns: Column[],
}

const ViewReport = ({ title, data, loading, columns }: ViewReportProps) => (
  <>
    {loading && <CircularProgress />}
    {!loading && (
      <CSVTable
        title={title}
        csv={data}
        columns={columns}
      />
    )}
  </>
)

export default ViewReport
