// @flow
import React, { Component } from 'react'
import MaterialTable from '@material-table/core'
import csvToJson from 'csvtojson'
import Typography from '@material-ui/core/Typography'

export type Column = {
  title: string,
  field: string,
  defaultGroupOrder?: number,
  filtering?: boolean,
  type?: 'boolean' | 'numeric',
}

type CSVTableProps = {
  title: string,
  csv: ?string,
  columns: Column[],
}

type CSVTableState = {
  rows: Array<Object>,
}

const parseCSV = async (csv: ?string): Promise<Array<Object>> => {
  if (!csv) {
    return []
  }

  const rows = await csvToJson().fromString(csv)
  return rows.map((row) =>
    Object.fromEntries(
      Object.entries(row).map(([field, val]) => {
        if (['true', 'TRUE', 'false', 'FALSE'].includes(val)) {
          return [field, val === 'true' || val === 'TRUE']
        }
        return [field, val]
      }),
    ),
  )
}

class CSVTable extends Component<CSVTableProps, CSVTableState> {
  constructor(props: CSVTableProps) {
    super(props)
    this.state = { rows: [] }
  }

  componentDidMount() {
    const { csv } = this.props
    parseCSV(csv).then((rows) => this.setState({ rows }))
  }

  onComponentDidUpdate({ csv: prevCsv }: CSVTableProps) {
    const { csv } = this.props
    if (csv !== prevCsv) {
      parseCSV(csv).then((rows) => this.setState({ rows }))
    }
  }

  render() {
    const { title, columns } = this.props
    const { rows } = this.state

    return (
      <>
        {rows.length > 500 && (
          <Typography>
            Only showing the first 500 rows; please download to view the full
            report.
          </Typography>
        )}
        <MaterialTable
          title={title}
          columns={columns}
          data={rows.slice(0, 500)}
          options={{
            grouping: false,
            paging: false,
            filtering: rows.length < 100,
          }}
        />
      </>
    )
  }
}

export default CSVTable
