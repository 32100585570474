// @flow
import type {
  Dispatch,
  GetState,
  ThunkExtraArgument,
  ThunkAction,
} from '../../../store'
import { ApiError, type API } from '../../../api/api'
import load from '../../../actions/load'

export type Group = {
  id: number,
  name: string,
}

export type Link = {
  rel: string,
  uri: string,
}

type LoadingGroups = 'groups::LOADING_GROUPS'
export type LoadingGroupsAction = { type: LoadingGroups, loading: boolean }

type LoadedGroups = 'groups::LOADED_GROUPS'
export type LoadedGroupsAction = {
  type: LoadedGroups,
  data: Group[],
  links: Link[],
}

type LoadGroupsResult = { data: Group[], links: Link[] }

const loadGroups = async (
  api: API,
  fetch: () => Promise<Response>,
): Promise<LoadGroupsResult> => {
  try {
    const groups = await fetch()
    const data = await groups.json()
    const { values: links } = api.parseLinks(groups)
    return { data, links }
  } catch (err) {
    if (err instanceof ApiError) {
      throw err.wrap('loading groups')
    }
    throw err
  }
}

async function* pages(res: Response, api: API) {
  yield await res.json()
  let links = api.parseLinks(res)
  while (links.exists('next')) {
    // eslint-disable-next-line no-await-in-loop
    const nextPage = await links.follow('next')
    // eslint-disable-next-line no-await-in-loop
    const data = await nextPage.json()
    yield data
    links = api.parseLinks(nextPage)
  }
}

export const loadAllGroups =
  (): ThunkAction =>
  (dispatch: Dispatch, getState: GetState, { api }: ThunkExtraArgument) =>
    dispatch(
      load(
        async () => {
          try {
            let allGroups = []
            for await (const groups of pages(
              await api.invokeOperation('admin.getGroups'),
              api,
            )) {
              allGroups = [...allGroups, ...groups]
            }
            return allGroups
          } catch (err) {
            if (err instanceof ApiError) {
              throw err.wrap('loading all groups')
            }
            throw err
          }
        },
        (loading: boolean) => ({ type: 'groups::LOADING_GROUPS', loading }),
        (data: Group[]) => ({
          type: 'groups::LOADED_GROUPS',
          data,
          links: [],
        }),
      ),
    )

export default (rel: ?string): ThunkAction =>
  (dispatch: Dispatch, getState: GetState, { api }: ThunkExtraArgument) =>
    dispatch(
      load(
        () => {
          if (!rel) {
            return loadGroups(api, () => api.invokeOperation('admin.getGroups'))
          }

          const { groups: { listing: { links = [] } = {} } = {} } = getState()
          const link = links.find(({ rel: linkRel }) => linkRel === rel)
          if (!link) {
            throw new Error(
              `unable to follow link: no matching link found: "${rel}`,
            )
          }
          return loadGroups(api, () => api.apiFetch(link.uri))
        },
        (loading: boolean) => ({ type: 'groups::LOADING_GROUPS', loading }),
        ({ data, links }: LoadGroupsResult) => ({
          type: 'groups::LOADED_GROUPS',
          data,
          links,
        }),
      ),
    )
