// @flow
import { connect } from 'react-redux'
import editUser from '../actions/editUser'
import ProfileForm from '../components/Profile/ProfileForm'
import type { State } from '../../../store'

const mapStateToProps = ({
  users: { loaded: { data, saving = false } = {} } = {},
}: State) => ({
  profile: data,
  editabe: !saving,
})

const mapDispatchToProps = {
  onSubmit: editUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileForm)
