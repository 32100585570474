// @flow
import { connect } from 'react-redux'
import { createGroup, loadGroups, showCreateGroup } from '../actions'
import { loadSkillGroups } from '../../skills/actions'
import CreateGroupPanel from '../components/CreateGroupPanel'
import { State, Dispatch } from '../../../store'
import pushNotification from '../../../notifications/actions/pushNotification'

const mapStateToProps = ({
  groups: { create: { visible, saving, data } = {} } = {},
  skills: { skillGroups: { loading, data: skillGroups } = {} } = {},
}: State) => ({
  open: visible,
  creating: saving,
  created: !saving && !!data,
  skillGroups,
  loading,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  createGroup: ({
    name,
    description,
    prefix,
    logo,
    banner,
    whiteLabelLogo,
    whiteLabelVideoURL,
    defaultSkill,
    recodingValue,
    isMailingValue,
    isActive,
    isFake,
  }: {
    name: string,
    description: string,
    prefix: string | null,
    logo: File | null,
    banner: File | null,
    whiteLabelLogo: File | null,
    whiteLabelVideoURL: string | null,
    defaultSkill: any | null,
    recodingValue: boolean,
    isMailingValue: Boolean,
    isActive: Boolean,
    isFake: Boolean,
  }) =>
    dispatch(
      createGroup({
        name,
        description,
        prefix,
        logo,
        banner,
        whiteLabelLogo,
        whiteLabelVideoURL,
        defaultSkill,
        recodingValue,
        isMailingValue,
        isActive,
        isFake,
      }),
    ),
  loadSkillGroups: () => dispatch(loadSkillGroups()),
  close: () => {
    dispatch(showCreateGroup(false))
    dispatch(loadGroups())
  },
  onCreate: (title: string) =>
    dispatch(
      pushNotification({
        title,
        type: 'success',
      }),
    ),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateGroupPanel)
