import React, {
  createContext,
  useContext,
  useMemo,
  type ReactNode,
} from 'react'

import { logger } from '@/logger/logger'

interface LoggerContextType {
  debug: (message: any, ...optionalParams: any[]) => void
  info: (message: any, ...optionalParams: any[]) => void
  warn: (message: any, ...optionalParams: any[]) => void
  error: (message: any, ...optionalParams: any[]) => void
}

const LoggerContext = createContext<LoggerContextType>(
  logger as LoggerContextType,
)

interface LoggerProviderProps {
  children: ReactNode
}

export const LoggerProvider: React.FC<LoggerProviderProps> = ({ children }) => {
  const value = useMemo(
    () => ({
      debug: (message: any, ...optionalParams: any[]) =>
        logger.debug(message, ...optionalParams),
      info: (message: any, ...optionalParams: any[]) =>
        logger.info(message, ...optionalParams),
      warn: (message: any, ...optionalParams: any[]) =>
        logger.warn(message, ...optionalParams),
      error: (message: any, ...optionalParams: any[]) =>
        logger.error(message, ...optionalParams),
    }),
    [],
  )

  return (
    <LoggerContext.Provider value={value}>{children}</LoggerContext.Provider>
  )
}

export const useLogger = (): LoggerContextType => useContext(LoggerContext)
