// @flow
import { connect } from 'react-redux'
import { deleteGroup } from '../actions'
import ConfirmButton from '../components/ConfirmButton'
import { State, Dispatch } from '../../../store'

const mapStateToProps = ({
  groups: { listing: { deleting } = {} } = {},
}: State) => ({
  disabled: deleting,
})

const mapDispatchToProps = (dispatch: Dispatch, ownProps: {groupId: number}) => ({
   onClick: () => dispatch(deleteGroup(ownProps.groupId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmButton)
