// @flow
import { connect } from 'react-redux'
import Toggle from '../components/Form/Toggle'
import { setCreateAccounts } from '../actions'
import type { State } from '../../../store'

const mapStateToProps = ({ imports: { createAccounts } = {} }: State) => ({
  value: createAccounts,
  label: 'Create Auth0 accounts',
})

const mapDispatchToProps = {
  onChange: setCreateAccounts,
}

export default connect(mapStateToProps, mapDispatchToProps)(Toggle)
