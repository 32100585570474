/** Auth0Config defines the configuration for initializing the Auth0 client. */
export type Auth0Config = {
  domain: string
  clientId: string
  audience: string
}

/** BaseUri defines the base URI values for different subsystems and services. */
export type BaseUri = {
  lattusApi: string
  lattusApp: string
  helpdesk: string
  redirect: string
}

/** DataDogConfig defines the configuration for observability. */
export type DataDogPrivacyLevel = 'mask' | 'mask-user-input' | 'allow'
export type DataDogConfig = {
  allowedTracingUrls?: string[] | RegExp[] | Array<(u: string) => boolean>
  applicationId?: string
  clientToken?: string
  site?: string
  service?: string
  env?: string
  version?: string
  sessionSampleRate?: number
  sessionReplaySampleRate?: number
  trackResources?: boolean
  trackLongTasks?: boolean
  trackUserInteractions?: boolean
  defaultPrivacyLevel?: DataDogPrivacyLevel
}

/** LogLevel are the available logger levels. */
export type LogLevel = 'debug' | 'info' | 'warn' | 'error'

export type NavLinks = {
  app: string
  apispec: string
}

/** Config is the entire application configuration object. */
export type Config = {
  logLevel: LogLevel
  auth0: Auth0Config
  baseUri: BaseUri
  environment: string // TODO can this be a more known enumeration?
  sentry: {
    dsn?: string
    environment?: string
    tracesSampleRate?: number
    replaySampleRate?: number
  }
  datadog: DataDogConfig
  links: NavLinks
  version: string
}

/**
 * defaultConfig is a reasonable default configuration.
 * Note that "reasonable" does not mean "usable". This provides a fallback for type safety, but
 * it will not necessarily work in a deployed environment.
 */
export const defaultConfig: Config = Object.freeze({
  logLevel: 'info',
  auth0: {
    domain: '',
    clientId: '',
    audience: '',
  },
  baseUri: {
    graph: '',
    lattusApi: '',
    lattusApp: '',
    helpdesk: '',
    redirect: '',
  },
  environment: '',
  sentry: {},
  datadog: {},
  links: {
    app: '',
    apispec: '',
  },
  version: 'lattus-admin@0.0.0',
})
