// @flow
import type { Group } from './loadGroups'
import type { Dispatch, GetState } from '../../../store'

type ShowEditGroup = 'groups::SHOW_EDIT_GROUP'
export type ShowEditGroupAction = {
  type: ShowEditGroup,
  data: Group,
}

export const showEditGroup = (groupId: number) => (
  dispatch: Dispatch,
  getState: GetState,
) => {
  const { groups: { listing: { data = [] } = {} } = {} } = getState()
  const group = data.find(({ id }) => id === groupId)

  if (!group) {
    throw new Error(`unable to load group with id "${groupId}"`)
  }

  dispatch({
    type: 'groups::SHOW_EDIT_GROUP',
    data: group,
  })
}

type HideEditGroup = 'groups::HIDE_EDIT_GROUP'
export type HideEditGroupAction = {
  type: HideEditGroup,
}

export const hideEditGroup = () => ({
  type: 'groups::HIDE_EDIT_GROUP',
})
