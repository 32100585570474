// @flow
import { createStore, compose, combineReducers } from 'redux'
import appEnhancer, { type EnhancedStore } from './enhancer'
import authReducer, { type State as AuthState } from './auth/reducer'
import type { Action as AuthAction } from './auth/actions'
import notificationsReducer, {
  type State as NotificationsState,
} from './notifications/reducer'
import type { Action as NotificationsAction } from './notifications/actions'
import reportsReducer, {
  type State as ReportsState,
} from './modules/reports/reducer'
import type { Action as ReportsAction } from './modules/reports/actions'
import groupsReducer, {
  type State as GroupsState,
} from './modules/groups/reducer'
import type { Action as GroupsAction } from './modules/groups/actions'
import importsReducer, {
  type State as ImportsState,
} from './modules/imports/reducer'
import type { Action as ImportsAction } from './modules/imports/actions'
import schemasReducer, {
  type State as SchemasState,
} from './modules/schemas/reducer'
import type { Action as SchemasAction } from './modules/schemas/actions'
import usersReducer, { type State as UsersState } from './modules/users/reducer'
import type { Action as UsersAction } from './modules/users/actions'
import type { Action as SkillAction } from './modules/skills/actions'
import skillsReducer, {
  type State as SkillsState,
} from './modules/skills/reducer'

export type State = {
  auth: AuthState,
  groups: GroupsState,
  imports: ImportsState,
  notifications: NotificationsState,
  reports: ReportsState,
  schemas: SchemasState,
  users: UsersState,
  skills: SkillsState,
}

export type ThunkExtraArgument = { api: typeof api }
export type Action =
  | AuthAction
  | ImportsAction
  | NotificationsAction
  | ReportsAction
  | GroupsAction
  | SchemasAction
  | SkillAction
  | UsersAction
export type ThunkAction = (Dispatch, GetState, ThunkExtraArgument) => * // eslint-disable-line no-use-before-define
export type PromiseAction = Promise<Action>
export type Dispatchable = Action | ThunkAction | PromiseAction
export type Dispatch = (Dispatchable) => *
export type GetState = () => State
export type Store = EnhancedStore<State, Action, Dispatch>

export default function configureStore(config) {
  const { auth0, baseUri } = config
  return createStore(
    combineReducers({
      auth: authReducer,
      groups: groupsReducer,
      imports: importsReducer,
      notifications: notificationsReducer,
      reports: reportsReducer,
      schemas: schemasReducer,
      skills: skillsReducer,
      users: usersReducer,
    }),
    compose(
      ...[
        appEnhancer({
          auth: auth0,
          api: {
            baseUri: baseUri.lattusApi,
          },
        }),
        /* eslint-disable no-underscore-dangle */
        window.__REDUX_DEVTOOLS_EXTENSION__ &&
          window.__REDUX_DEVTOOLS_EXTENSION__(),
        /* eslint-enable no-underscore-dangle */
      ].filter(Boolean),
    ),
  )
}
