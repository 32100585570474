// @flow
import React, { type ComponentType, useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

type ConfirmButtonProps = {
  confirmationTitle?: string,
  confirmationText: string,
  confirmationLabel?: string,
  onClick: () => void,
  component?: ComponentType<{
    onClick: () => void,
  }>,
  disabled?: boolean,
}

const ConfirmButton = ({ confirmationTitle = 'Confirm', confirmationText, confirmationLabel = 'Continue', onClick, disabled, component: Component = Button, ...props }: ConfirmButtonProps) => {
  const [confirmVisible, setConfirmVisible] = useState(false)

  return (
    <>
      <Dialog
        open={confirmVisible}
        onClose={() => setConfirmVisible(false)}
      >
        <DialogTitle>{confirmationTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {confirmationText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmVisible(false)}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              onClick()
              setConfirmVisible(false)
            }}
          >
            {confirmationLabel}
          </Button>
        </DialogActions>
      </Dialog>
      <Component
        onClick={() => setConfirmVisible(true)}
        disabled={confirmVisible || disabled}
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...props}
      />
    </>
  )
}

ConfirmButton.defaultProps = {
  component: Button,
  confirmationTitle: 'Confirm',
  confirmationLabel: 'Continue',
  disabled: false,
}

export default ConfirmButton
