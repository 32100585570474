// @flow
import type {
  Dispatch,
  GetState,
  ThunkExtraArgument,
  ThunkAction,
} from '../../../store'
import { ApiError, type API } from '../../../api/api'
import load from '../../../actions/load'

type LinkingAccount = 'users::LINKING_ACCOUNT'
export type LinkingAccountAction = { type: LinkingAccount, saving: boolean }

type LinkedAccount = 'users::LINKED_ACCOUNT'
export type LinkedAccountAction = { type: LinkedAccount, data: string }

type LinkAccountResult = { data: string }

const linkAccount = async (
  api: API,
  profileId: number,
  externalId: string,
): Promise<LinkAccountResult> => {
  try {
    await api.invokeOperation('admin.linkAccount', {
      params: { profileId },
      headers: new Headers({ 'content-type': 'application/json' }),
      body: JSON.stringify({ externalId }),
    })
    return { data: externalId }
  } catch (err) {
    if (err instanceof ApiError) {
      throw err.wrap('linking account')
    }
    throw err
  }
}

export default (externalId: string): ThunkAction =>
  (dispatch: Dispatch, getState: GetState, { api }: ThunkExtraArgument) => {
    const { users: { loaded: { id: userId } = {} } = {} } = getState()
    if (!userId) {
      throw new Error('cannot link user: no user selected')
    }
    dispatch(
      load(
        () => linkAccount(api, userId, externalId),
        (saving: boolean) => ({ type: 'users::LINKING_ACCOUNT', saving }),
        ({ data }: LinkAccountResult) => ({
          type: 'users::LINKED_ACCOUNT',
          data,
        }),
      ),
    )
  }
