// @flow
import React from 'react'
import Typography from '@material-ui/core/Typography'

type MessageProps = {
  title: string,
  body?: null | string | string[],
}

const Message = ({ title, body }: MessageProps) => (
  <>
    <Typography variant="h3">{title}</Typography>
    {body && !Array.isArray(body) && <Typography paragraph>{body}</Typography>}
    {body &&
      Array.isArray(body) &&
      body.map((para) => <Typography paragraphy>{para}</Typography>)}
  </>
)

Message.defaultProps = {
  body: null,
}

export default Message
