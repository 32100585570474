import { datadogRum } from '@datadog/browser-rum'
import { init } from '@sentry/browser'
import { makeGlobalLogger } from './logger/logger'
import loadingConfig from './config/init'
// import version from './version'

// initialize sentry
loadingConfig
  .then((config) => {
    // Update version in window
    const { version } = config
    if (window?.lattitude) {
      window.lattitude.version = version
    }
    // Init Logger
    const logger = makeGlobalLogger({
      name: 'GLOBAL_LOGGER',
      level: config.logLevel,
    })
    logger.info('Logger initialized', { logLevel: config.logLevel, version })

    // Init Sentry
    const { dsn, environment, replaySampleRate, tracesSampleRate } =
      config.sentry

    if (dsn) {
      init({
        dsn,
        environment,
        release: `lattus-admin@${version}`,
      })
      logger.info('Sentry initialized', {
        hasDsn: !!dsn,
        environment,
        replaySampleRate,
        tracesSampleRate,
        version,
      })
    } else {
      // eslint-disable-next-line no-console -- during init, wont use pino logger
      logger.debug('Sentry not initialized', { environment, version })
    }
    return { config, logger }
  })
  .then(({ config: { datadog, version }, logger }) => {
    const {
      applicationId,
      clientToken,
      defaultPrivacyLevel,
      env,
      service,
      site,
      sessionSampleRate,
      sessionReplaySampleRate,
      trackUserInteractions,
      trackLongTasks,
      trackResources,
    } = datadog

    // DataDog RUM
    if (clientToken) {
      datadogRum.init({
        ...datadog,
        allowedTracingUrls: [
          /https:\/\/api\.golattitude\.com(\/.*)?/,
          /http:\/\/localhost:5002(\/.*)?/,
        ],
      })

      logger.info('DataDog initialized', {
        service,
        site,
        env,
        hasClientToken: !!clientToken,
        hasApplicationId: !!applicationId,
        dpl: defaultPrivacyLevel,
        ssr: sessionSampleRate,
        srsr: sessionReplaySampleRate,
        tui: trackUserInteractions,
        tlt: trackLongTasks,
        tr: trackResources,
        version,
      })
    } else {
      // eslint-disable-next-line no-console -- during init, wont use pino logger
      console.debug('DataDog not initialized', { env, service, site })
    }
  })
