// @flow
import type {
  Dispatch,
  GetState,
  ThunkExtraArgument,
  ThunkAction,
} from '../../../store'
import { ApiError, type API } from '../../../api/api'
import load from '../../../actions/load'

export type SchemaType = 'notes' | 'feedback'

export type SchemaData = {
  type: SchemaType,
  definition: mixed,
}

export type Schema = {
  ...SchemaData,
  id: number,
  active: boolean,
}

export type Link = {
  rel: string,
  uri: string,
}

type LoadingSchemas = 'schemas::LOADING_SCHEMAS'
export type LoadingSchemasAction = { type: LoadingSchemas, loading: boolean }

type LoadedSchemas = 'schemas::LOADED_SCHEMAS'
export type LoadedSchemasAction = {
  type: LoadedSchemas,
  data: Schema[],
  links: Link[],
}

type LoadSchemasResult = { data: Schema[], links: Link[] }

type Filter = {
  status?: 'active' | 'inactive' | 'any',
  type?: SchemaType,
}

const loadSchemas = async (
  api: API,
  query: ?Filter = {},
): Promise<LoadSchemasResult> => {
  try {
    const schemas = await api.invokeOperation('getSchemas', {
      query: query || {},
    })
    const data = await schemas.json()
    const { values: links } = api.parseLinks(schemas)
    return { data, links }
  } catch (err) {
    if (err instanceof ApiError) {
      throw err.wrap('loading schemas')
    }
    throw err
  }
}

const loadingSchemas = (loading: boolean) => ({
  type: 'schemas::LOADING_SCHEMAS',
  loading,
})

const loadedSchemas = ({ data, links }: LoadSchemasResult) => ({
  type: 'schemas::LOADED_SCHEMAS',
  data,
  links,
})

export default (filter: ?Filter): ThunkAction =>
  (dispatch: Dispatch, getState: GetState, { api }: ThunkExtraArgument) =>
    dispatch(
      load(() => loadSchemas(api, filter), loadingSchemas, loadedSchemas),
    )

const loadLink = async (api: API, { uri }: Link) => {
  try {
    const schemas = await api.apiFetch(uri)
    const data = await schemas.json()
    const { values: nextLinks } = api.parseLinks(schemas)
    return { data, links: nextLinks }
  } catch (err) {
    if (err instanceof ApiError) {
      throw err.wrap('following linked resource')
    }
    throw err
  }
}

export const follow =
  (rel: string): ThunkAction =>
  (dispatch: Dispatch, getState: GetState, { api }: ThunkExtraArgument) => {
    const { schemas: { listing: { links = [] } = {} } = {} } = getState()
    const link = links.find(({ rel: linkRel }) => linkRel === rel)
    if (!link) {
      throw new Error(`unable to follow link: no matching link found: "${rel}"`)
    }
    dispatch(load(() => loadLink(api, link), loadingSchemas, loadedSchemas))
  }
