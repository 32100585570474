// @flow
import { connect } from 'react-redux'
import { debounce } from 'debounce'
import filterEmail from '../actions/filterEmail'
import EmailFilter from '../components/EmailFilter'
import type { State } from '../../../store'

const mapStateToProps = ({
  users: { listing: { filter: { email = '' } = {} } = {} } = {},
  groups: { members: { visible, filter = {} } = {} } = {},
}: State) => ({
  value: visible && filter ? filter.email : email,
})

const mapDispatchToProps = (dispatch) => ({
  applyFilter: debounce((email) => dispatch(filterEmail(email)), 500),
})

export default connect(mapStateToProps, mapDispatchToProps)(EmailFilter)
