// @flow
import type { Schema, Link } from './actions/loadSchemas'
import type { Action } from '../../store'

export type State = {
  edit: {
    visible: boolean,
    saving: boolean,
    saved: boolean,
    data?: Schema,
  },
  listing: {
    loading: boolean,
    data?: Schema[] | null,
    links?: Link[] | null,
  },
}

const defaultState = {
  edit: { visible: false, saving: false, saved: false },
  listing: { loading: false },
}

export default (state: State = defaultState, action: Action) => {
  switch (action.type) {
    case 'schemas::LOADING_SCHEMAS': {
      const { loading } = action
      const { listing = {} } = state
      return { ...state, listing: { ...listing, loading } }
    }
    case 'schemas::LOADED_SCHEMAS': {
      const { data, links } = action
      const { listing = {} } = state
      return { ...state, listing: { ...listing, data, links } }
    }
    case 'schemas::SHOW_EDIT_SCHEMA': {
      const { data } = action
      return { ...state, edit: { visible: true, data } }
    }
    case 'schemas::HIDE_EDIT_SCHEMA': {
      return { ...state, edit: { visible: false } }
    }
    case 'schemas::EDITING_SCHEMA': {
      const { saving } = action
      const { edit = {} } = state
      return { ...state, edit: { ...edit, saving } }
    }
    case 'schemas::EDITED_SCHEMA': {
      const { data } = action
      const { edit = {} } = state
      return { ...state, edit: { ...edit, data, saved: true } }
    }
    default:
      return state
  }
}
