// @flow
import type {
  Dispatch,
  GetState,
  ThunkExtraArgument,
  ThunkAction,
} from '../../../store'
import { ApiError, type API } from '../../../api/api'
import load from '../../../actions/load'

type DeletingAccount = 'users::DELETING_ACCOUNT'
export type DeletingAccountAction = { type: DeletingAccount, deleting: boolean }

type DeletedAccount = 'users::DELETED_ACCOUNT'
export type DeletedAccountAction = { type: DeletedAccount, data: number }

type DeleteAccountResult = { data: number }

const deleteAccount = async (
  api: API,
  profileId: number,
): Promise<DeleteAccountResult> => {
  try {
    await api.invokeOperation('admin.deleteAccount', {
      params: { profileId },
    })
    return { data: profileId }
  } catch (err) {
    if (err instanceof ApiError) {
      throw err.wrap('deleting account')
    }
    throw err
  }
}

export default (): ThunkAction =>
  (dispatch: Dispatch, getState: GetState, { api }: ThunkExtraArgument) => {
    const { users: { loaded: { id: userId } = {} } = {} } = getState()
    if (!userId) {
      throw new Error('cannot delete user account: no user selected')
    }
    dispatch(
      load(
        () => deleteAccount(api, userId),
        (deleting: boolean) => ({ type: 'users::DELETING_ACCOUNT', deleting }),
        ({ data }: DeleteAccountResult) => ({
          type: 'users::DELETED_ACCOUNT',
          data,
        }),
      ),
    )
  }
