// @flow
import React, { useEffect } from 'react'
import Dropzone from './Dropzone'

type UploadCSVProps = {
  label: string,
  onUpload: (Object[], string[]) => void,
  clear: () => void,
  disabled: boolean,
  isValid: (boolean) => void,
}

const UploadCSV = ({ label, onUpload, clear, disabled, isValid }: UploadCSVProps) => {
  useEffect(() => {
    clear()
  }, [])

  useEffect(() => {
    isValid(disabled)
  }, [disabled])

  return (
    <Dropzone label={label} onUpload={onUpload} disabled={disabled}>
      Drag and drop a CSV file.
    </Dropzone>
  )
}

export default UploadCSV
