// @flow
import type {
  Dispatch,
  GetState,
  ThunkExtraArgument,
  ThunkAction,
} from '../../../store'
import { ApiError, type API } from '../../../api/api'
import load from '../../../actions/load'

type Group = {
  id: number,
  name: string,
}

type JoiningGroup = 'users::JOINING_GROUP'
export type JoiningGroupAction = { type: JoiningGroup, saving: boolean }

type JoinedGroup = 'users::JOINED_GROUP'
export type JoinedGroupAction = { type: JoinedGroup, data: string }

type JoinGroupResult = { data: Group }

async function* pages(res: Response, api: API) {
  yield await res.json()
  let links = api.parseLinks(res)
  while (links.exists('next')) {
    // eslint-disable-next-line no-await-in-loop
    const nextPage = await links.follow('next')
    // eslint-disable-next-line no-await-in-loop
    const data = await nextPage.json()
    yield data
    links = api.parseLinks(nextPage)
  }
}

const getGroup = async (groupId: number, api: API): Promise<?Group> => {
  let group
  for await (const groupsPage of pages(
    await api.invokeOperation('admin.getGroups'),
    api,
  )) {
    group = groupsPage.find(({ id }) => id === groupId)
    if (group) {
      break
    }
  }
  return group
}

const joinGroup = async (
  api: API,
  profileId: number,
  groupId: number,
): Promise<JoinGroupResult> => {
  const group = await getGroup(groupId, api)
  if (!group) {
    throw new Error(`invalid group: ${groupId}`)
  }

  try {
    await api.invokeOperation('admin.addGroupMembers', {
      params: { groupId },
      headers: new Headers({ 'content-type': 'application/json' }),
      body: JSON.stringify({ profileIds: [profileId] }),
    })
    return { data: group }
  } catch (err) {
    if (err instanceof ApiError) {
      throw err.wrap('joining group')
    }
    throw err
  }
}

export default (groupId: number): ThunkAction =>
  (dispatch: Dispatch, getState: GetState, { api }: ThunkExtraArgument) => {
    const { users: { loaded: { id: userId } = {} } = {} } = getState()
    if (!userId) {
      throw new Error('cannot join group: no user selected')
    }
    dispatch(
      load(
        () => joinGroup(api, userId, groupId),
        (saving: boolean) => ({ type: 'users::JOINING_GROUP', saving }),
        ({ data }: JoinGroupResult) => ({ type: 'users::JOINED_GROUP', data }),
      ),
    )
  }
