// @flow
import React, { useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import Failures from './Failures'

type ProgressProps = {
  queue: Object[],
  progress: number,
  failed: Array<{ id: number, data: Object, err: Error }>,
  startImport: (Object[]) => void,
  isValid: (boolean) => void,
  caption: string,
  classes: { [string]: string },
}

const styles = (theme) => ({
  progress: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
})

const Progress = ({
  queue,
  progress,
  failed,
  startImport,
  isValid,
  caption,
  classes,
}: ProgressProps) => {
  useEffect(() => {
    startImport(queue)
  }, [])

  useEffect(() => {
    isValid(progress >= 100)
  }, [progress])

  return (
    <>
      <div className={classes.progress}>
        <Typography>{caption}</Typography>
        <LinearProgress variant="determinate" value={progress} />
      </div>
      {failed && failed.length > 0 && (
        <>
          <Typography variant="h4">Failures</Typography>
          <Failures failed={failed} />
        </>
      )}
    </>
  )
}

export default withStyles(styles)(Progress)
