// @flow
import React, { useCallback, type Node } from 'react'
import clsx from 'clsx'
import { withStyles } from '@material-ui/core/styles'
import { useDropzone } from 'react-dropzone'
import InputBase from '@material-ui/core/InputBase'
import FormLabel from '@material-ui/core/FormLabel'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import DoneIcon from '@material-ui/icons/Done'
import Paper from '@material-ui/core/Paper'
import csvToJson from 'csvtojson'

type DropzoneProps = {
  label: string,
  onUpload: (Object[], string[]) => void,
  onAbort?: null | ((string) => void),
  onError?: null | ((string) => void),
  disabled?: boolean,
  children: ?Node,
  classes: { [string]: string },
}

const styles = (theme) => ({
  container: {
    width: '100%',
    display: 'flex',
  },
  dropzoneRoot: {
    overflow: 'hidden',
    width: '200px',
    height: '200px',
    backgroundColor: theme.palette.grey[100],
    transition: 'all 1s linear',
    margin: 'auto',
  },
  inner: {
    padding: theme.spacing(2),
    border: '5px solid transparent',
    width: '200px',
    height: '200px',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  icon: {
    textAlign: 'center',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  bar: {
    position: 'absolute',
    width: '50px',
    height: '5px',
    transition: 'all 1s linear',
    animationDuration: '1s',
    animationFillMode: 'both',
    animationIterationCount: 'infinite',
    background: 'transparent',
  },
  delay: {
    animationDelay: '0.5s',
  },
  top: {
    left: '-5px',
    top: '-5px',
    animationName: '$hmove',
  },
  right: {
    right: '-28px',
    top: '18px',
    transform: 'rotate(90deg)',
    animationName: '$vmove',
  },
  bottom: {
    bottom: '-5px',
    left: '-5px',
    animationName: '$hmove',
  },
  left: {
    top: '18px',
    left: '-28px',
    transform: 'rotate(90deg)',
    animationName: '$vmove',
  },
  '@keyframes hmove': {
    from: {
      left: '-5px',
    },
    to: {
      left: '200px',
    },
  },
  '@keyframes vmove': {
    from: {
      top: '-5px',
    },
    to: {
      top: '228px',
    },
  },
  hover: {
    backgroundColor: theme.palette.secondary.light,
    '& $bar': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
})

const parseCsv = async (csv: ?string): Promise<Object[]> => {
  if (!csv) {
    return []
  }
  const rows = await csvToJson().fromString(csv)
  return rows
}

const Dropzone = ({
  label,
  onUpload,
  onAbort,
  onError,
  disabled,
  children,
  classes,
}: DropzoneProps) => {
  const onDrop = useCallback((files) => {
    if (!files || !Array.isArray(files) || files.length < 1) {
      return
    }
    files.map(async (file) => {
      const reader = new FileReader()
      reader.onabort = () => {
        if (typeof onAbort === 'function') {
          onAbort('File upload was aborted.')
        }
      }
      reader.onerror = () => {
        if (typeof onError === 'function') {
          onError('File upload failed.')
        }
      }
      reader.onload = async () => {
        const textStr = reader.result
        if (typeof textStr !== 'string') {
          onUpload([], [])
          return
        }
        try {
          const parsed = await parseCsv(textStr)
          const headers = parsed.length > 0 ? Object.keys(parsed[0]) : []
          onUpload(parsed, headers)
        } catch (err) {
          if (typeof onError === 'function') {
            onError('File type is not supported.')
          }
        }
      }
      reader.readAsText(file)
    })
  }, [])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    disabled,
    multiple: false,
    onDrop,
  })

  return (
    <div className={classes.container}>
      <FormControl
        className={clsx({
          [classes.dropzoneRoot]: true,
          [classes.hover]: isDragActive,
        })}
        margin="normal"
        fullWidth
        disabled={disabled}
        component={Paper}
        elevation={3}
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...getRootProps()}
      >
        <div className={classes.inner}>
          <div className={clsx(classes.bar, classes.top)} />
          <div className={clsx(classes.bar, classes.left)} />
          <div className={clsx(classes.bar, classes.right, classes.delay)} />
          <div className={clsx(classes.bar, classes.bottom, classes.delay)} />

          <FormLabel>{label}</FormLabel>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <InputBase {...getInputProps()} />
          <div className={classes.icon}>
            {!disabled && (
              <CloudUploadIcon fontSize="large" color="secondary" />
            )}
            {disabled && <DoneIcon fontSize="large" color="disabled" />}
          </div>
          <FormHelperText>
            {!disabled && <>{isDragActive ? 'Drop file here' : children}</>}
            {disabled && <>File uploaded!</>}
          </FormHelperText>
        </div>
      </FormControl>
    </div>
  )
}

Dropzone.defaultProps = {
  disabled: false,
  onAbort: null,
  onError: null,
}

export default withStyles(styles)(Dropzone)
