// @flow
import type { Dispatch } from '../../../store'

type SetStepValid = 'imports::SET_STEP_VALID'
export type SetStepValidAction = { type: SetStepValid, valid: boolean }

export const setStepValid = (valid: boolean) => ({
  type: 'imports::SET_STEP_VALID',
  valid,
})

type SetStep = 'imports::SET_STEP'
export type SetStepAction = { type: SetStep, delta: number }

export const nextStep = () => (dispatch: Dispatch) => {
  dispatch({
    type: 'imports::SET_STEP',
    delta: 1,
  })
  dispatch(setStepValid(false))
}

export const prevStep = () => ({
  type: 'imports::SET_STEP',
  delta: -1,
})

type ResetStep = 'imports::RESET_STEP'
export type ResetStepAction = { type: ResetStep }

export const resetStep = () => ({
  type: 'imports::RESET_STEP',
})
