// this file sets a version on the global window, for easy reference

// eslint-disable-next-line import/no-mutable-exports
let version = process.env.REACT_APP_VERSION ?? '0.0.0'
if (version.at(0) === 'v') {
  version = `lattus-admin@${version.substring(1)}`
}

if (window) {
  const lattitude = { ...window.lattitude }
  lattitude.version = version
  window.lattitude = lattitude
}

export default version
