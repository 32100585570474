// @flow
import type {
  Dispatch,
  GetState,
  ThunkExtraArgument,
  ThunkAction,
} from '../../../store'
import { ApiError, type API } from '../../../api/api'
import load from '../../../actions/load'

type LeavingGroup = 'users::LEAVING_GROUP'
export type LeavingGroupAction = { type: LeavingGroup, saving: boolean }

type LeftGroup = 'users::LEFT_GROUP'
export type LeftGroupAction = { type: LeftGroup, data: number }

type LeaveGroupResult = { data: number }

const leaveGroup = async (
  api: API,
  profileId: number,
  groupId: number,
): Promise<LeaveGroupResult> => {
  try {
    await api.invokeOperation('admin.removeGroupMembers', {
      params: { groupId },
      headers: new Headers({ 'content-type': 'application/json' }),
      body: JSON.stringify({ profileIds: [profileId] }),
    })
    return { data: groupId }
  } catch (err) {
    if (err instanceof ApiError) {
      throw err.wrap('leaving group')
    }
    throw err
  }
}

export default (groupId: number): ThunkAction =>
  (dispatch: Dispatch, getState: GetState, { api }: ThunkExtraArgument) => {
    const { users: { loaded: { id: userId } = {} } = {} } = getState()
    if (!userId) {
      throw new Error('cannot leave group: no user selected')
    }
    dispatch(
      load(
        () => leaveGroup(api, userId, groupId),
        (saving: boolean) => ({ type: 'users::LEAVING_GROUP', saving }),
        ({ data }: LeaveGroupResult) => ({ type: 'users::LEFT_GROUP', data }),
      ),
    )
  }
