// @flow
import type { SkillGroup } from './actions/loadSkillGroups'
import type { Action } from '../../store'

export type State = {
  skillGroups: {
    loading: boolean,
    data?: SkillGroup[],
  },
}

const defaultState = {
  skillGroups: { loading: false, data: [] },
}

export default (state: State = defaultState, action: Action) => {
  switch (action.type) {
    case 'skills::LOADING_SKILLGROUPS': {
      const { loading } = action
      const { skillGroups = {} } = state
      return { ...state, skillGroups: { ...skillGroups, loading } }
    }
    case 'skills::LOADED_SKILLGROUPS': {
      const { data} = action
      const { skillGroups = {} } = state
      return { ...state, skillGroups: { ...skillGroups, data } }
    }
    default:
      return state
  }
}
