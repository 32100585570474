// @flow
import React from 'react'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import { green } from '@material-ui/core/colors'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'

type ListProps = {
  isAdmin?: boolean,
  addLattusAdmin?: ?(void) => void,
  removeLattusAdmin?: ?(void) => void,
}

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
  // eslint-disable-next-line react/jsx-props-no-spreading
})((props) => <Checkbox color="default" {...props} />)

const GlobalPermission = ({
  isAdmin,
  addLattusAdmin,
  removeLattusAdmin,
}: ListProps) => {
  return (
    <Grid item sm={6}>
      <Grid container spacing={2}>
        <Grid item sm={8}>
          <Typography variant="h6" component="h5">
            LATTUS PERMISSIONS
          </Typography>
        </Grid>
        <Grid item sm={4}>
          <Typography
            variant="inherit"
            component="h5"
            style={{ paddingTop: 10 }}
          >
            ADMIN
          </Typography>
        </Grid>
        <Grid item sm={8} style={{ paddingTop: 16 }}>
          <li className="MuiListItem-root">
            <div className="MuiListItemIcon-root" style={{ minWidth: 30 }}>
              <FontAwesomeIcon
                icon={faGlobe}
                color="rgba(0, 0, 0, 0.54)"
                size="lg"
              />
            </div>
            <div className="MuiListItemText-root">
              <span className="MuiTypography-root MuiListItemText-primary MuiTypography-body1">
                Global Admin
              </span>
            </div>
          </li>
        </Grid>
        <Grid item sm={4} style={{ paddingLeft: 20, paddingTop: 17 }}>
          <FormControlLabel
            control={
              <GreenCheckbox
                checked={isAdmin}
                onChange={(e) => {
                  if (e.target.checked) {
                    addLattusAdmin && addLattusAdmin()
                  } else {
                    removeLattusAdmin && removeLattusAdmin()
                  }
                }}
                name="checked"
              />
            }
            label=""
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

GlobalPermission.defaultProps = {
  isAdmin: false,
  addLattusAdmin: undefined,
  removeLattusAdmin: undefined,
}

export default GlobalPermission
