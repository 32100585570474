// @flow
import React, { type Node } from 'react'
import clsx from 'clsx'
import { withStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'

type PanelProps = {
  open: boolean,
  onClose: () => void,
  title: string,
  children: ?Node,
  classes: { [string]: string },
}

const styles = (theme) => ({
  contents: {
    paddingBottom: theme.spacing(5),
    width: '90vw',
  },
  titleBar: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.dark,
  },
  body: {
    paddingBottom: theme.spacing(5),
  },
  container: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
})

const Panel = ({ open, onClose, title, children, classes }: PanelProps) => (
  <Drawer
    anchor="right"
    open={open}
    onClose={onClose}
    classes={{ paper: classes.contents }}
  >
    <header className={clsx(classes.titleBar, classes.container)}>
      <IconButton size="small" onClick={onClose}>
        <ArrowBackIosIcon />
      </IconButton>
      <Typography variant="h3">{title}</Typography>
    </header>
    <div className={clsx(classes.container, classes.body)}>{children}</div>
  </Drawer>
)

export default withStyles(styles)(Panel)
