// @flow
import React, { useEffect } from 'react'
import GroupForm from './GroupForm'
import Panel from '../../../components/Panel'

type EditGroupPanelProps = {
  open: boolean,
  updated: boolean,
  updating: boolean,
  updateGroup: ({
    name: string,
    description: string,
    prefix: string | null,
    logo: File | null,
    banner: File | null,
    whiteLabelLogo: File | null,
    whiteLabelVideoURL: string | null,
    defaultSkill: number | null,
    recodingValue: boolean,
    isMailingValue: Boolean,
    isActive: Boolean,
    isFake: boolean,
  }) => void,
  group: {
    name: string,
    description: string,
    prefix: string,
    logo: string,
    banner: string,
    whiteLabelLogo: string,
    whiteLabelVideoURL: string,
    defaultSkill: number,
    recodingValue: boolean,
    isMailingValue: Boolean,
    isActive: Boolean,
    isFake: boolean,
  },
  close: () => void,
  onUpdate: (string) => void,
}

const EditGroupPanel = ({
  open,
  updated,
  updating,
  updateGroup,
  group,
  close,
  onUpdate,
}: EditGroupPanelProps) => {
  useEffect(() => {
    if (updated) {
      onUpdate('Successfully updated the group')
      close()
    }
  }, [updated])

  return (
    <Panel title="Edit group" open={open} onClose={close}>
      <GroupForm group={group} editable={!updating} onSubmit={updateGroup} />
    </Panel>
  )
}

export default EditGroupPanel
