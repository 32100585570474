// @flow
import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import SaveIcon from '@material-ui/icons/Save'
import CancelIcon from '@material-ui/icons/Cancel'

type AccountFormProps = {
  externalId: ?string,
  linkAccount: (string) => void,
  editable: boolean,
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(1),
  },
  fields: {
    width: '100%',
    '& > *': {
      margin: theme.spacing(1, 0),
    },
    '& > *:first-child': {
      marginTop: 0,
    },
    '& > *:last-child': {
      marginBottom: 0,
    },
  },
  button: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}))

const validateId = (val: ?string): boolean =>
  typeof val === 'string' && val.length > 0

const AccountForm = ({
  externalId,
  linkAccount,
  editable = true,
}: AccountFormProps) => {
  const classes = useStyles()
  const [dirty, setDirty] = useState(false)
  const [locked, setLocked] = useState(true)
  const formChanged = !locked && dirty
  const [value, setValue] = useState(externalId || '')
  const [valid, setValid] = useState(validateId(value))

  useEffect(() => {
    setDirty(value !== externalId)
  }, [value])

  useEffect(() => {
    setValid(!formChanged || validateId(value))
  }, [formChanged, value])

  useEffect(() => {
    setValue(externalId || '')
  }, [externalId])

  const update = (e) => {
    e.preventDefault()
    linkAccount(value)
    setLocked(true)
    setDirty(false)
  }

  const discard = () => {
    setLocked(true)
    setDirty(false)
    setValue(externalId || '')
  }

  const formDisabled = !editable || !valid
  const fieldDisabled = locked || !editable
  const submitDisabled = formDisabled || !dirty

  return (
    <form onSubmit={update} disabled={formDisabled} className={classes.root}>
      <div className={classes.fields}>
        <TextField
          label="External ID"
          helperText="Auth0 ID associated with this profile."
          value={value}
          disabled={fieldDisabled}
          variant="outlined"
          fullWidth
          error={!valid}
          onChange={(e) => setValue(e.target.value)}
        />
      </div>
      <div className={classes.actions}>
        {locked && (
          <IconButton
            aria-label="edit"
            disabled={!locked}
            color="secondary"
            onClick={() => setLocked(false)}
          >
            <EditIcon />
          </IconButton>
        )}
        {!locked && (
          <>
            <IconButton aria-label="cancel" disabled={locked} onClick={discard}>
              <CancelIcon />
            </IconButton>
            <IconButton
              aria-label="save"
              disabled={locked || submitDisabled}
              color="primary"
              onClick={update}
            >
              <SaveIcon />
            </IconButton>
          </>
        )}
      </div>
    </form>
  )
}

export default AccountForm
