// @flow
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Typography from '@material-ui/core/Typography'
import ImportExportIcon from '@material-ui/icons/ImportExport'
import ViewAllUsers from '../../modules/users/containers/ViewAllUsers'
import FilterUsers from '../../modules/users/containers/FilterUsers'
import ShowImporterButton from '../../modules/imports/containers/ShowImporterButton'
import UserWizard from '../../modules/imports/containers/UserWizard'

const styles = (theme) => ({
  spacer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
})

const UsersPage = ({ classes }: { classes: { [string]: string } }) => (
  <>
    <Typography variant="h3">Users</Typography>
    <div className={classes.spacer} />
    <ButtonGroup variant="text">
      <ShowImporterButton startIcon={<ImportExportIcon />}>
        Import users
      </ShowImporterButton>
    </ButtonGroup>
    <div className={classes.spacer} />
    <FilterUsers />
    <div className={classes.spacer} />
    <ViewAllUsers />
    <UserWizard />
  </>
)

export default withStyles(styles)(UsersPage)
