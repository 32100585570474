// @flow
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import VpnKeyIcon from '@material-ui/icons/VpnKey'
import ManageUserAccount from '../../containers/ManageUserAccount'
import CreateAccountButton from '../../containers/CreateAccountButton'
import ResetPasswordButton from '../../containers/ResetPasswordButton'
import DeleteAccountButton from '../../containers/DeleteAccountButton'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    '& > *': {
      marginBottom: theme.spacing(1),
    },
    '& > *:last-child': {
      marginBottom: 0,
    },
  },
}))

const Manage = () => {
  const classes = useStyles()
  return (
    <>
      <Typography paragraph>
        Use this panel to manage the user&apos;s Auth0 account.
      </Typography>
      <Typography variant="body2" paragraph>
        An Auth0 account is what the user uses to log in. If a user does not
        already have an Auth0 account, use the &ldquo;create account&rdquo;
        button to create them a new account.
      </Typography>
      <Typography variant="body2" paragraph gutterBottom>
        The External ID maps the user profile in the Lattus database to an Auth0
        account. This is how Lattus knows which profile belongs to a user when
        they log in.{' '}
        <strong>
          Changing this value is dangerous. Only do so if you know what you are
          doing.
        </strong>
      </Typography>
      <Grid container spacing={2} className={classes.root}>
        <Grid item sm={9}>
          <ManageUserAccount />
        </Grid>
        <Grid item sm={3} className={classes.actions}>
          <CreateAccountButton
            variant="contained"
            startIcon={<AddCircleIcon />}
          >
            Create Account
          </CreateAccountButton>
          <DeleteAccountButton
            confirmationTitle="Are you sure you want to delete this account?"
            confirmationText="Deleting an account removes the user's ability to log in and access their profile. However, profile data is kept in the database."
            confirmationLabel="Delete"
            variant="contained"
            startIcon={<DeleteForeverIcon />}
          >
            Delete Account
          </DeleteAccountButton>
          <ResetPasswordButton variant="contained" startIcon={<VpnKeyIcon />}>
            Reset Password
          </ResetPasswordButton>
        </Grid>
      </Grid>
    </>
  )
}

export default Manage
