// @flow
import type { Action } from '../store'

export type AuthState = {
  loggedIn: boolean,
  username?: string,
  avatar?: string,
  loaded: boolean,
  roles: string[],
  rolesLoaded: boolean,
}

const defaultState: AuthState = {
  loggedIn: false,
  loaded: false,
  roles: [],
  rolesLoaded: false,
}

export default (state: AuthState = defaultState, action: Action) => {
  switch (action.type) {
    case 'auth::SET_USER': {
      const { username, avatar, roles } = action
      return {
        ...state,
        username,
        avatar,
        roles,
        rolesLoaded: true,
        loggedIn: true,
      }
    }
    case 'auth::CLEAR_USER': {
      const { username, avatar, roles, ...restOfState } = state
      return { ...restOfState, rolesLoaded: false, loggedIn: false }
    }
    case 'auth::SET_LOADED': {
      const { loaded } = action
      return { ...state, loaded }
    }
    default:
      return state
  }
}
