// @flow
import React from 'react'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'

type ReviewTableProps = {
  rows: Object[],
  columns: string[],
}

const ReviewTable = ({ rows, columns }: ReviewTableProps) => {
  if (rows.length < 0) {
    return <Typography>No data.</Typography>
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          {columns.map((name) => (
            <TableCell key={name}>{name}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row) => (
          <TableRow key={row.id}>
            {columns.map((col) => (
              <TableCell key={`${row.id}-${col}`}>{row[col]}</TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default ReviewTable
