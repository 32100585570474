// @flow
import { connect } from 'react-redux'
import UploadCSV from '../components/Upload/UploadCSV'
import { clearData, resetImport, loadData, setStepValid } from '../actions'
import type { State, Dispatch } from '../../../store'

const mapStateToProps = ({
  imports: { data: { loaded } = {} } = {},
}: State) => ({
  disabled: loaded,
  label: 'User file',
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onUpload: (...args) => dispatch(loadData(...args)),
  clear: () => {
    dispatch(clearData())
    dispatch(resetImport())
  },
  isValid: (...args) => dispatch(setStepValid(...args)),
})

export default connect(mapStateToProps, mapDispatchToProps)(UploadCSV)
